// Core bootstrap
@import "../bootstrap/_buttons.scss";

/**
 *  If you want to override some bootstrap styles, you have to code below.
 */

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
    outline: 0;
    outline-offset: none;
}

.btn {
    line-height: 1.5;
    border: none;
    border-radius: 0;
    @include transition(none);
    &-min {
        min-width: 120px !important;
    }
    &-link {
        padding: 0;
        color: $text-color;
        border-radius: 0;
        background-color: transparent;
        &:focus,
        &:hover {
            color: $brand-secondary;
            text-decoration: none;
            i {
                color: $brand-secondary;
            }
        }
        span {
            margin-left: .5rem;
        }
        &--primary {
            display: inline-block;
            color: $brand-primary;
            vertical-align: inherit;
        }
        &--no-focus {
            &:focus {
                color: inherit;
                i {
                    color: inherit;
                }
            }
            &:hover {
                color: $brand-secondary;
                text-decoration: none;
                i {
                    color: $brand-secondary;
                }
            }
        }
    }
    &-icon {
        text-align: left;
        position: relative;
        overflow: hidden;
        i {
            position: absolute;
            top: 0;
            line-height: inherit;
            width: 2rem;
            height: 100%;
            border-radius: .5rem 0 0 .5rem;
            &:before {
                position: absolute;
                top: 50%;
                left: 50%;
                @include transform(translate(-50%, -50%));
            }
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                right: -.5rem;
                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    /* IE10+ specific styles go here */
                    right: -.45rem;
                }
                border-bottom: .5rem solid transparent;
                border-top: .5rem solid transparent;
                border-left: .5rem solid transparent;
                display: inline-block;
                height: 0;
                width: 0;
                transform: translateY(-50%);
            }
        }
        &--left {
            padding-left: 3rem;
            i {
                left: 0;
            }
            &.btn-link {
                padding-left: 2rem;
            }
        }
        &--right {
            padding-right: 3rem;
            i {
                right: 0;
            }
            &.btn-link {
                padding-right: 2rem;
            }
        }
        &.btn {
            &-primary {
                i {
                    background-color: $brand-secondary;
                    &:after {
                        border-left-color: $brand-secondary;
                    }
                }
            }
            &-secondary {
                i {
                    background-color: $gray-600;
                    color: #ffffff;
                    &:after {
                        border-left-color: $gray-600;
                    }
                }
            }
        }
    }
    &-primary {
        background-color: $brand-secondary;
        color: #fff;
        min-width: 96px;
        &:active,
        &:focus,
        &:active:focus {
            color: #fff;
            background-color: $brand-secondary;
            border-color: $brand-secondary;
        }
        &:hover {
            background-color: $brand-secondary-dark;
        }
        >i.far,
        >i.fas {
            display: none;
        }
        &.btn-radius{
        }
        &.btn-dark{
            background-color: $darkBlue;
            border-radius: 32px;
            -moz-border-radius: 32px;
            -webkit-border-radius: 32px;
            min-width: 12rem;
            padding: $space-sm $space-md;
        }
    }
    &-primary-me {
        background-color: $green500;
        color: $darkBlue;
        font-weight: 700;
        min-width: 96px;
        border-radius: 50px;
        padding: 5px 24px;
        font-size: 20px;
        min-height: 52px;
        line-height: 40px;
        &:active,
        &:focus,
        &:active:focus {
            color: $darkBlue;
            background-color: $green500;
            border-color: $green500;
        }
        &:hover {
            color: $darkBlue;
            background-color: $greenHover;
        }
        >i.far,
        >i.fas {
            display: none;
        }
    }
    &-secondary-me {
        background-color: $disabledBackground;
        color: $darkBlue;
        font-weight: 700;
        min-width: 96px;
        border: 1px solid $disabledBackground;
        border-radius: 50px;
        padding: 5px 24px;
        font-size: 20px;
        min-height: 52px;
        line-height: 40px;
        &:active,
        &:focus,
        &:active:focus {
            color: $darkBlue;
            background-color: $disabledBackground;
            border-color: $disabledBackground;
        }
        &:hover {
            color: $darkBlue;
            background-color: $disabledBackground;
        }
        >i.far,
        >i.fas {
            display: none;
        }
    }
    &-active-me {
        background-color: $brightYellow;
        color: $darkBlue;
        font-weight: 700;
        min-width: 96px;
        border: 1px solid $brightYellow;
        border-radius: 50px;
        padding: 5px 24px;
        font-size: 20px;
        min-height: 52px;
        line-height: 40px;
        &:active,
        &:focus,
        &:active:focus {
            color: $darkBlue;
            background-color: $brightYellow;
            border-color: $brightYellow;
        }
        &:hover {
            color: $darkBlue;
            background-color: $brightYellow;
        }
        >i.far,
        >i.fas {
            display: none;
        }
    }
    &-no-background-me {
        background-color: $white;
        color: $darkBlue;
        font-weight: 700;
        min-width: 96px;
        border: 1px solid $darkBlue;
        border-radius: 50px;
        padding: 5px 24px;
        font-size: 20px;
        min-height: 52px;
        line-height: 40px;
        &:active,
        &:focus,
        &:active:focus {
            color: $darkBlue;
            background-color: $white;
            border-color: $darkBlue;
        }
        &:hover {
            color: $darkBlue;
            background-color: $white;
        }
        >i.far,
        >i.fas {
            display: none;
        }
    }
    &-radius{
        padding: 1.167rem 2.667rem;
        border-radius: 2.2rem;
        min-height: 4.6rem;
        > .fal{
            float: left;
            margin-right: 0.8rem;
            font-size: 1.8rem;
        }
    }
    &-secondary {
        background-color: #fff;
        color: $brand-secondary;
        min-width: 96px;
        &:active,
        &:focus,
        &:active:focus {
            color: $brand-secondary;
            background-color: #fff;
            border-color: #fff;
        }
        &:hover {
            background: none;
            border: none;
            color: $brand-secondary-dark;
        }
        >i.far,
        >i.fas {
            display: none;
        }
    }
    &-secondary-gray {
        background-color: $gray-200;
        color: $text-color;
        min-width: 96px;
        &:active,
        &:focus,
        &:active:focus {
            color: $brand-secondary;
            background-color: $gray-200;
            border-color: $gray-200;
        }
        &:hover {
            background: $gray-300;
            border: none;
        }
        >i.far,
        >i.fas {
            display: none;
        }
    }
    &-back {
        color: $text-color-secondary;
        font-size: 1.16rem;
        padding-left: 1.5rem;
        margin-bottom: 10px;
        &::before {
            font-family: 'Font Awesome 5 Pro';
            content: '\f104';
            font-size: 1.5rem;
            font-weight: 700;
            position: absolute;
            top: 50%;
            left: 0;
            height: auto;
            width: auto;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
        }
        &:hover {
            color: $brand-secondary;
        }
    }
    &.disabled{
        opacity: 0.5;
    }
}

.button-triangle {
    background-color: #ffffff;
    margin: 0 25px;
    width: 350px;
    border: solid $border-color;
    border-width: 2px 0;
    position: relative;
    padding: 0;
    text-align: center;
    height: 50px;
    display: inline-block;
    @include flexbox();
    @include align(center);
    @include justify-content(center);
    &:hover {
        background-color: $gray-100;
        .triangle {
            &--left {
                &:after {
                    border-right-color: $gray-100;
                }
            }
            &--right {
                &:after {
                    border-left-color: $gray-100;
                }
            }
        }
    }
    &.selected {
        border-color: $brand-secondary;
        .triangle {
            &--left {
                &:before {
                    border-right-color: darken($brand-secondary, 7%);
                }
            }
            &--right {
                &:before {
                    border-left-color: darken($brand-secondary, 7%);
                }
            }
        }
    }
}

.triangle {
    position: absolute;
    top: 0;
    &:after,
    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border: solid transparent;
    }
    &:after {
        top: 0;
        border-width: 23px;
    }
    &:before {
        top: -2px;
        border-width: 25px;
    }
    &--left {
        left: 0;
        &:after,
        &:before {
            right: 100%;
        }
        &:after {
            border-right-color: #ffffff;
        }
        &:before {
            border-right-color: darken($border-color, 10%);
        }
    }
    &--right {
        right: 0;
        &:after,
        &:before {
            left: 100%;
        }
        &:after {
            border-left-color: #ffffff;
        }
        &:before {
            border-left-color: darken($border-color, 10%);
        }
    }
}

.me-page{
    button{
        &.btn{
            &.btn-primary{
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                padding: 5px 24px;
                min-height: 52px;
                border-radius: 40px;
                border-width: 2px;
                border-style: solid;
                -webkit-box-pack: center;
                justify-content: center;
                text-align: center;
                -webkit-box-align: center;
                align-items: center;
                cursor: pointer;
                text-decoration: none;
                color: rgb(32, 42, 77);
                background: #b7ecdc;
                border-color: #b7ecdc;
            }
        }
    }
}