@keyframes height-max {
    0% {
        max-height: 100rem;
        opacity: 1;
    }
    50%{
        opacity: 0.5;
    }
    100% {
        max-height: 0;
        opacity: 0;
    }
}
@keyframes height-max-domain {
    0% {
        opacity: 1;
        max-height: 800px;
        padding-bottom: 40px;
    }
    20% {
        opacity: 0.8;
        max-height: 800px;
    }
    40% {
        opacity: 0.2;
        max-height: 800px;
        padding-bottom: 40px;
    }
    60%{
        opacity: 0;
    }
    100% {
        max-height: 0;
        padding-bottom: 0;
    }
}
@keyframes slide-left {
    0% {
        opacity: 0;
        right: -480px;
    }
    100% {
        opacity: 1;
        right: 0;
    }
}
@keyframes slide-top {
    0% {
        top: -180px;
    }
    100% {
        top: 0;
    }
}
@keyframes zoom {
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.2, 1.2);
    }
    100% {
        transform: scale(1, 1);
    }
}
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fixed {
    0% {
        position: absolute;
    }
    100% {
        position: fixed;
    }
}
