.touch-device {
    // Core bootstrap
    /**
     *  If you want to override some bootstrap styles, you have to code below.
     */
    .table {
        &--grid {
            &__body {
                >.row {
                    &:hover {
                        .table--grid {
                            &__actions {
                                &--hover {
                                    @include media-breakpoint-up(xl) {
                                        padding: 0 $space-sm;
                                        @include linear-gradient(to left, $gray-100, 88%, rgba(245, 245, 245, 0.2), 100%);
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &__actions {
                @include media-breakpoint-up(xl) {
                    .dropdown {
                        display: block !important;
                    }
                    &__ellipsis {
                        display: block;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        @include transform(translateY(-50%));
                    }
                    &__links {
                        padding: 12px $space-md;
                        padding-right: 40px;
                    }
                    &--hover {
                        display: block;
                    }
                }
                .dropdown-toggle {
                    &:after {
                        content: '';
                    }
                }
                .btn-group {
                    &:not(.open) {
                        .dropdown-menu {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}