
.gray-50 {
    color: $gray-50 !important;
}
.gray-100 {
    color: $gray-100 !important;
}
.gray-200 {
    color: $gray-200 !important;
}
.gray-300 {
    color: $gray-300 !important;
}
.gray-400 {
    color: $gray-400 !important;
}
.gray-500 {
    color: $gray-500 !important;
}
.gray-600 {
    color: $gray-600 !important;
}
.gray-700 {
    color: $gray-700 !important;
}
.gray-800 {
    color: $gray-800 !important;
}
.gray-900 {
    color: $gray-900 !important;
}
.blue-primary {
    color: $brand-primary !important;
}
.blue-primary-light {
    color: $brand-primary-light !important;
}
.blue-primary-dark {
    color: $brand-primary-dark !important;
}
.blue-secondary {
    color: $brand-secondary !important;
}
.blue-secondary-light {
    color: $brand-secondary-light !important;
}
.blue-secondary-dark {
    color: $brand-secondary-dark !important;
}

.text-color {
    color: $text-color !important;
}

.bg-gray-50 {
    background-color: $gray-50 !important;
}
.bg-gray-100 {
    background-color: $gray-100 !important;
}
.bg-gray-200 {
    background-color: $gray-200 !important;
}
.bg-gray-300 {
    background-color: $gray-300 !important;
}
.bg-gray-400 {
    background-color: $gray-400 !important;
}
.bg-gray-500 {
    background-color: $gray-500 !important;
}
.bg-gray-600 {
    background-color: $gray-600 !important;
}
.bg-gray-700 {
    background-color: $gray-700 !important;
}
.bg-gray-800 {
    background-color: $gray-800 !important;
}
.bg-gray-900 {
    background-color: $gray-900 !important;
}
.bg-blue-primary {
    background-color: $brand-primary !important;
}
.bg-blue-primary-light {
    background-color: $brand-primary-light !important;
}
.bg-blue-primary-dark {
    background-color: $brand-primary-dark !important;
}
.bg-blue-secondary {
    background-color: $brand-secondary !important;
}
.bg-blue-secondary-light {
    background-color: $brand-secondary-light !important;
}
.bg-blue-secondary-dark {
    background-color: $brand-secondary-dark !important;
}
