// Default theme
$color-selected: $brand-primary;
.ng-select {
    outline: 0;
    &.ng-select-opened {
        >.ng-select-container {
            background: #fff;
            border-color: $brand-primary;
            &:hover {
                box-shadow: none;
            }
            .ng-arrow {
                top: -2px;
                border-color: transparent transparent #999;
                border-width: 0 5px 5px;
                &:hover {
                    border-color: transparent transparent #666;
                }
            }
        }
        &.ng-select-bottom {
            >.ng-select-container {
                // border-bottom-right-radius: 0;
                // border-bottom-left-radius: 0;
            }
        }
        &.ng-select-top {
            >.ng-select-container {
                // border-top-right-radius: 0;
                // border-top-left-radius: 0;
            }
        }
    }
    &.ng-select-focused {
        &:not(.ng-select-opened)>.ng-select-container {
            border-color: $brand-primary;
            // box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1);
        }
    }
    &.ng-select-disabled {
        >.ng-select-container {
            background-color: #f9f9f9;
        }
    }
    .ng-has-value .ng-placeholder {
        display: none;
    }
    .ng-select-container {
        background-color: #fff;
        // border-radius: 4px;
        border-bottom: 1px solid #ccc;
        min-height: 36px;
        align-items: center;
        &:hover {
            box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
        }
        .ng-value-container {
            align-items: center;
            padding-left: 10px;
            .ng-placeholder {
                color: #aaa;
            }
        }
    }
    &.ng-select-single {
        .ng-select-container {
            height: 36px;
            .ng-value-container {
                .ng-input {
                    left: 0;
                    padding-left: 10px;
                    padding-right: 50px;
                    top: 5px;
                }
            }
        }
    }
    &.ng-select-multiple {
        &.ng-select-disabled {
            >.ng-select-container .ng-value-container .ng-value {
                background-color: #f9f9f9;
                border: 1px solid #e3e3e3;
                .ng-value-label {
                    padding: 0px 5px;
                }
            }
        }
        .ng-select-container {
            .ng-value-container {
                padding-left: 7px;
                padding-top: 5px;
                .ng-value {
                    font-size: 14px;
                    margin-right: 5px;
                    margin-bottom: 5px;
                    background-color: $color-selected;
                    color: #fff;
                    padding: 5px 10px 0px 5px;
                    font-weight: 500;
                    // border-radius: 2px;
                    // border: 1px solid #c2e0ff;
                    &.ng-select-disabled {
                        background-color: #f9f9f9;
                        border: 1px solid #e3e3e3;
                    }
                    .ng-value-label {
                        display: inline-block;
                        padding: 0px 5px 0px 1px;
                    }
                    .ng-value-icon {
                        display: inline-block;
                        padding: 0px 5px;
                        font-size: 16px;
                        &:hover {
                            // background-color: #d8eafd;
                            color: $brand-secondary;
                        }
                        &.left {
                            // border-right: 1px solid #c2e0ff;
                        }
                        &.right {
                            // border-left: 1px solid #c2e0ff;
                        }
                    }
                }
                .ng-input {
                    padding-bottom: 3px;
                    padding-left: 3px;
                }
                .ng-placeholder {
                    top: 5px;
                    padding-bottom: 5px;
                    padding-left: 3px;
                }
            }
        }
    }
    .ng-clear-wrapper {
        display: none;
        color: #999;
        .ng-clear {
            &:hover {
                color: #D0021B;
            }
        }
    }
    .ng-spinner-zone {
        padding-right: 5px;
        padding-top: 5px;
    }
    .ng-arrow-wrapper {
        display: none;
        padding-right: 5px;
        width: 25px;
        .ng-arrow {
            border-color: #999 transparent transparent;
            border-style: solid;
            border-width: 5px 5px 2.5px;
            &:hover {
                border-top-color: #666;
            }
        }
    }
}

.ng-dropdown-panel {
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    &.ng-select-bottom {
        // top: 100%;
        top: calc(100% + 2px);
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        border-top-color: #e6e6e6;
        margin-top: -1px;
        .ng-dropdown-panel-items {
            .ng-option {
                &:last-child {
                    border-bottom-right-radius: 4px;
                    border-bottom-left-radius: 4px;
                }
            }
        }
    }
    &.ng-select-top {
        bottom: 100%;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        border-bottom-color: #e6e6e6;
        margin-bottom: -1px;
        .ng-dropdown-panel-items {
            .ng-option {
                &:first-child {
                    border-top-right-radius: 4px;
                    border-top-left-radius: 4px;
                }
            }
        }
    }
    .ng-dropdown-header {
        border-bottom: 1px solid #ccc;
        padding: 5px 7px;
    }
    .ng-dropdown-footer {
        border-top: 1px solid #ccc;
        padding: 5px 7px;
    }
    .ng-dropdown-panel-items {
        margin-bottom: 1px;
        .ng-optgroup {
            user-select: none;
            cursor: default;
            padding: 8px 10px;
            &.ng-select-disabled {
                color: rgba(0, 0, 0, .54);
            }
            &.ng-option-marked {
                background-color: $gray-100;
                color: $text-color;
            }
            &.ng-option-selected {
                color: #333;
                background-color: $color-selected;
                font-weight: 600;
            }
        }
        .ng-option {
            background-color: #fff;
            color: rgba(0, 0, 0, .87);
            padding: 8px 10px;
            &.ng-option-selected {
                color: #333;
                background-color: $gray-300;
                .ng-option-label {
                    font-weight: 600;
                }
            }
            &.ng-option-marked {
                background-color: $gray-100;
                color: $text-color;
            }
            &.ng-option-disabled {
                color: #cccccc;
            }
            &.ng-option-child {
                padding-left: 22px;
            }
            .ng-tag-label {
                padding-right: 5px;
                font-size: 80%;
                font-weight: 400;
            }
        }
    }
}