// import all components here
@import 'global';
@import 'helper';
@import 'checkbox';
@import 'radio';
@import 'scrollbar';
@import 'select';
@import 'help-btn';
@media only screen and (max-width: 1024px) { /* 10 inch tablet enter here */
  @import 'tablet';
}
@import 'symptom-map'; // Safari needs this
@import 'spinner';
@import 'ng-select';
@import 'my-date-picker';
@import 'image-upload';
@import 'materials';
@import 'fontawesome';
@import 'color';
@import 'choices';
@import 'touchdevice';