//
// Display utilities
//
.d-block {
    display: block !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-inline {
    display: inline !important;
}

.d-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
}
