// help buttons
.help-btn-all {
    &:before {
        border-bottom-color: transparent !important;
    }
}

.patient-list-help {
    $moveSpace: 30%;
    min-width: 200px;
    .popover-content {
        max-height: 600px;
        overflow-y: auto;
    }
    transform: translateX($moveSpace);
    &:before, &:after {
        left: (50% - $moveSpace) !important;
    }
}

.patient-list-action-help {
    // $moveSpace: -30%;
    min-width: 600px;
    max-width: 600px;
    .popover-content {
        max-height: 600px;
        overflow-y: auto;
    }
    margin-left: -270px;
    // transform: translateX($moveSpace);
    &:before, &:after {
        left: calc(50% + 270px) !important;
    }
}

.patient-diagnosis-help {
    min-width: 400px;
    max-width: 600px;
    .popover-content {
        max-height: 600px;
        overflow-y: auto;
    }
    &:before, &:after {
        // left: (50% - $moveSpace) !important;
    }
}

.patient-questionnare-help {
    // $moveSpace: 30%;
    min-width: 400px;
    max-width: 600px;
    .popover-content {
        max-height: 500px;
        overflow-y: auto;
    }
    // transform: translateX($moveSpace);
    &:before, &:after {
        // left: (50% - $moveSpace) !important;
    }
}

.notification-help {
     $moveSpace: 30%;
    min-width: 400px;
    max-width: 500px;
    .popover-content {
        max-height: 500px;
        overflow-y: auto;
    }
     transform: translateX($moveSpace);
    &:before, &:after {
         left: (50% - $moveSpace) !important;
    }
}

.patient-di-map-help {
    min-width: 400px;
    max-width: 600px;
    .popover-content {
        max-height: 600px;
        overflow-y: auto;
    }
    margin-left: -140px;
    &:before, &:after {
        left: calc(50% + 140px) !important;
    }
    @media only screen and (max-width: 1024px) {
        .popover-content {
            max-height: 280px;
        }
    }
}

.patient-se-map-help {
    // $moveSpace: 0;
    min-width: 400px;
    max-width: 600px;
    .popover-content {
        max-height: 600px;
        overflow-y: auto;
    }
    // transform: translateX($moveSpace);
    &:before, &:after {
        // left: (50% - $moveSpace) !important;
    }
    @media only screen and (max-width: 1024px) {
        .popover-content {
            max-height: 280px;
        }
    }
}

.di-overview-help {
    // $moveSpace: 0px;
    min-width: 450px;
    max-width: 600px;
    .popover-content {
        max-height: 600px;
        overflow-y: auto;
    }
    margin-left: -280px;
    // right: 0;
    // transform: translateX($moveSpace);
    &:before, &:after {
        left: calc(50% + 280px) !important;
    }
    &:after {
        @media only screen and (max-width: 1024px) {
            top: -9px !important;
        }
    }
}

.di-symptom-se-help {
    $moveSpace: 350px;
    min-width: 400px;
    max-width: 560px;
    .popover-content {
        max-height: 600px;
        overflow-y: auto;
    }
    // margin-left: -200px;
    // transform: translateX($moveSpace);
    &:before, &:after {
        // left: calc(50% - 150px) !important;
    }
}

.di-symptom-expert-help {
    min-width: 200px;
    max-width: 600px;
    .popover-content {
        max-height: 600px;
        overflow-y: auto;
    }
    margin-left: 100px;
    &:before, &:after {
        left: calc(50% - 100px) !important;
    }
}

.di-symptom-check-help {
    min-width: 450px;
    max-width: 600px;
    .popover-content {
        max-height: 600px;
        overflow-y: auto;
    }
    margin-left: -280px;
    &:before, &:after {
        left: calc(50% + 280px) !important;
    }
    &:after {
        @media only screen and (max-width: 1024px) {
            top: -9px !important;
        }
    }
}

.di-symptom-header {
    min-width: 400px;
    max-width: 600px;
    .popover-content {
        max-height: 600px;
        overflow-y: auto;
    }
    margin-left: 150px;
    &:before, &:after {
        left: calc(50% - 150px) !important;
    }
    // &:after {
    //     @media only screen and (max-width: 1024px) {
    //         top: -9px !important;
    //     }
    // }
}

.di-symptom-header-amnestic {
    min-width: 200px;
    max-width: 600px;
    .popover-content {
        max-height: 600px;
        overflow-y: auto;
    }
    margin-left: 200px;
    &:before, &:after {
        left: calc(50% - 200px) !important;
    }
    // &:after {
    //     @media only screen and (max-width: 1024px) {
    //         top: -9px !important;
    //     }
    // }
}

.symptom-map-menu-help {
    z-index: 10000;
}

.doctor-home {
    min-width: 450px;
    max-width: 600px;
    .popover-content {
        max-height: 600px;
        overflow-y: auto;
    }
    margin-left: -250px;
    &:before, &:after {
        left: calc(50% + 250px) !important;
    }
}

.clinic-profile {
    min-width: 200px;
    max-width: 600px;
    .popover-content {
        max-height: 600px;
        overflow-y: auto;
    }
    margin-left: 200px;
    &:before, &:after {
        left: calc(50% - 200px) !important;
    }
    // &:after {
    //     @media only screen and (max-width: 1024px) {
    //         top: -9px !important;
    //     }
    // }
}

.assignment-patient-help {
    min-width: 400px;
    max-width: 500px;
    @media only screen and (max-width: 1024px) {
        min-width: 400px;
        max-width: 300px;
    }

    .popover-content {
        max-height: 500px;
        overflow-y: auto;
    }
}

.share-patient-help {
    min-width: 400px;
    max-width: 500px;
    @media only screen and (max-width: 1024px) {
        min-width: 400px;
        max-width: 300px;
    }

    .popover-content {
        max-height: 500px;
        overflow-y: auto;
    }
}
