@function caret($colorIndex) {
    @return 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 10 6"><path fill="'+$colorIndex+'" d="M5.09,5.5a0.5,0.5,0,0,1-.35-0.15l-4-4A0.5,0.5,0,0,1,1.45.65L5.09,4.29,8.74,0.65a0.5,0.5,0,0,1,.71.71l-4,4A0.5,0.5,0,0,1,5.09,5.5Z"/></svg>';
}

@function closeIcon($colorIndex) {
    @return 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><path fill="'+$colorIndex+'" fill-rule="evenodd" d="M6.95159371,6.00004168 L11.7985692,1.16984373 C12.0612851,0.907022137 12.0679193,0.476702838 11.8131645,0.207072392 C11.5570829,-0.0625580548 11.1377989,-0.0707286743 10.875083,0.193454692 L6.00024375,5.05224981 L1.12540448,0.193454692 C0.862688587,-0.0707286743 0.442077795,-0.0625580548 0.187322993,0.207072392 C-0.0674318083,0.476702838 -0.0621244166,0.907022137 0.20191832,1.16984373 L5.04889379,6.00004168 L0.20191832,10.8302396 C-0.0621244166,11.0930612 -0.0674318083,11.5233805 0.187322993,11.793011 C0.31735409,11.9305497 0.48984432,12 0.663661398,12 C0.829517389,12 0.996700227,11.9359968 1.12540448,11.8066287 L6.00024375,6.94783355 L10.875083,11.8066287 C11.0037873,11.9359968 11.1696433,12 11.3368261,12 C11.5093163,12 11.6818066,11.9305497 11.8131645,11.793011 C12.0679193,11.5233805 12.0612851,11.0930612 11.7985692,10.8302396 L6.95159371,6.00004168 Z"/></svg>';
}


/*===============================
=            Choices            =
===============================*/

$choices-selector: 'choices' !default;
$choices-single-dd: '.#{$choices-selector}[data-type*="select-one"]';
$choices-font-size-lg: 14px !default;
$choices-font-size-md: 14px !default;
$choices-font-size-sm: 12px !default;
$choices-guttering: 24px !default;
$choices-border-radius: 2px !default;
$choices-border-radius-item: 20px !default;
$choices-bg-color: #ffffff !default;
$choices-bg-color-disabled: #f2f2f4 !default;
$choices-color-disabled: #cccece !default;
$choices-bg-color-dropdown: #FFFFFF !default;
$choices-text-color: #333333 !default;
$choices-keyline-color: $divider !default;
$choices-primary-color: #00BCD4 !default;
$choices-disabled-color: #eaeaea !default;
$choices-highlight-color: $choices-primary-color !default;
$choices-button-icon-path: '../../icons' !default;
$choices-button-dimension: 8px !default;
$choices-button-offset: 8px !default;
$choices-item-height: 35px !default;
$choices-multi-height: 45px !default;
$choices-multi-item-hover: #FFFFFF !default;
$choices-box-shadow: rgba(0, 0, 0, 0.3) 0 0 4px 0px !default;
$choices-caret-color: #FFFFFF !default;
$choices-hover-color: #9ca0a0 !default;
$choices-focused-color: #04bee8 !default;
$choices-search-icon: 'data:image/svg+xml;base64,PHN2ZyBpZD0iODZjZjM1MDItZTRlMy00MmE2LWE0MGMtZjk2MjI0M2UzODkzIiBmaWxsPSIjY2NjZWNlIiBkYXRhLW5hbWU9IkxheWVyIDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiB2aWV3Qm94PSIwIDAgMjAgMjAiPjx0aXRsZT5TZWFyY2hfPC90aXRsZT48cGF0aCBkPSJNMTksMTguMzNMMTQuNCwxMy43YTcuNjgsNy42OCwwLDEsMC0uNzEuNzFMMTguMzMsMTlBMC41LDAuNSwwLDAsMCwxOSwxOC4zM1ptLTEwLjM4LTNhNi42Niw2LjY2LDAsMSwxLDYuNjYtNi42NkE2LjY2LDYuNjYsMCwwLDEsOC42NiwxNS4zMVoiLz48L3N2Zz4=';
.#{$choices-selector} {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: $choices-guttering;
    font-size: $choices-font-size-lg;
    &:focus {
        outline: none;
    }
    &:last-child {
        margin-bottom: 0;
    }
    &.is-disabled {
        .#{$choices-selector}__inner {
            background-color: $choices-bg-color-disabled;
            cursor: not-allowed !important;
            user-select: none;
            color: $choices-color-disabled;
            border: none;
        }
        .#{$choices-selector}__input {
            display: none;
        }
        .#{$choices-selector}__item {
            cursor: not-allowed;
        }
    }
    &:not(.is-open) .#{$choices-selector}__inner:hover {
        border-color: $choices-hover-color !important;
    }
}

choices [role="combobox"][data-type*="select-one"].is-open {
    .#{$choices-selector}__list--dropdown {
        transform: translateY(-35px);
    }
}

#{$choices-single-dd} {
    cursor: pointer;
    .#{$choices-selector}__inner {
        display: flex;
        padding: 0 6px;
        align-items: center;
    }
    .#{$choices-selector}__input {
        display: block;
        width: 100%;
        padding: 10px;
        border-bottom: 1px solid $choices-keyline-color;
        background-color: #FFFFFF;
        height: $choices-item-height;
        max-height: $choices-item-height;
        margin: 0;
        background-position: 98.5% 7px;
        padding-right: 35px;
        background-image: url($choices-search-icon);
        background-repeat: no-repeat;
        transition: all 5s;
    }
    &.is-open .choices__input {
        border: 1px solid $choices-focused-color;
    }
    &.is-open .#{$choices-selector}__input {
        border-color: $choices-focused-color;
    }
    .#{$choices-selector}__button {
        //background-image: url($choices-button-icon-path + '/cross-inverse.svg');
        padding: 0;
        background-size: 8px;
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -10px;
        margin-right: 25px;
        height: 20px;
        width: 20px;
        border-radius: 10em;
        opacity: .5;
        &:hover,
        &:focus {
            opacity: 1;
        }
        &:focus {
            box-shadow: 0px 0px 0px 2px $choices-highlight-color;
        }
    }
    &:after {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: -2.5px;
        background: url(caret($choices-caret-color)) no-repeat;
        transition: transform 0.2s;
    }
    &.is-open:after {
        transform: rotate(180deg) translateY(4px)
    }
    .#{$choices-selector}__list--single {
        padding-top: 0;
        padding-bottom: 0;
    }
    &[dir="rtl"] {
        &:after {
            left: 11.5px;
            right: auto;
        }
        .#{$choices-selector}__button {
            right: auto;
            left: 0;
            margin-left: 25px;
            margin-right: 0;
        }
    }
}

.#{$choices-selector}[data-type*="select-multiple"],
.#{$choices-selector}[data-type*="text"] {
    .#{$choices-selector}__inner {
        cursor: text;
        min-height: $choices-multi-height;
    }
    .#{$choices-selector}__list--dropdown {
        transform: translateY(1px);
    }
    .#{$choices-selector}__button {
        position: relative;
        display: inline-block;
        // margin: 0 -$choices-button-offset/2 0 5px;
        // margin: 4px 8px 0 -16px;
        // padding-left: $choices-button-offset*2;
        // background-image: url(closeIcon($choices-caret-color));
        background-repeat: no-repeat;
        background-size: 10px;
        background-position: 15px 1px;
        line-height: 1;
        opacity: 1;
        // hide content text: remove
        color: transparent;
        width: 22px;
        white-space: nowrap;
        overflow: hidden;
        text-indent: 0;
        margin-top: 2px;
        padding: 0;

        &:before {
            // .fa, .fas from font-awesome
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
            font-family: 'Font Awesome 5 Pro';
            font-weight: 900;
            // icon content
            content: "\f00d";
            color: #fff;
            font-size: 12px;
        }
    }
    .#{$choices-selector}__input {
        margin-bottom: 0;
        padding-top: 6px;
    }
}

.#{$choices-selector}__inner {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    background-color: $choices-bg-color;
    padding: 7.5px 7.5px 3.75px;
    border-bottom: 1px solid $choices-keyline-color;
    border-radius: $choices-border-radius;
    font-size: $choices-font-size-md;
    min-height: $choices-item-height;
    overflow: hidden;
    .is-focused &,
    .is-open & {
        border-color: $choices-focused-color;
    }
    .is-open & {
        border-radius: $choices-border-radius $choices-border-radius 0 0;
    }
    .is-flipped.is-open & {
        border-radius: 0 0 $choices-border-radius $choices-border-radius;
    }
}

.#{$choices-selector}__list {
    margin: 0;
    padding-left: 0;
    list-style: none;
}

.#{$choices-selector}__list--single {
    display: inline-block;
    padding: 4px 16px 4px 4px;
    width: 100%;
    [dir="rtl"] & {
        padding-right: 4px;
        padding-left: 16px;
    }
    .#{$choices-selector}__item {
        width: 100%;
    }
}

.#{$choices-selector}__list--multiple {
    display: inline;
    .#{$choices-selector}__item {
        display: inline-block;
        vertical-align: middle;
        padding: 8px 10px;
        font-size: $choices-font-size-md;
        font-weight: 500;
        margin-right: 10px;
        margin-bottom: 5px;
        word-break: break-all;
        // height: 29px;
        border-radius: 2px;
        background-color: $brand-primary;
        color: #FFFFFF;
        border: none;
        cursor: default;
        &[data-deletable] {
            padding-right: 5px;
        }
        [dir="rtl"] & {
            margin-right: 0;
            margin-left: 3.75px;
        }
        &:hover {
            color: $choices-multi-item-hover;
            .#{$choices-selector}__button {
                // background-image: url(closeIcon($choices-multi-item-hover));
            }
        }
        &.is-highlighted {}
        .is-disabled & {
            color: #cccece;
            background-color: #f8f8f9;
            .#{$choices-selector}__button {
                cursor: not-allowed;
                background-image: url(closeIcon(#cccece));
            }
        }
    }
}

.#{$choices-selector}__list--dropdown {
    box-shadow: $choices-box-shadow;
    display: none;
    z-index: 1;
    position: absolute;
    width: 100%;
    background-color: $choices-bg-color-dropdown;
    border: 1px solid $choices-keyline-color;
    top: 100%;
    left: 0;
    margin-top: -1px;
    border-bottom-left-radius: $choices-border-radius;
    border-bottom-right-radius: $choices-border-radius;
    overflow: hidden;
    word-break: break-all;
    &.is-active {
        display: block;
    }
    .is-open & {
        //border-color: darken($choices-keyline-color, 15%);
    }
    .is-flipped & {
        top: auto;
        bottom: 100%;
        margin-top: 0;
        margin-bottom: -1px;
        border-radius: .25rem .25rem 0 0;
    }
    .#{$choices-selector}__list {
        position: relative;
        max-height: 300px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        will-change: scroll-position;
    }
    .#{$choices-selector}__item {
        position: relative;
        padding: 0 10px;
        font-size: $choices-font-size-md;
        height: $choices-item-height;
        display: flex;
        align-items: center;
        [dir="rtl"] & {
            text-align: right;
        }
    }
    .#{$choices-selector}__item--selectable {
        @media (min-width: 640px) {
            padding-right: 100px;
            &:after {
                content: attr(data-select-text);
                font-size: $choices-font-size-sm;
                opacity: 0;
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
            }
            [dir="rtl"] & {
                text-align: right;
                padding-left: 100px;
                padding-right: 10px;
                &:after {
                    right: auto;
                    left: 10px;
                }
            }
        }
        &.is-highlighted {
            background-color: #f2f2f4;
            &:after {
                opacity: .5;
            }
        }
    }
}

.#{$choices-selector}__item {
    cursor: default;
}

.#{$choices-selector}__item--selectable {
    cursor: pointer;
}

.#{$choices-selector}__item--disabled {
    cursor: not-allowed;
    user-select: none;
    opacity: .5;
}

.#{$choices-selector}__heading {
    font-weight: 600;
    font-size: $choices-font-size-sm;
    padding: 10px;
    border-bottom: 1px solid lighten($choices-keyline-color, 10%);
    color: lighten(#333, 30%);
}

.#{$choices-selector}__button {
    text-indent: -9999px;
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    float: left;
    &:focus {
        outline: none;
    }
}

.#{$choices-selector}__input {
    display: inline-block;
    vertical-align: baseline;
    background-color: #ffffff;
    font-size: $choices-font-size-md;
    margin-bottom: 5px;
    border: 0;
    border-radius: 0;
    max-width: 100%;
    padding: 4px 0 4px 2px;
    &:focus {
        outline: 0;
    }
    [dir="rtl"] & {
        padding-right: 2px;
        padding-left: 0;
    }
}

.#{$choices-selector}__placeholder {
    opacity: .5;
}

.#{$choices-selector}__item--selected {
    background-color: #f8f8f9;
    font-weight: 600;
    color: #383838;
}

.#{$choices-selector}__list--dropdown .#{$choices-selector}__placeholder {
    display: none;
}

.choices__checkbox {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    border: 1px solid lightgray;
    &--selected::before {
        content: "\2713";
        color: darkseagreen;
        font-size: 14px;
        text-align: center;
        line-height: 15px;
    }
}
