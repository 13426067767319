// Core bootstrap
@import "../bootstrap/_forms.scss";

/**
 *  If you want to override some bootstrap styles, you have to code below.
 */

label,
.label {
    font-size: 1rem;
    margin-bottom: $space-sm;
    vertical-align: top;
    line-height: 1;
}

.label {
    &--highlight {
        color: $brand-secondary;
        font-weight: $font-medium;
    }
    &--bold {
        font-weight: $font-medium;
    }
}

select {
    &:-moz-focusring {
        color: transparent !important;
        text-shadow: 0 0 0 $text-color !important;
    }
    &.form-control {
        &.comparison-date, &.reply-date {
            display: inline-block;
        }
    }
    &.form-control:not([size]):not([multiple]) {
        //height: 2.5rem;
        border-color: $border-color;
        border-radius: 0;
        width: 100%;
        &:focus {
            border-color: $brand-primary;
        }
    }
}

.form-control {
    border-radius: 0;
    color: $text-color;
    &[readonly] {
        background-color: transparent;
        border: none;
        padding-left: 0;
        padding-right: 0;
    }
    &:disabled {
        cursor: inherit;
        color: $gray-400;
        border-width: 0;
        padding-left: 0;
        padding-right: 0;
    }
}

.form-group {
    margin-bottom: $space-md;
    &:last-child {
        margin: 0;
    }
}

.form-element {
    padding-bottom: 3px;
}

small {
    font-size: 0.9167rem;
    line-height: 0.9167rem;
}

select.form-control:disabled {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    font-weight: $font-medium;
    background: none;
}

input,
input.form-control {
    @include placeholder {
        font-weight: 400;
        color: $text-color-secondary;
    }
}

input::-ms-clear {
    display: none;
}

input[type=range] {
    -webkit-appearance: none;
    width: 100%;
    &.form-control {
        border: 0;
        padding: 0;
    }
    &:focus {
        outline: none;
        &::-webkit-slider-runnable-track {
            background: $gray-400;
        }
        &::-ms-fill-lower {
            background: $gray-400;
        }
        &::-ms-fill-upper {
            background: $gray-400;
        }
    }
    &.input-range-readonly {
        &::-webkit-slider-thumb {
            background: $gray-500;
        }
        &::-moz-range-thumb {
            background: $gray-500;
        }
        &::-ms-thumb {
            background: $gray-500;
        }
    }
    &::-moz-focus-outer {
        border: 0;
    }
    &::-webkit-slider-runnable-track {
        width: 100%;
        height: 8px;
        cursor: pointer;
        animate: 0.2s;
        background: $gray-400;
        border-radius: 25px;
    }
    &::-webkit-slider-thumb {
        height: 12px;
        width: 36px;
        border-radius: 7px;
        background: $brand-secondary;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -2px;
    }
    &::-moz-range-track {
        width: 100%;
        height: 8px;
        cursor: pointer;
        animate: 0.2s;
        background: $gray-400;
        border-radius: 25px;
    }
    &::-moz-range-thumb {
        height: 12px;
        width: 36px;
        border-radius: 7px;
        background: $brand-secondary;
        cursor: pointer;
        border: none;
    }
    &::-ms-track {
        width: 100%;
        height: 8px;
        cursor: pointer;
        animate: 0.2s;
        background: transparent;
        border-color: transparent;
        border-width: 36px 0;
        color: transparent;
    }
    &::-ms-fill-lower {
        background: $gray-400;
        border-radius: 50px;
    }
    &::-ms-fill-upper {
        background: $gray-400;
        border-radius: 50px;
    }
    &::-ms-thumb {
        height: 12px;
        width: 36px;
        border-radius: 7px;
        background: $brand-secondary;
        cursor: pointer;
    }
}

.input-range {
    position: relative;
    top: 5px;
    .input-group-addon {
        background-color: transparent;
        border: 0;
        position: absolute;
        top: 20px;
        padding: 0;
        width: auto;
        &:first-child {
            left: 0;
        }
        &:last-child {
            right: 0;
        }
    }
}


/***************************
 Date
****************************/

input[type=date] {
    &::-webkit-inner-spin-button,
    &::-webkit-clear-button {
        position: relative;
        height: 2.5rem;
        right: .5rem;
        margin-right: .5rem;
    }
}


.form-customized {
    position: relative;
    .mat-form-field-underline {
        bottom: auto;
        top: 60px;
    }
}
.form-unfocused {
    .form-text {
        display: none;
    }
}

.form-focused {
    label {
        color: $brand-secondary;
    }
}

.form-errored {
    label,
    .form-text {
        color: $brand-danger;
    }
    .mat-form-field-underline,
    .mat-form-field-ripple {
        background-color: $brand-danger;
    }
}