// Tablet High resolution and Desktop
@media screen and (min-width: 1367px){
    .app-wrapper {
        &.se-page {
            &.touch-device {
                background: $gray-100;
            }
        }
    }
}

// Small Desktop resolution
@media screen and (min-width: 1141px){
    .app-wrapper {
        &.se-page {
            .mat-drawer-container{
            }
        }
    }
}

@media screen and (max-width: 1140px){
    .app-wrapper.se-page,
    .self-evaluation-modal {
        .close-btn {
            cursor: pointer;
        }
    }
}

@media screen and (max-width: 1024px){
    body{
        overflow-x: hidden;
        max-width: 100vw;
        min-width: auto;
    }
}
@media screen and (max-width: 480px){
    body{
        app.app{
            min-height: auto;
            height: 100%;
        }
        &.mobile-selfevaluation-rating{
            height: 100vh !important; /* Use vh as a fallback for browsers that do not support Custom Properties */
            max-height: 100vh !important;
            margin: 0 auto;
            min-height: auto !important;
            &.iOS{
                height: calc(var(--vh, 1vh) * 100) !important;
            }
        }
        .container.sidenav-opened{
            position: fixed !important;
            top:0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow-y: scroll !important;
            .mat-drawer-backdrop.mat-drawer-shown{
                visibility: hidden;
            }
        }
    }
    .se-progress-bar {
        background-color: $gray-100;
        min-height: 64px;
        display: flex;
        align-items: center;
        -webkit-align-items: center;
    }
    .wrapper{
        &__progress {
            &__bar {
                margin-right: 32px;
                margin-left: 32px;
                width: calc(100vw - 64px);
                height: 0px;
            }
        }
        &__selfevaluation{
            padding: 0 $space-md $space-sm $space-md !important;

            .mat-slider{
                &--rating{
                    left: 6px;
                }
            }
            &--rating{
                padding-left: 16px !important;
                padding-bottom:0 !important;
                height: 100vh;
                min-height: auto !important;
                @media screen and (min-height: 800px){
                    overflow: hidden;
                }
                .wrapper__btns{
                }
            }
            //  Rating on small device
            .rating-area {
                height: calc(100vh - 168px);
                left: 0;
                top: 60px;
                bottom: auto;
                width: 100%;
                &.isIOS{
                    height: calc(100vh - 220px);
                }
                &:after{
                    content: '';
                    width: 8px;
                    height: 8px;
                    border-top:2px solid $brand-primary;
                    border-left:2px solid $brand-primary;
                    position: absolute;
                    bottom: 20px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }
            .rating-scale{
                height: 100%;
                width: 28px;
            }

            .wrapper__btns{
                margin: 0 !important;
                width: 100%;

                .btn-secondary{
                    margin-bottom: 1rem;
                }
                button.btn{
                    min-width: 35vw !important;
                    width: 35vw !important;
                    font-size: 0.85rem !important;
                    padding: 0px 4px;
                    text-align: center;
                    height: 54px;
                    text-wrap: unset;
                    font-weight: 700;
                    line-height: 1.2rem;

                    &--pause{
                        float: right;
                        width: 30px !important;
                        min-width: unset !important;
                        margin-right: 2vw;
                        font-size: 30px !important;
                        color: $darkBlue;
                        padding: 0;
                    }
                }
            }

            &.smoke-detector,
            &.feeling-selection,
            &.amnestic-smoke-detector,
            &.amnestic-feeling-selection{
                padding-bottom: $space-md !important;
                flex-flow: column !important;
            }

            .slide-navigator {
                padding-left: 0px;
                padding-right: 0px;
            }

            .header-title {
                margin-top: 2rem;
                &__info {
                    > i {
                        font-size: 24px !important;
                    }
                }
            }
        }

        &__feedback-rating {
            &__area {
                .rating-item {
                    font-size: calc((100vw - 171px)/5) !important;
                }
            }
        }
    }
    .mat-drawer-inner-container{
        .selfevaluation{
            &__nav{
                position: fixed;
            }
        }
    }

    .btn-xs-full{
        width: 100%;
        padding: $space-sm;
    }
    .feeling-block {
        width: auto !important;
    }

    .app-wrapper.se-page {
        mat-drawer {
            width: 100%;
            min-width: auto;
            padding: 0;
        }
        .mat-drawer-container{
            height: 100%;
            z-index: $zindex-modal !important;
            background: #FFF;
        }
        .mat-drawer.mat-drawer-end{
            left: 0 !important;
        }
    }

    .self-evaluation-menu{
        display: flex !important;
        display: -ms-flex;
        flex-direction: column;
        align-content: flex-start;
        position: unset !important;
        justify-content: flex-start;
        overflow: hidden;
        float: left;
        height: auto;
        width: 100%;
        min-height: 99vh;
        padding: $space-md;

        &__bottom{
            align-self: flex-end;
            position: unset !important;
            min-height: 250px;
        }
    }
    .app-wrapper.se-page.overlay{

    }
    .app-wrapper.auth-se{
        min-height: calc(100vh - 160px);
        .header{
            .container{
                padding-left: $space-md;
                padding-right: $space-md;
            }
        }
        .bg-circle-corner{
            display: none;
        }
        .card-block{
            padding: 0;
        }
        .auth-self-evalution {
            padding: 0 $space-sm;
            &__content {
                font-size: 1.133rem !important;
                line-height: 1.833rem;
            }
            .card {
                &--header {
                    font-size: 2.333rem !important;
                }
                &--box {
                    &__search__input {
                        height: 5rem !important;
                    }
                }
                button.btn {
                    &.btn-primary {
                        font-size: 1.333rem;
                        padding: 1.367rem 2.667rem;
                        border-radius: 2.2rem;
                        width: 100%;
                    }
                }
            }
            .mat-form{
                padding-left: 0 !important;
            }
        }
    }
    footer.footer{
        .footer__ce{
            flex-grow: 1;
        }
    }
    .pull{
        &--xs{
            &--left{
                float: left !important;
            }
            &--right{
                float: right !important;
            }
        }
    }
}

@media screen and (max-width: 320px){
    .wrapper {
        &__progress {
            &__bar {
                &__step {
                    &--progress {
                        width: 100px;
                    }
                }
            }
        }
        &__selfevaluation{
            padding-top: $space-md !important;
            padding-left:  $space-sm !important;
            padding-right: $space-sm !important;

            .wrapper__btns{

            }
        }
    }
    mat-sidenav {
        &.mat-sidenav--selfevaluation{
            padding: 16px;
        }
    }
}
@media screen and (min-width: 420px){
    .self-evaluation-menu{
        &__bottom{
            &--footerlinks{
                span{
                    -ms-flex: 1 1 auto!important;
                    flex: 1 1 auto!important;
                    width: auto !important;
                    padding-right: 1rem;
                }
            }
        }
    }
}

// Fix min Tablet
@media screen and (min-width: 420px) and (min-height: 481px) and (max-height: 745px){
    .wrapper{
        &__selfevaluation{
            min-height: 100vh !important;
            .wrapper__btns{
                top: calc(100% - 112px) !important;
            }
        }
    }
}

/* IE10+ specific styles go here */
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .app-wrapper.se-page{
        .wrapper__selfevaluation{
            .wrapper__btns{
                position: absolute !important;
                bottom: 4rem !important;
                left: 4.5rem !important;
                margin-bottom: 0 !important;
            }

        }
    }
}
