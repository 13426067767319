// Core bootstrap
@import "../bootstrap/_tooltip.scss";

/**
 *  If you want to override some bootstrap styles, you have to code below.
 */

.tooltip {
    font-size: 1.16rem;
    /* 
        fixed issue of some top tooltips not horizontally centered on the production app.
        The default margin-left is set to the width of the tooltip arrow. Refer to line 24 in bootstrap/_tooltip.scss
    */
    &.assistants.tooltip-top,
    &.assistants.bs-tether-element-attached-bottom {
        .tooltip-inner::before {
            margin-left: 0;
        }
    }
    .tooltip-inner{
        background-color: #4F4F4F;
        border-radius: 4px;
        max-width: 280px;
    }

    &.tooltip-bottom{
        .tooltip-inner{
            &::before{
                border-bottom-color: #4F4F4F;
            }
        }
    }
    &.tooltip-top{
        .tooltip-inner{
            &::before{
                border-top-color: #4F4F4F;
            }
        }
    }
}