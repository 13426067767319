.ps.ps--active-y > .ps__rail-y,
.ps.ps--active-y.ps--scrolling-y > .ps__rail-y {
    background-color: $gray-200;
}

.ps__rail-y>.ps__thumb-y {
    position: absolute;
    background-color: $gray-700;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, -webkit-border-radius .2s ease-in-out;
    transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, -webkit-border-radius .2s ease-in-out;
    -o-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
    -moz-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out, -moz-border-radius .2s ease-in-out;
    transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
    transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out, -webkit-border-radius .2s ease-in-out, -moz-border-radius .2s ease-in-out;
    right: 0;
    width: 6px;
}

perfect-scrollbar>.ps.ps--active-y>div.ps__rail-y,
perfect-scrollbar>.ps.ps--active-y:hover>div.ps__rail-y,
perfect-scrollbar>.ps.ps--active-y:active>div.ps__rail-y,
.ps--active-y>.ps__rail-y:hover>.ps__thumb-y,
.ps--active-y>.ps__rail-y:active>.ps__thumb-y {
    width: 6px;
    opacity: 1;
}

.ps--active-y>.ps__rail-y:hover>.ps__thumb-y,
.ps--active-y>.ps__rail-y:active>.ps__thumb-y {
    background-color: $gray-700;
}