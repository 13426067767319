// Core bootstrap
@import "../bootstrap/_tables.scss";

/**
 *  If you want to override some bootstrap styles, you have to code below.
 */

.table {
    &--grid {
        background-color: #ffffff;
        border-bottom: 1px solid #ffffff;
        line-height: 1.5;
        color: $text-color-table-grid;
        input[type="checkbox"] {
            display: table-cell;
            vertical-align: middle;
        }
        &__header {
            @include flexbox();
            @include align(center);
            background-color: $gray-300;
            height: $space-lg;
        }
        &__header-tab {
            height: 100%;
            padding: 0 $space-md;
            color: $text-color;
            cursor: pointer;
            position: relative;
            font-size: 1.333rem;
            @include flexbox();
            @include align(center);
            &:hover:not(.item--active) {
                color: $brand-secondary;
            }
            >i.far,
            >i.fas {
                position: absolute;
                right: $space-lg;
                top: 35%;
                &:hover {
                    color: $brand-secondary;
                }
            }
            &.active {
                background-color: #ffffff;
                color: $brand-primary;
                &:hover {
                    color: $brand-primary;
                }
            }
            .form-control {
                width: auto;
                margin-left: $space-md;
            }
        }
        &__body {
            >.row {
                margin: 0;
                border-bottom: 1px solid $divider;
                position: relative;
                padding: $space-table $space-sm;
                @include flexbox();
                @include align(center);
                &:first-child {
                    padding-top: ($space-table + ($space-table - $space-table / 4));
                    padding-bottom: $space-table;
                    border-bottom-width: 1px;
                    border-bottom-color: $divider;
                    >[class*=col-] {
                        @extend .user-select-none;
                        >.row {
                            [class*=col-] {
                                overflow: visible;
                                &:hover {
                                    cursor: pointer;
                                    span:not(.sorted):after {
                                        content: '\f0dc';
                                        font-family: 'Font Awesome 5 Pro';
                                        position: absolute;
                                        right: -25px;
                                        top: -2px;
                                        color: $brand-secondary;
                                        font-size: 1.5rem;
                                        font-weight: 700;
                                    }
                                }
                                >span {
                                    position: relative;
                                    display: inline-block;
                                    color: $text-color-inactive;
                                    font-size: 1.167rem;
                                }
                                >.sorted {
                                    color: $text-color-primary;
                                    &:before {
                                        content: '\f0d8';
                                        font-family: 'Font Awesome 5 Pro';
                                        position: absolute;
                                        right: -25px;
                                        top: -8px;
                                        color: $divider;
                                        font-size: 1.5rem;
                                        font-weight: 700;
                                    }
                                    &:after {
                                        content: '\f0d7';
                                        font-family: 'Font Awesome 5 Pro';
                                        position: absolute;
                                        right: -25px;
                                        bottom: -6px;
                                        color: $divider;
                                        font-size: 1.5rem;
                                        font-weight: 700;
                                    }
                                    &.asc {
                                        &:before {
                                            color: $brand-primary;
                                        }
                                    }
                                    &.desc {
                                        &:after {
                                            color: $brand-primary;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &:last-child {
                    border-bottom-width: 0;
                }
                &:hover {
                    &:not(:first-child) {
                        background-color: $gray-100;
                    }
                }
                &:hover {
                    [class*=col-] {
                        &:not(:last-child) {
                            cursor: pointer;
                        }
                    }
                    .table--grid {
                        &__actions {
                            &--hover {
                                display: block; // background-color: $gray-100;
                                @include media-breakpoint-up(xl) {
                                    padding: 0 $space-md;
                                }
                                background: -webkit-linear-gradient(to left, rgba(245, 245, 245, 1) 90%, rgba(245, 245, 245, 0.5) 100%);
                                background-image: linear-gradient(to left, rgba(245, 245, 245, 1) 90%, rgba(245, 245, 245, 0.5) 100%);
                                background-image: -ms-linear-gradient(to left, rgba(245, 245, 245, 1) 90%, rgba(245, 245, 245, 0.5) 100%);
                            }
                        }
                    }
                }
                &--no-cursor {
                    &:hover {
                        [class*=col-] {
                            &:not(:last-child) {
                                cursor: inherit;
                            }
                        }
                    }
                }
                [class*=col-] {
                    .row {
                        [class*=col-] {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-size: 1.333rem;
                        }
                    }
                }
                &.inactive {
                    color: $gray-400;
                }
            }
        }
        [class*=col-] {
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        &__action-btn {
            &.btn {
                font-size: 1.25rem;
                background-color: transparent;
                border: none;
                cursor: pointer;
                outline: none;
                padding-top: 0;
                padding-bottom: 0;
                margin-right: $space-sm;
                line-height: 1;
                position: relative;
                z-index: 1000;
                @include media-breakpoint-down(lg) {
                    width: 125px;
                    height: 25px;
                    &:before {
                        content: '';
                        width: 100%;
                        height: 100%;
                        z-index: 10000;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: rgba(255, 255, 255, 0);
                    }
                }
                &:hover {
                    // color: $brand-secondary;
                    i {
                        color: $brand-secondary;
                    }
                }
                &:last-child {
                    margin-right: 0;
                }
                i {
                    color: $gray-600;
                }
            }
            &--lighter {
                &.btn {
                    i {
                        color: $gray-600;
                    }
                }
            }
        }
        &__caret {
            margin-left: $space-sm;
        }
        &__actions {
            &__links {
                margin: 0;
                padding: 0;
                z-index: 1;
                min-width: 0;
                &:before,
                &:after {
                    border: 0;
                }
                .dropdown-item {
                    border: 0;
                }
            }
            @include media-breakpoint-up(xl) {
                &__ellipsis {
                    display: none;
                }
                &__links {
                    display: block;
                    position: relative;
                    border: 0;
                    background-color: transparent;
                    .dropdown-item {
                        display: inline-block;
                        width: auto;
                        padding: 0;
                        padding-right: $space-md;
                        &:last-child {
                            padding-right: 0;
                        }
                    }
                }
                &--hover {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    display: none;
                    width: auto;
                    @include transform(translateY(-50%));
                }
            }
            @include media-breakpoint-down(lg) {
                text-align: right;
                &__ellipsis {
                    margin: 0;
                    padding: 5px 12px;
                    border: 1px solid transparent;
                    border-bottom-width: 0;
                    &:before,
                    &:after {
                        content: '' !important;
                    }
                }
                &__links {
                    border-color: $gray-600;
                    &__icon {
                        z-index: 1;
                        &:after {
                            content: attr(name);
                            font-family: $font-family-base;
                            margin-left: $space-sm;
                            font-size: 1.2rem;
                            font-weight: $font-regular;
                            z-index: 1;
                            position: absolute;
                            left: 20px;
                            top: 50%;
                            @include transform(translateY(-50%));
                        }
                        &:before {
                            position: absolute;
                            left: 0;
                            top: 50%;
                            @include transform(translateY(-50%));
                        }
                    }
                    .dropdown-item {
                        padding: $space-xs $space-sm;
                        &:not(:last-child) {
                            border-bottom: 1px solid $gray-600;
                        }
                    }
                    .list-inline-item {
                        .table--grid__actions__links__icon {
                            &:after {
                                content: '';
                                margin: 0;
                            }
                        }
                    }
                }
                &[class*=col-] {
                    overflow: inherit;
                }
            }
            .btn-group {
                .dropdown-menu {
                    float: none;
                }
                &.open {
                    .dropdown-toggle {
                        border-color: $gray-600;
                        .table--grid {
                            &__actions__ellipsis__icon {
                                &:before {
                                    content: '\f00d';
                                    font-weight: 700;
                                }
                            }
                        }
                    }
                }
            }
        }
        .card {
            margin-bottom: 0;
            &-header {
                padding-top: ($grid-gutter-width-base / 2);
                padding-bottom: ($grid-gutter-width-base / 2);
                background-color: #ffffff;
                position: absolute;
                border-radius: 0;
                bottom: -4.083rem;
                width: 100%;
            }
            &-title {
                margin-bottom: 0;
                text-align: center;
            }
            &-block {
                padding: 0;
            }
        }
    }
}

.b2c-table {
    &--grid {
        gap: $space-md;
        background-color: #ffffff;
        border-bottom: 1px solid #ffffff;
        line-height: 1.5;
        color: $text-color-table-grid;
        border: 1px solid #ccc;
        border-radius: 1.28rem;
        -moz-border-radius: 1.28rem;
        -webkit-border-radius: 1.28rem;

        input[type="checkbox"] {
            display: table-cell;
            vertical-align: middle;
        }
        &__header {
            @include flexbox();
            @include align(center);
            background-color: $darkBlue;
            color: #FFF;
            height: $space-lg;
        }
        &__body {
            >.row {
                margin: 0;
                border-bottom: 1px solid $divider;
                position: relative;
                padding: $space-table $space-sm;
                @include flexbox();
                @include align(center);
                &:first-child {
                    padding-top: ($space-table + ($space-table - $space-table / 4));
                    padding-bottom: $space-table;
                    border-bottom-width: 1px;
                    border-bottom-color: $divider;
                    background-color: $darkBlue;
                    color: #FFF;
                    border-radius: 1.28rem 1.28rem 0px 0px;
                    -moz-border-radius: 1.28rem 1.28rem 0px 0px;
                    -webkit-border-radius: 1.28rem 1.28rem 0px 0px;
                    >[class*=col-] {
                        @extend .user-select-none;
                        >.row {
                            [class*=col-] {
                                overflow: visible;
                                &:hover {
                                    cursor: pointer;
                                    span:not(.sorted):after {
                                        content: '\f0dc';
                                        font-family: 'Font Awesome 5 Pro';
                                        position: absolute;
                                        right: -25px;
                                        top: -2px;
                                        color: $brand-secondary;
                                        font-size: 1.5rem;
                                        font-weight: 700;
                                    }
                                }
                                >span {
                                    position: relative;
                                    display: inline-block;
                                    color: #FFF;
                                    font-size: 1.367rem;
                                    font-weight: 700;
                                }
                                >.sorted {
                                    &:before {
                                        content: '\f0d8';
                                        font-family: 'Font Awesome 5 Pro';
                                        position: absolute;
                                        right: -25px;
                                        top: -8px;
                                        color: $divider;
                                        font-size: 1.5rem;
                                        font-weight: 700;
                                    }
                                    &:after {
                                        content: '\f0d7';
                                        font-family: 'Font Awesome 5 Pro';
                                        position: absolute;
                                        right: -25px;
                                        bottom: -6px;
                                        color: $divider;
                                        font-size: 1.5rem;
                                        font-weight: 700;
                                    }
                                    &.asc {
                                        &:before {
                                            color: $brand-primary;
                                        }
                                    }
                                    &.desc {
                                        &:after {
                                            color: $brand-primary;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &:last-child {
                    border-bottom-width: 0;
                    &:hover{
                        border-radius: 0 0 1.28rem 1.28rem;
                        -moz-border-radius: 0 0 1.28rem 1.28rem;
                        -webkit-border-radius: 0 0 1.28rem 1.28rem;
                    }
                }
                &:hover {
                    &:not(:first-child) {
                        background-color: $gray-100;
                    }
                }
                &:hover {
                    [class*=col-] {
                        &:not(:last-child) {
                            cursor: pointer;
                        }
                    }
                    .table--grid {
                        &__actions {
                            &--hover {
                                display: block; // background-color: $gray-100;
                                @include media-breakpoint-up(xl) {
                                    padding: 0 $space-md;
                                }
                                background: -webkit-linear-gradient(to left, rgba(245, 245, 245, 1) 90%, rgba(245, 245, 245, 0.5) 100%);
                                background-image: linear-gradient(to left, rgba(245, 245, 245, 1) 90%, rgba(245, 245, 245, 0.5) 100%);
                                background-image: -ms-linear-gradient(to left, rgba(245, 245, 245, 1) 90%, rgba(245, 245, 245, 0.5) 100%);
                            }
                        }
                    }
                }
                &--no-cursor {
                    &:hover {
                        [class*=col-] {
                            &:not(:last-child) {
                                cursor: inherit;
                            }
                        }
                    }
                }
                [class*=col-] {
                    .row {
                        [class*=col-] {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-size: 1.333rem;
                        }
                    }
                }
                &.inactive {
                    color: $gray-400;
                }
            }

            .mat-slide-toggle.mat-checked {
                .mat-slide-toggle-bar{
                    background-color: $green300;
                }
                .mat-slide-toggle-thumb{
                    background-color: $green900;
                }
            }
        }
        [class*=col-] {
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        &__action-btn {
            &.btn {
                font-size: 1.25rem;
                background-color: transparent;
                border: none;
                cursor: pointer;
                outline: none;
                padding-top: 0;
                padding-bottom: 0;
                margin-right: $space-sm;
                line-height: 1;
                position: relative;
                z-index: 1000;
                @include media-breakpoint-down(lg) {
                    width: 125px;
                    height: 25px;
                    &:before {
                        content: '';
                        width: 100%;
                        height: 100%;
                        z-index: 10000;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: rgba(255, 255, 255, 0);
                    }
                }
                &:hover {
                    // color: $brand-secondary;
                    i {
                        color: $brand-secondary;
                    }
                }
                &:last-child {
                    margin-right: 0;
                }
                i {
                    color: $gray-600;
                }
            }
            &--lighter {
                &.btn {
                    i {
                        color: $gray-600;
                    }
                }
            }
        }
        &__caret {
            margin-left: $space-sm;
        }
        &__actions {
            &__links {
                margin: 0;
                padding: 0;
                z-index: 1;
                min-width: 0;
                &:before,
                &:after {
                    border: 0;
                }
                .dropdown-item {
                    border: 0;
                }
            }
            @include media-breakpoint-up(xl) {
                &__ellipsis {
                    display: none;
                }
                &__links {
                    display: block;
                    position: relative;
                    border: 0;
                    background-color: transparent;
                    .dropdown-item {
                        display: inline-block;
                        width: auto;
                        padding: 0;
                        padding-right: $space-md;
                        &:last-child {
                            padding-right: 0;
                        }
                    }
                }
                &--hover {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    display: none;
                    width: auto;
                    @include transform(translateY(-50%));
                }
            }
            @include media-breakpoint-down(lg) {
                text-align: right;
                &__ellipsis {
                    margin: 0;
                    padding: 5px 12px;
                    border: 1px solid transparent;
                    border-bottom-width: 0;
                    &:before,
                    &:after {
                        content: '' !important;
                    }
                }
                &__links {
                    border-color: $gray-600;
                    &__icon {
                        z-index: 1;
                        &:after {
                            content: attr(name);
                            font-family: $font-family-base;
                            margin-left: $space-sm;
                            font-size: 1.2rem;
                            font-weight: $font-regular;
                            z-index: 1;
                            position: absolute;
                            left: 20px;
                            top: 50%;
                            @include transform(translateY(-50%));
                        }
                        &:before {
                            position: absolute;
                            left: 0;
                            top: 50%;
                            @include transform(translateY(-50%));
                        }
                    }
                    .dropdown-item {
                        padding: $space-xs $space-sm;
                        &:not(:last-child) {
                            border-bottom: 1px solid $gray-600;
                        }
                    }
                    .list-inline-item {
                        .table--grid__actions__links__icon {
                            &:after {
                                content: '';
                                margin: 0;
                            }
                        }
                    }
                }
                &[class*=col-] {
                    overflow: inherit;
                }
            }
            .btn-group {
                .dropdown-menu {
                    float: none;
                }
                &.open {
                    .dropdown-toggle {
                        border-color: $gray-600;
                        .table--grid {
                            &__actions__ellipsis__icon {
                                &:before {
                                    content: '\f00d';
                                    font-weight: 700;
                                }
                            }
                        }
                    }
                }
            }
        }
        .card {
            margin-bottom: 0;
            &-header {
                padding-top: ($grid-gutter-width-base / 2);
                padding-bottom: ($grid-gutter-width-base / 2);
                background-color: #ffffff;
                position: absolute;
                border-radius: 0;
                bottom: -4.083rem;
                width: 100%;
            }
            &-title {
                margin-bottom: 0;
                text-align: center;
            }
            &-block {
                padding: 0;
            }
        }
    }
}
