// Core bootstrap
@import "../bootstrap/_alert.scss";

/**
 *  If you want to override some bootstrap styles, you have to code below.
 */

.alert {
    font-weight: $font-medium;

    .fas {
        margin-right: $space-xs;
    }
}