.visible-tablet {
    display: block;
}

.hidden-tablet {
    display: none;
}

.se-page {
    background: #FFF;
    main {
        margin-top: 0 !important;
        padding: 0 !important;
        background: #ffffff;
        .container {
            padding: 0;
            overflow: inherit !important;
        }
        .wrapper__progress {
            padding-top: 0;
        }
    }
    app-footer {
        display: none;
    }
}

.di-page {
    background-color: #ffffff;
    app-footer {
        display: none;
    }
    main {
        margin-bottom: 0;
        padding-bottom: 0;
    }
    .main>.container {
        background-color: $body-bg;
    }
    symptoms-rating-component {
        .wrapper {
            padding-top: 0;
            .wrapper__selfevaluation {
                padding-top: 6px;
                padding-bottom: 0;
                .rating-area {
                    margin-top: -30px;
                    margin-bottom: 0;

                    .rating-scale {
                        .rating-scale__down {}
                        .rating-scale__up {}
                    }
                    p {
                        margin: 0;
                        padding-bottom: 60px;
                        &:not(:first-child) {
                            padding-top: 45px;
                        }
                    }
                }
            }
        }
    }
    .container {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
    }
}
