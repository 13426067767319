.cdk-overlay-container {
    z-index: 9999;
}

.mat-form,
form {
    .mat-form-field-prefix,
    .mat-form-field-suffix {
        .mat-icon-button {
            width: 3.5rem;
            height: 3.5rem;
            .mat-icon {
                font-size: 2rem;
                &:hover {
                    color: $brand-secondary;
                }
            }
        }
    }
    .mat-form-field {
        font-size: 1.3rem;
        font-family: $font-family-cera;
        &.mat-form-field-disabled {
            .mat-form-field-underline {
                background: $border-color;
            }
        }
        .mat-select {
            padding: 0.8rem 0 0.8rem 0;
            &.mat-select-disabled {
                .mat-select-arrow {
                    border-width: 0;
                }
            }
        }
        &.mat-form-field-invalid .mat-form-field-label,
        .mat-form-field-label {
            color: $text-color;
            font-weight: $font-regular;
            line-height: $input-line-height;
            font-family: $font-family-cera;
        }
    }
    .mat-form-field-underline {
        height: 1px;
        background-color: $border-color;
    }
    .form-group {
        label {
            margin-bottom: 0;
        }
        select.form-control:not([size]):not([multiple]) {
            height: 2rem;
            padding: 0;
            &:disabled {
                height: 3.5rem;
            }
        }
    }
    .form-control {
        font-size: 1.3rem;
        padding: 1px 0;
        border-width: 0 0 1px 0;
        box-shadow: 0 1px 0 0 transparent;
        margin-bottom: 1.25em;
        &:disabled {
            font-weight: 400;
            color: $gray-500;
            border-bottom-width: 1px;
            height: 3.5rem;
        }
        &:focus {
            box-shadow: 0 1px 0 0 $brand-secondary;
            @include transition(box-shadow 0.3s ease-in-out);
        }
    }
    .form-wrapper {
        margin-bottom: 3.5em;
    }
    .mat-input-element {
        padding: $input-padding-y 0;
        line-height: $input-line-height;
    }
    .mat-form-field-can-float.mat-form-field-should-float, 
    .mat-input-server:focus + .mat-form-field-label-wrapper {
         .mat-form-field-label {
            -webkit-transition-duration: 0s;
            -moz-transition-duration: 0s;
            -o-transition-duration: 0s;
            transition-duration: 0s;
            -webkit-transition-property: none;
            -moz-transition-property: none;
            -o-transition-property: none;
            transition-property: none;
         }
    }
}

.mat-form-field-infix {
    .form-text {
        position: absolute;
        top: 100%;
        margin-top: $space-xs;
    }
}
.cdk-overlay-pane{
    margin-top: 0;
}
.mat-optgroup-label {
    margin-bottom: 0;
}
