// Core bootstrap
@import "../bootstrap/_modal.scss";
/**
 *  If you want to override some bootstrap styles, you have to code below.
 */
body{
    &.modal-open{
        overflow-y: auto !important;
        &:has(.modal--custom.show){
            overflow-y: hidden !important;
        }
    }
}
.modal {
    background: #4f4f4f99;
    font-family: "Cera Pro";
    &-content {
        border-radius: 0;
    }
    &-header {
        background-color: $brand-primary;
        color: #ffffff;
        padding-left: $grid-gutter-width-base;
        padding-right: $grid-gutter-width-base;
        &+.modal-body {
            // border-radius: 0;
        }
    }
    &-body {
        background-color: #ffffff;
        padding: $grid-gutter-width-base;
        // border-radius: $modal-border-radius-width;
    }
    &-title {
        font-size: 1.667rem;
        font-family: "Begum Bold";
    }
    &-content {
        color: $text-color;
    }
    &-description {
        font-weight: $font-medium;
        padding-bottom: $space-sm;
    }
    &-icon {
        font-size: 3rem;
        color: $brand-primary;
        margin-top: $space-sm;
    }
    &-buttons {
        margin-top: $grid-gutter-width-base;
    }
    .mat-form-field,
    .mat-form,
    .mat-option,
    .mat-option-text,
    .mat-select,
    .mydp .selection,
    .mat-select-value{
        font-family: $font-family-cera;
        color: $text-color;
    }
    b,
    strong,
    h1, h2, h3, h4, h5, h6 {
        font-family: $font-family-cera-bold;
    }
    .mat-form .mat-form-field .mat-form-field-label,
    .mat-form-field.mat-form-field-invalid .mat-form-field-label,
    form .mat-form-field.mat-form-field-invalid .mat-form-field-label,
    label{
        color: $blue-500;
    }
    .mydp .btnclear,
    .mydp .btndecrease,
    .mydp .btnincrease,
    .mydp .btnpicker,
    .mydp .headerbtn,
    .mydp .headermonthtxt,
    .mydp .headertodaybtn,
    .mydp .headeryeartxt,
    .mydp .yearchangebtn,
    input[type=radio] + label,
    input[type=radio] + label,
    input[type=checkbox] + label{
        color: $text-color;
    }
    button {
        &.close {
            font-size: 1.5rem;
            font-weight: 400;
            position: absolute;
            color: #fff;
            opacity: 1;
            z-index: 1;
            top: -40px;
            right: 25px;
            text-shadow: none;
            font-family: 'Font Awesome 5 Pro';
            &:before {
                content: '\f00d';
            }
            &:hover {
                color: $brand-secondary;
            }
        }
    }
    &-footer {
        background-color: #ffffff;
        border: 0;
        margin-top: -1px; //fix ie issue
    }

    &.patient-creation-modal,
    &.patient-selfevaluation-modal,
    &.user-feedback-modal,
    &.marked-patient-modal,
    &.diagnosis-notify-modal,
    &__material,
    &.patient-sendreminder-modal{
        background: #4f4f4f99;
        .modal-dialog{
            width: 750px;
        }
        .modal-content{
            .modal-header{
                background: #FFFFFF;
                padding: 56px 32px 0px 48px;

                > button.close{
                    color: $gray-800;
                    font-size: 2.5rem;
                    font-weight: lighter;
                    top: 36px;
                    right: 36px;
                    height: 18px;
                    width: 18px;
                    line-height: 18px;
                }
                > .modal-title{
                    font-family: "Begum Bold";
                    font-size: 3rem;
                    color: $brand-primary;
                    font-weight: 500;
                    line-height: 3.6rem;
                }
            }
            .modal-body{
                padding: 32px 48px;
                button{
                    font-size: 1.333rem;
                    &.btn-primary{
                        padding: 1.367rem 2.667rem;
                        border-radius: 2.2rem;
                        min-width: 200px;
                    }
                }
                .tooltip-study{
                    .tooltip-inner{
                        text-align: left;
                    }
                }
            }
        }
    }

    &.diagnosis-notify-modal {
        button{
            font-size: 1.333rem;
            &.btn{
                padding: 1.367rem 2.667rem;
                border-radius: 2.2rem;
                min-width: 105px;

                &.btn-dismiss {
                    background: none;
                    border: 1px solid $text-color-inactive;
                    color: $text-color-inactive;
                    padding: 1.2654rem 2.667rem;
                }
            }
        }

        [type="checkbox"] + label {
            line-height: 20px;
        }

        .modal-title {
            text-align: left;
        }

        .modal-body {
            text-align: left;
        }

        .close {
            right: 0;
            text-align: right;
        }
    }
}

.modal-confirmation {
    text-align: center;
    &:before {
        content: '';
        height: 100%;
        display: inline-block;
        vertical-align: middle;
    }
    &-dialog {
        display: inline-block;
        vertical-align: middle;
    }
}

.notification-release{
    .modal-content,
    .modal-description{
        text-align: initial;
        padding-top: 1rem;
    }
}