/* style variables */

// Font Configuration
@import url('https://fonts.googleapis.com/css?family=Roboto:400,400i,500,500i,700,700i');
$font-family-cera          : 'Cera Pro' !default;
$font-family-cera-bold     : 'Cera Pro Bold' !default;
$font-family-base          : $font-family-cera;
$font-family-header        : 'Begum Bold' !default;
$font-size-root            : 12px !default;
$font-size-base            : 1.167rem !default;
$text-security-font-path   : '~/../fonts/securetext/' !default;

$font-regular              : 400 !default;
$font-medium               : 500 !default;
$font-bold                 : 700 !default;


// Color Configuration
$brand-primary             : #00516C !default;
$brand-primary-light       : #4788af !default;
$brand-primary-light-2     : #3D7D96 !default;
$brand-primary-dark        : #003252 !default;
$brand-secondary           : #00ACD2 !default;
$brand-secondary-light     : #5fbfff !default;
$brand-secondary-dark      : #0062a3 !default;
$brand-danger              : #ff0101 !default;
$text-color                : #58595B !default;
$text-color-primary        : #4F4F4F !default;
$text-color-secondary      : #757575 !default;
$text-color-inactive       : #848484 !default;
$text-color-table-grid     : #2F2F2F !default;
$gray-50                   : #fafafa !default;
$gray-100                  : #f5f5f5 !default;
$gray-200                  : #eeeeee !default;
$gray-300                  : #e0e0e0 !default;
$gray-400                  : #bdbdbd !default;
$gray-500                  : #9e9e9e !default;
$gray-600                  : #757575 !default;
$gray-700                  : #616161 !default;
$gray-800                  : #424242 !default;
$gray-900                  : #212121 !default;
$green-500                 : #4E7D08 !default;
$blue-500                  : #005B7F !default;
$divider                   : $gray-300 !default;
$brand-blue-light          : #d1e9ed !default;
$brand-orange-light        : #fee2aa !default;
$brand-green-light         : #ccf0d4 !default;
$symptom-reduced           : #A2E0B5 !default;
$symptom-increased         : #EDA8B1 !default;
$symptom-new-appearance    : #AABBD6 !default;
$symptom-unchanged         : #EDF9FE !default;
$symptom-rating-low        : #FCE76E !default;
$symptom-rating-middle     : #EF995C !default;
$symptom-rating-high       : #FC555E !default;


// Variables for B2C Admin Dashboard
$darkBlue: #202A4D !default;
$brightYellow: #FFF785 !default;
$gray: #BCBFCA !default;
$grayScale: #dedfe4 !default;
$grayScaleBackground: #F8F9FA !default;
$grayBackdrop: #9095A6 !default;
$grayLanding: #E9EAED !default;
$pink100: #FFF0F4 !default;
$pink300: #FFB5C8 !default;
$pink500: #ff6b92 !default;
$red500: #ffa991 !default;
$green30: #D4F3EA !default;
$green100: #E5F8F2 !default;
$green300: #B7ECDC !default;
$green500: #6FD8B8 !default;
$green900: #59AD93 !default;
$greenHover: #9AD8C5 !default;
$greenBackground: #E2F7F1 !default;
$gray500: #F0F0F0 !default;
$aqua500: #037db1 !default;
$aqua550: #036F9D !default;
$blue500: #81BED8 !default;
$blue100: #E8F3F8 !default;
$yellow500: #FFFBC2 !default;
$yellow100: #FFFEF3 !default;
$disabledBackground: #E1E1E2 !default;
$white: #FFF !default;
$slate100: #f1f5f9 !default;
$slate200: #e2e8f0 !default;
$slate300: #cbd5e1 !default;
$sky200: #bae6fd !default;
$orange100: #ffecb3 !default;
$orange500: #ff9800 !default;
$shadowBorder: #eeeef1 !default;

// Variable for Klenico Me
$me-main-color: #202a4d;
$me-secondary-color: #fffef3;
$me-yellow-color: #fffbc2;

// Input
$input-padding-y           : .55rem !default;
$input-bg-disabled         : transparent !default;
$input-border-color        : $gray-400 !default;

$body-bg                   : $gray-100 !default;
$body-color                : $text-color !default;
$border-color              : $gray-400 !default;
$body-min-width            : 1024px !default;
$container-max-widths      : ( sm: 540px, md: 720px, lg: 960px, xl: 1170px) !default;

// Space (padding, margin)
$space-value               : 16px !default;

$space-xs                  : $space-value / 2 !default;
$space-sm                  : $space-value * 1 !default;
$space-md                  : $space-value * 2 !default;
$space-lg                  : $space-value * 3 !default;
$space-xl                  : $space-value * 4 !default;
$space-table               : 12px !default;
$spacers: (
    0: (
        x: 0,
        y: 0
    ),
    1: (
        x: $space-value,
        y: $space-value
    ),
    2: (
        x: ($space-value * 2),
        y: ($space-value * 2)
    ),
    3: (
        x: ($space-value * 3),
        y: ($space-value * 3)
    )
) !default;

$font-se-title: 26px;
$font-se-title-small: 24px;
$font-se-subtitle: 20px;
$font-se-paragraph: 16px;
$font-se-label: 14px;
$font-se: (
    title: $font-se-title,
    title-small: $font-se-title-small,
    subtitle: $font-se-subtitle,
    paragraph: $font-se-paragraph,
    label: $font-se-label
) !default;

$spacing-se-title: 34px;
$spacing-se-title-small: 30px;
$spacing-se-subtitle: 26px;
$spacing-se-paragraph: 22px;
$spacing-se-label: 16px;
$spacing-se: (
    title: $spacing-se-title,
    title-small: $spacing-se-title-small,
    subtitle: $spacing-se-subtitle,
    paragraph: $spacing-se-paragraph,
    label: $spacing-se-label
) !default;

$grid-gutter-width-base:     32px !default;

$btn-secondary-bg          : $gray-600 !default;
$logo-width                : 220px !default;
$logo-height               : 55px !default;
$image-path                : '/assets/img/' !default;
$icon-path                : '/assets/icon/' !default;
$sprite-width              : 600px;
$sprite-height             : 800px;
$card-border-width         : 0 !default;
$card-border-radius        : 0 !default;
$card-spacer-x             : 30px !default;
$card-spacer-y             : 40px !default;
$modal-content-bg          : transparent !default;
$modal-content-border-width: 0 !default;
$modal-header-border-color : transparent !default;
$modal-border-radius-width : .7rem !default;

$modal-lg                  : 1280px !default;
$modal-md                  : 950px !default;
$modal-sm                  : 500px !default;
$popover-bg                : $gray-600 !default;
$popover-title-bg          : $brand-primary !default;
$popover-title-padding-y   : $space-sm !default;
$popover-arrow-color       : #ffffff !default;
$popover-inner-padding     : 0 !default;
$popover-max-width         : 600px !default;
$pagination-padding-y      : 0 !default;
$pagination-color          : $gray-500 !default;
$pagination-bg             : transparent !default;
$pagination-border-width   : 0 !default;
$pagination-border-color   : transparent !default;
$pagination-hover-color    : $brand-primary !default;
$pagination-hover-bg       : transparent !default;
$pagination-active-color   : $brand-primary !default;
$pagination-active-bg      : transparent !default;
$pagination-disabled-color : $gray-500 !default;
$pagination-disabled-bg    : transparent !default;


$diagnoses-width           : 680px;
$diagnoses-width-xl        : 830px;

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-modal:             1080 !default;

/*
 * Tooltips
 */

 $tooltip-color            : #ffffff !default;
 $tooltip-bg               : $brand-primary-light !default;

 // Navs
 $nav-tabs-border-width: 0 !default;
 $nav-tabs-active-link-hover-bg: #ffffff !default;
/*
 * Font headings
 */
$font-size-h4              : 1.2rem !default;

$close-text-shadow         : transparent !default;

:root{
  --outline-focus: 2px solid #00ACD2;
}
@media (max-width: 1024px){
  :root{
    --outline-focus: 0 !important;
  }
}
/*
 * Gray color levels
 */
// Colors
// Body min width
// Grid containers
// Paddings
// Buttons
// Logo specification
// Image Path
// Sprite Size
// Cards
// Modal
// Popover
// Pagination
// Core variables and mixins
// Bootstrap variables
// Components
// Core variables and mixins
@import 'bootstrap/_variables.scss'; // Components
@import 'bootstrap-customized/_mixins.scss';
@import 'bootstrap-customized/_animate.scss';
