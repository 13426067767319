// Core bootstrap
@import "../bootstrap/_grid.scss";

/**
 *  If you want to override some bootstrap styles, you have to code below.
 */

.container {
    @include media-breakpoint-down(lg) {
        width: 100%;
    }
}

.row {
    &.inline {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;

        .col {
            position: relative;
            width: 100%;
            min-height: 1px;
            padding-right: 15px;
            padding-left: 15px;

            -ms-flex-preferred-size: 0;
            flex-basis: 0;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            max-width: 100%;
        }
    }
}