// Core bootstrap
@import "../bootstrap/_popover.scss";
// @import "../_variables.scss";
/**
 *  If you want to override some bootstrap styles, you have to code below.
 */

.popover {
    cursor: default;
    border-radius: 0;
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, .3);
    box-shadow: 0 2px 15px rgba(0, 0, 0, .3);
    background-color: #ffffff;
    border-width: 0;
    font-size: $font-size-base;
    &.popover-left,
    &.bs-tether-element-attached-right {
        margin-left: calc(-#{$popover-arrow-width} - 5px);
    }
    .popover-content {
        >button {
            &.close {
                font-size: 1.5rem;
                font-weight: 400;
                position: absolute;
                color: $gray-700;
                opacity: 1;
                z-index: 1;
                top: 18px;
                right: 15px;
                text-shadow: none;
                &:before {
                    content: '\f00d';
                    font-family: 'Font Awesome 5 Pro';
                    font-weight: 700;
                }
                &:hover {
                    color: $brand-secondary;
                }
            }
        }
    }
}

.popover-title {
    color: #ffffff;
    border-bottom-width: 0;
    font-weight: $font-medium;
    border-radius: 0;
    font-size: 1.66rem;
}

.popover.popover-bottom .popover-title::before,
.popover.bs-tether-element-attached-top .popover-title::before {
    display: none;
}

.popover-content {
    color: $gray-700; // border: 3px solid $gray-400;
    border-top-width: 0;
    background-color: #fff;
    img {
        max-width: 100%;
        height: auto;
    }
}

.popover.popover-bottom::after,
.popover.bs-tether-element-attached-top::after {
    border-bottom-color: $brand-primary;
}

// for domain component
$popover-width-xl: 1100px;
$popover-width: 960px;
.domain-container {
    .domain__popover.popover {
        @include transform(translateY(10px));
        >.popover-content {
            padding: ($space-md + 3) $space-sm;
            font-size: 1.16rem;
            .symptom-domain {
                &:not(:last-child) {
                    margin-bottom: $space-sm;
                }
            }
        }
        &,
        &.popover-bottom::before,
        &.bs-tether-element-attached-top::before {
            box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.3);
        }
        &.popover-bottom::before,
        &.bs-tether-element-attached-top::before {
            width: 25px;
            height: 25px;
            background-color: #fff;
            transform: rotate(45deg);
            z-index: -1;
        }
        &.popover-bottom::after,
        &.bs-tether-element-attached-top::after {
            border: 0;
        }
    }
}

.domain-container {
    &.left {
        &:not(.with-subdomains) .domain__popover.popover,
        .domain__popover.popover .domain__popover.popover {
            @include transform(translate($popover-width-xl / 3 + 2, 10px));
            @include media-breakpoint-down(lg) {
                @include transform(translate($popover-width / 3 + 2, 10px));
            }
        }
    }
    &.right {
        &:not(.with-subdomains) .domain__popover.popover,
        .domain__popover.popover .domain__popover.popover {
            @include transform(translate(-$popover-width-xl / 3, 10px));
            @include media-breakpoint-down(lg) {
                @include transform(translate(-$popover-width / 3, 10px));
            }
        }
    }
}

.domain-container.with-subdomains {
    >.domain__popover.popover {
        min-width: 340px;
        >.popover-content {
            background-color: #FFF;
        }
        &.popover-bottom::before,
        &.bs-tether-element-attached-top::before {
            background-color: $gray-50;
        }
    }
}

.domain-container {
    .domain__popover.popover,
    .domain__popover.popover .domain__popover.popover {
        min-width: $popover-width-xl;
        @include media-breakpoint-down(lg) {
            min-width: $popover-width;
        }
    }
    &.left:not(.with-subdomains) .domain__popover.popover,
    &.left.with-subdomains .domain__popover.popover .domain__popover.popover {
        &:before {
            @include transform(translateX(-$popover-width-xl / 3) rotate(45deg));
            @include media-breakpoint-down(lg) {
                @include transform(translateX(-$popover-width / 3) rotate(45deg));
            }
        }
    }
    &.right:not(.with-subdomains) .domain__popover.popover,
    &.right.with-subdomains .domain__popover.popover .domain__popover.popover {
        &:before {
            @include transform(translateX($popover-width-xl / 3) rotate(45deg));
            @include media-breakpoint-down(lg) {
                @include transform(translateX($popover-width / 3) rotate(45deg));
            }
        }
    }
}

// for symptom-item component
.feelings-popover {
    width: 100%;
    max-width: 100%;
    background-color: $gray-50;
    padding: 0 $space-sm $space-sm;
    font-size: 1.167rem;
    .popover-content {
        padding: 0;
        color: $text-color;
    }
    &.popover.popover-bottom::after,
    &.popover.bs-tether-element-attached-top::after {
        border-bottom-color: $gray-50;
    }
}

.popover.domain__popover {
    max-width: inherit;
    left: 0 !important;
    right: 0 !important;
    margin: 25px auto 0 auto;
    &:before,
    &:after {
        display: none;
    }
}

.with-subdomains {
    .domain__popover {
        max-width: 100%;
        margin-top: 16px;
        transition-duration: 0s;
    }
}

.popover-revocation-patient-agreement {
    right: 15px;
    left: auto !important;
    width: 550px;
    .popover-header {
        display: none;
    }
    .popover-content {
        padding: $space-lg;
    }
    &.popover-bottom,
    &.bs-tether-element-attached-top {
        &:before {
            border-bottom-color: rgba(0, 0, 0, 0.15);
        }
        &:after {
            border-bottom-color: #ffffff;
        }
        &::before,
        &::after {
            left: 80%;
        }
    }
}

.with-subdomains{
    .bs-popover-bottom.bottom {
        > .arrow {
            right: 44% !important;
        }
    }
}
.bs-popover-bottom{
    &.popover-revocation-patient-agreement,
    &.help-btn-all{
        .popover-arrow.arrow{
            display: none;
        }
    }

    .arrow{
        border-width: 0px;
        margin-left: 0;
        -webkit-box-shadow: 0px -7px 18px -8px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px -7px 18px -8px rgba(0, 0, 0, 0.75);
        box-shadow: 0px -7px 18px -8px rgba(0, 0, 0, 0.75);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 67px;
        background: #FFF;
        z-index: 1070;
        width: 22px;
        height: 22px;
        position: absolute;
        top: -10px;
    }
    &.left{
        > .arrow{
            left: 18% !important;
        }
    }
    &.right{
        > .arrow{
            right: 16% !important;
            left: auto !important;
        }
    }

    .feelings-popover{
        > .arrow{
            display: none;
        }

    }

}

.bs-popover-left, 
.bs-popover-right {
    .arrow {
        margin-top: 0 !important;
    }
}
