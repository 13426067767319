.mat-form {
    .kl-datepicker {
        .selection,
        .weekdaytitle,
        .monthinput,
        .yearinput {
            font-weight: 400;
        }
        .selection {
            font-size: 1.3rem !important;
            padding: $input-padding-y 0;
        }
    }
    .kl-datepicker {
        .mydp {
            border-radius: 0px;
            .selectiondisabled {
                background: #ffffff;
                color: $input-color-placeholder;
                opacity: .45;
            }
            .btnclear,
            .btndecrease,
            .btnincrease,
            .btnpicker {
                height: 30px;
                width: 30px;
                padding: 5px;
                margin-top: -10px;
                border-radius: 100% !important;
            }
            .btncleardisabled,
            .btnpickerdisabled {
                // background-color: $gray-400;
                color: $gray-200;
            }
            * {
                border-radius: 0px;
            }
            input:focus {
                background-color: transparent;
            }
            .header {
                border-radius: 4px 4px 0 0;
                background-color: transparent;
            }
            .headerbtncell {
                background-color: transparent;
            }
            .caltable {
                border-radius: 0 0 4px 4px;
                tbody tr:nth-child(6) td:first-child {
                    border-bottom-left-radius: 4px;
                }
            }
            .weekdaytitle {
                color: $text-color;
                font-size: $font-size-base;
                background-color: transparent;
            }
        }
    }
    .mydp {
        border-width: 0;
        * {
            font-family: "Cera Pro";
        }
        .headertodaybtn {
            border-width: 0;
        }
        .selection {
            color: $text-color;
        }
        .selectedday,
        .selectedmonth,
        .selectedyea {
            .datevalue,
            .monthvalue,
            .yearvalue {
                color: #ffffff;
                background-color: transparent;
            }
        }
        .markcurrday,
        .markcurrmonth,
        .markcurryear {
            text-decoration: none;
        }
        .caltable,
        .daycell,
        .monthcell,
        .monthtable,
        .weekdaytitle,
        .yearcell,
        .yeartable {
            color: $text-color;
            background-color: transparent;
            &:focus {
                outline: 0;
                border: 0;
            }
        }
        .daycell {
            padding: 4px 0;
            border-radius: 100% !important;
        }
        .yearcell,
        .monthcell,
        .daycell {
            overflow: hidden;
            &:focus {
                outline: 0;
                border-width: 0;
            }
            &.selectedmonth,
            &.selectedyear,
            &.selectedday {
                color: #ffffff;
                background-color: $brand-secondary;
            }
        }
        .selectedday .datevalue,
        .selectedmonth .monthvalue,
        .selectedyear .yearvalue {
            background-color: transparent;
        }
        .headerbtn,
        .monthlabel,
        .yearchangebtn,
        .yearlabel {
            &:focus {
                color: $text-color;
            }
        }
        .selectorarrow {
            background-color: #ffffff;
            margin-top: 0;
            &:before,
            &:after {
                border-width: 0;
            }
        }
        .headerbtn,
        .headerlabelbtn,
        .yearchangebtn {
            background-color: #ffffff;
        }
        .selector {
            padding: 10px;
            width: 300px !important;
            height: 310px !important;
            border-color: transparent !important;
            box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
        }
        .currmonth {
            background-color: transparent;
        }
        .icon-mydpdown,
        .icon-mydpleft,
        .icon-mydpright,
        .icon-mydpup {
            opacity: .7;
        }
        .highlight {
            color: $brand-danger;
        }
        .disabled {
            background-color: transparent;
            opacity: .7;
        }
        .invaliddate {
            background-color: transparent;
        }
    }
}
