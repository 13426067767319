// Core bootstrap
@import "../bootstrap/_dropdown.scss";

/**
 *  If you want to override some bootstrap styles, you have to code below.
 */

.dropdown {
    &.open {
        .dropdown-toggle {
            &::after {
                content: '\f106';
                color: $brand-secondary;
            }
            &--no-caret {
                &::after {
                    content: '';
                    width: 0;
                }
            }
        }
    }
}

.dropdown-menu {
    padding: 0;
    border-radius: 0;
    &:before,
    &:after {
        content: '';
        position: absolute;
        left: 50%;
        display: inline-block;
        @include transform(translateX(-50%));
    }
    &:before {
        top: -10px;
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
        border-bottom: 10px solid $border-color;
    }
    &:after {
        top: -9px;
        border-right: 9px solid transparent;
        border-left: 9px solid transparent;
        border-bottom: 9px solid $gray-400;
    }
}

.dropdown-toggle {
    &:after {
        border: 0;
        content: '\f107';
        font-family: 'Font Awesome 5 Pro';
        font-size: 1.667rem;
        position: absolute;
        font-weight: 700;
        color: $gray-600;
        top: 50%;
        right: 10px;
        height: auto;
        width: auto;
        @include transform(translateY(-50%));
        @include transition(all ease-in-out .2s);
    }
    &:hover {
        .dropdown-menu {
            display: block;
        }
        &:after {
            color: $brand-secondary;
        }
    }
    &--no-caret {
        &::after {
            content: '';
            width: 0;
        }
    }
}

.dropdown-item {

    &:hover,
    &:focus,
    &.active,
    &.active:hover,
    &.active:focus{
        background-color: $gray-100;
        outline: none;
        cursor: pointer;
        color: $gray-600;
    }
    &:last-child {
        border-bottom-width: 0;
    }
}

.dropdown-header {
    padding: .75rem 1.5rem;
    background-color: $gray-100;
    color: $gray-700;
    font-weight: $font-medium;
    text-transform: capitalize;
    border-bottom: 1px solid $dropdown-border-color;
}

.dropdown-menu-center {
    left: 50%;
    right: auto;
    @include transform(translateX(-50%));
}

.cdk-overlay-pane{
    margin-top: 2.6rem;
}
