// Core bootstrap
@import "../bootstrap/_media.scss";

/* Prevent change landscape to portrait mode */

.rotate-device {
}
/*
  ##Device = Tablets, Ipads (Portrait)
  ##Screen = B/w 481px to 1024px
*/
//@media screen and (min-width: 481px) and (max-device-aspect-ratio: 13/9) and (orientation: portrait),
/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = max-width 480px , Landcape => max-height 480px
*/
.rotate-device {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    background-color: $brand-primary;
    @include flexbox();
    @include justify-content(center);
    @include align(center);
    @include flex-direction(column);
    .img-rotate {
        background-image: url("#{$image-path}pages/rotate-device.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        width: 200px;
        height: 106px;
    }
    .text-rotate {
        color: #ffffff;
        font-size: 2rem;
        margin-top: $space-md;
    }
}
