.app-loading {
    opacity: .3;
}

.spinner-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: wait;
    z-index: 99999;
    background: rgba(0, 0, 0, .5);
    &--partial {
        position: absolute;
        background-color: hsla(0, 0%, 100%, .7);
        .spinner {
            position: absolute;
            top: calc(50% - 89px);
        }
    }
    &.bg-dark{
        background: rgba(0, 0, 0, .5);
    }
}

.spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 9999;
    display: block;
    width: 29px;
    @include transform(translate(-50%, -50%));
    &__loading {
        width: 26px;
        height: 26px;
        text-align: left;
        @include animation(spinner-loading-ani1 2.3s ease-in-out infinite);
        i,
        i:before {
            content: '';
            width: 26px;
            height: 26px;
            position: absolute;
        }
        i {
            border-radius: 50%;
            overflow: hidden;
            &:nth-child(1) {
                background: $brand-secondary;
                @include transform(translate(0, -14px));
                &:before {
                    background: $brand-secondary;
                    bottom: 0;
                    @include animation(spinner-loading-ani2 2.3s ease-in-out infinite);
                }
            }
            &:nth-child(2) {
                background: $brand-primary;
                @include transform(translate(0, 14px));
                &:before {
                    @include animation(spinner-loading-ani2 2.3s ease-in-out infinite);
                    background: $brand-primary;
                }
            }
        }
    }
}

@keyframes spinner-loading-ani1 {
    40%,
    50% {
        transform: rotate(180deg);
    }
    90%,
    100% {
        transform: rotate(360deg);
    }
}

@-o-keyframes spinner-loading-ani1 {
    40%,
    50% {
        -o-transform: rotate(180deg);
    }
    90%,
    100% {
        -o-transform: rotate(360deg);
    }
}

@-ms-keyframes spinner-loading-ani1 {
    40%,
    50% {
        -ms-transform: rotate(180deg);
    }
    90%,
    100% {
        -ms-transform: rotate(360deg);
    }
}

@-webkit-keyframes spinner-loading-ani1 {
    40%,
    50% {
        -webkit-transform: rotate(180deg);
    }
    90%,
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes spinner-loading-ani1 {
    40%,
    50% {
        -moz-transform: rotate(180deg);
    }
    90%,
    100% {
        -moz-transform: rotate(360deg);
    }
}

@keyframes spinner-loading-ani2 {
    40%,
    100% {
        height: 100%;
    }
    50%,
    90% {
        height: 0;
    }
}

@-o-keyframes spinner-loading-ani2 {
    40%,
    100% {
        height: 100%;
    }
    50%,
    90% {
        height: 0;
    }
}

@-ms-keyframes spinner-loading-ani2 {
    40%,
    100% {
        height: 100%;
    }
    50%,
    90% {
        height: 0;
    }
}

@-webkit-keyframes spinner-loading-ani2 {
    40%,
    100% {
        height: 100%;
    }
    50%,
    90% {
        height: 0;
    }
}

@-moz-keyframes spinner-loading-ani2 {
    40%,
    100% {
        height: 100%;
    }
    50%,
    90% {
        height: 0;
    }
}
