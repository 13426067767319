.br-t {
    border-radius        : .7rem .7rem 0 0;
    -moz-border-radius   : .7rem .7rem 0 0;
    -webkit-border-radius: .7rem .7rem 0 0;
}

.br{
    &--none{
        border: none !important;
    }
}

.mr-t {
    &--xs {
        margin-top: $space-xs;
    }
    &--sm {
        margin-top: $space-sm;
    }
    &--md {
        margin-top: $space-md;
    }
    &--lg {
        margin-top: $space-lg;
    }
}


.mr-b {
    &--xs {
        margin-bottom: $space-xs;
    }
    &--sm {
        margin-bottom: $space-sm;
    }
    &--md {
        margin-bottom: $space-md;
    }
    &--lg {
        margin-bottom: $space-lg;
    }
}

.mr-r {
    &--n{
        margin-right: 0 !important;
    }
    &--xs {
        margin-right: $space-xs;
    }
    &--sm {
        margin-right: $space-sm;
    }
    &--md {
        margin-right: $space-md;
    }
    &--lg {
        margin-right: $space-lg;
    }
    &--xl{
        margin-right: $space-xl;
    }
}

.mr-l {
    &--xs {
        margin-left: $space-xs;
    }
    &--sm {
        margin-left: $space-sm;
    }
    &--md {
        margin-left: $space-md;
    }
    &--lg {
        margin-left: $space-lg;
    }
}

.pd-h{
    &--none{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    &--sm {
        padding-left: $space-sm;
        padding-right: $space-sm;
    }
    &--md {
        padding-left: $space-md;
        padding-right: $space-md;
    }
    &--lg {
        padding-left: $space-lg;
        padding-right: $space-lg;
    }
}

.pd-v{
    &--none{
        padding-bottom: 0 !important;
        padding-top: 0 !important;
    }
    &--sm {
        padding-top: $space-sm;
        padding-bottom: $space-sm;
    }
    &--md {
        padding-top: $space-md;
        padding-bottom: $space-md;
    }
    &--lg {
        padding-top: $space-lg;
        padding-bottom: $space-lg;
    }
}

// Position : absolute
.pos{
    &--relative{
        position: relative !important;
    }
    &--absolute{
        position: absolute !important;
    }
    &--top{
        top: 0;
    }
    &--bottom{
        bottom: 0;
    }
    &--left{
        left: 0;
    }
    &--right{
        right: 0;
    }
}
.modal-center {
    &:before {
        content       : '';
        display       : inline-block;
        height        : 100%;
        vertical-align: middle;
        margin-right  : -4px;
    }
}

.box-shadow{
    -moz-box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.3);
}

.border-box{
    background-color: rgba(250, 250, 250, 1);
    border: none;
    border-radius: 2px;
    @extend .box-shadow;
}

.w-100{
    width: 100%;
}
.flex-wrap{
    flex-flow: wrap;
}
.align-self-start{
    align-self: flex-start;
}
.align-self-end{
    align-self: flex-end;
}
.flex-grow1 {
    flex-grow: 1;
}

.text-indent-4 {
    text-indent: 4rem !important;
}

.text-wrap-normal{
    word-wrap: normal !important;
}

.text-white{
    color: #FFF !important;
}

.badge-pill{
    border-radius        : 10rem;
    -moz-border-radius   : 10rem;
    -webkit-border-radius: 10rem;
    padding-right: 1.66em;
    padding-left: 1.66em;
}

.bg{
    &--white{
        background: #FFFFFF !important;
    }
    &--none{
        background: transparent !important;
    }
}

@each $tag, $size in $font-se {
    .font-se-#{$tag} {
        font-size: $size !important;
    }
}
@each $tag, $size in $spacing-se {
    .font-se-#{$tag} {
        line-height: $size !important;
    }
}
