// Core bootstrap
@import "../bootstrap/_pagination.scss";

/**
 *  If you want to override some bootstrap styles, you have to code below.
 */

.pagination {
    margin-top: $grid-gutter-width-base;
    margin-bottom: 0;
    position: relative;
    z-index: 1;
}

.page-link {
    padding: 0 1.5rem;
}

.page-item.active .page-link,
.page-item.active .page-link:focus {
    font-weight: $font-medium;
}

.page-link:focus, .page-link:hover {
    color: $brand-secondary;
    > i {
        color: $brand-secondary;
    }
}

.btn-link:disabled:focus,
.btn-link:disabled:hover {
    > i {
        color: $pagination-disabled-color;
    }
}