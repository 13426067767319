// Core bootstrap
@import "../bootstrap/_card.scss";

/**
 *  If you want to override some bootstrap styles, you have to code below.
 */

.card {
    &--box {
        background-color: transparent;
        @extend .user-select-none;
        margin: 0;
        &__header {
            height: 75px;
            @include flexbox();
            @include justify-content(space-between);
            @include align(center);
            background-color: $brand-primary;
            color: #ffffff;
            padding: 0 $space-md;
            overflow: hidden;
            font-size: 1.667rem;
            @include media-breakpoint-down(lg) {
                min-width: 100%;
            }
            &--info {
                display: inline-block;
            }
            &--action {
                display: block;
                padding-left: $space-xl;
                float: right;
            }
            &__title {
                font-size: 2.833rem;
                font-family: "Begum Bold";
                margin: 0;
                display: inline-block;
                vertical-align: middle;
                h2,
                h6 {
                    margin-bottom: 0;
                }
                h2 {
                    font-weight: $font-medium;
                }
                span {
                    font-size: 1.2rem;
                    font-weight: $font-medium;
                }
            }
            &__action {
                display: inline-block;
                margin-left: 1.6rem;
                font-size: .9rem;
                &__button {
                    color: #ffffff;
                    padding-left: 2.5rem;
                    min-width: 2rem;
                    &:active,
                    &:focus,
                    &:hover {
                        color: $brand-secondary;
                        span {
                            text-decoration: none;
                        }
                        i {
                            color: $brand-secondary;
                        }
                    }
                    i {
                        color: #ffffff;
                        font-size: 1rem;
                    }
                    &:not(:first-child) {
                        border-left: 1px solid #ffffff;
                        margin-left: 1rem;
                    }
                }
            }
            &--no-boder-radius {
                border-radius: 0;
            }
            @include media-breakpoint-down(lg) {
                max-width: 100%;
            }
        }
        .panel-header {
            background-color: $gray-300;
            width: 100%;
            border-radius: 2px;
            padding: 15px 0;
            line-height: 20px;
            &__info {
                .help-btn {
                    vertical-align: middle;
                }
            }
            &__title {
                padding-left: $space-md;
                color: $brand-primary-dark;
                font-size: 1.66rem;
                // @include flexbox();
                // @include align(center);
            }
            &__right {
                &--label {
                    font-size: 1.2rem;
                    padding-right: 30px;
                    line-height: 1.66rem;
                }
            }
        }
        &__block {
            background-color: #ffffff;
            clear: both;
            &--tabs {
                padding: 0;
                .nav-tabs {
                    .nav-item {
                        margin: 0;
                        .nav-link {
                            border-radius: 0;
                            border: 0;
                            font-weight: $font-regular;
                            padding: 0 $space-md;
                            line-height: $space-xl;
                            border-top: 4px solid $gray-300;
                            color: $gray-500;
                            border-bottom: 2px solid $gray-300;
                            &.active {
                                border-color: $brand-primary;
                                color: $brand-primary-dark;
                                border-radius: 0;
                                border-bottom-color: transparent;
                            }
                        }
                    }
                }
                .tab-content {
                    padding: 0;
                }
                &--border-top {
                    .nav-tabs {
                        .nav-item {
                            background-color: $gray-300;
                            &.active {
                                background-color: transparent;
                                border-top-color: $brand-primary;
                            }
                            .nav-link {
                                position: relative;
                                text-transform: inherit;
                            }
                        }
                    }
                    .tab-content {
                        padding: 0;
                        &__divider {
                            margin-left: $space-md;
                            padding-right: $space-md;
                            display: inline-block;
                            border-left: 1px solid $gray-500;
                            height: 20px;
                            vertical-align: middle;
                        }
                    }
                    &__header {
                        position: relative;
                        display: inline-block;
                        .tag {
                            position: absolute;
                            right: (-#{$grid-gutter-width-base});
                            top: 50%;
                            border-radius: 50%;
                            padding: 0;
                            width: 1.5rem;
                            height: 1.5rem;
                            line-height: 1.5rem;
                            font-size: .8rem;
                            @include transform(translateY(-50%));
                        }
                    }
                }
            }
        }
        &__search {
            position: relative;
            padding-left: 16px;
            &.sprite {
                &:before {
                    content: '';
                    background-position: -32px -207px;
                    background-repeat: no-repeat;
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: inline-block;
                    vertical-align: middle;
                    width: 2.667rem;
                    height: 2.667rem;
                    z-index: 1;
                }
            }
            &:after {
                content: '';
                background-color: $border-color;
                position: absolute;
                left: 4rem;
                width: 1px;
                height: 1.333rem;
                z-index: 1;
                top: 50%;
                @include transform(translateY(-50%));
            }
            &__input {
                height: $grid-gutter-width-base;
                width: 100%;
                border: 1px solid $border-color;
                text-indent: 4rem;
                &:focus {
                    outline: 0;
                    outline-offset: none;
                    box-shadow: none;
                    border-color: $brand-primary;
                }
            }
            &__button {
                margin-top: $space-sm;
            }
            @include media-breakpoint-down(lg) {
                margin-bottom: $space-md;
            }
            .dropdown-menu {
                width: calc(100% - 1.333rem);
                margin-top: 0;
                border-top: 0;
                &::after,
                &::before {
                    border: 0;
                }
            }
            .dropdown-item {
                padding-top: .8333rem;
                padding-bottom: .8333rem;
            }

            &--material{
                .dropdown-menu {
                    width: 100%;
                    margin-top: 16px;
                    border-top: 0;
                    &::after{
                        border: 0;
                    }
                    &::before {
                        left: 25px;
                        top: -15px;
                        border-right: 12px solid transparent;
                        border-left: 12px solid transparent;
                        border-bottom: 15px solid #ffffff;
                    }
                }
                .dropdown-item {
                    padding-top: .8333rem;
                    padding-bottom: .8333rem;
                    border-left: 0px;
                }
            }
        }
        label {
            margin-bottom: 0;
        }
    }

    &--me{
        background: #FFF;
        box-shadow: rgba(32, 42, 77, 0.08) 0px 40px 60px, rgba(32, 42, 77, 0.08) 0px 10px 10px;
        border-radius: 24px;
        font-weight: 400;
        color: $darkBlue;
        padding: 80px;
        font-size: 28px;
        line-height: 40px;

        &.bg{
          &__secondary{
            background: #fffef3 !important;
          }
        }
    }
}

.card-hexa {
    padding: 0 $space-sm;
    margin: $space-sm/2 $space-sm;
    color: $text-color;
    cursor: pointer;
    height: $space-lg;
    text-align: center;
    position: relative;
    width: calc(100% - #{$space-md});
    border: solid $divider;
    border-width: 2px 0;
    background-color: #ffffff;
    @include flexbox();
    @include align(center);
    &:hover {
        background-color: $gray-300;
        .left-angle {
            &:before, &:after {
                border-right-color: $gray-300;
            }
        }
        .right-angle {
            &:before, &:after {
                border-left-color: $gray-300;
            }
        }
    }
    &.selected:not(.symptom-indicator) {
        border-color: $brand-secondary;
        .text {
            color: $brand-secondary;
        }
        .left-angle {
            &:before {
                border-right-color: $brand-secondary;
            }
        }
        .right-angle {
            &:before {
                border-left-color: $brand-secondary;
            }
        }
    }
    .left-angle {
        &::before {
            content: '';
            position: absolute;
            top: -2px;
            left: -16px;
            border-top: 25px solid transparent;
            border-bottom: 24px solid transparent;
            border-right: 16px solid darken($divider, 7%);
        }
        &::after {
            content: '';
            position: absolute;
            top: 1px;
            left: -14px;
            border-top: 22px solid transparent;
            border-bottom: 22px solid transparent;
            border-right: 14px solid #fff;
        }
    }
    .right-angle {
        &::before {
            content: '';
            position: absolute;
            top: -2px;
            right: -16px;
            border-top: 25px solid transparent;
            border-bottom: 24px solid transparent;
            border-left: 16px solid darken($divider, 7%);
        }
        &::after {
            content: '';
            position: absolute;
            top: 1px;
            right: -14px;
            border-top: 22px solid transparent;
            border-bottom: 22px solid transparent;
            border-left: 14px solid #fff;
        }
    }
}

.card--new {
    &--box {
        background-color: transparent;
        @extend .user-select-none;
        margin: 0;
        &__header {
            @include flexbox();
            @include justify-content(space-between);
            @include align(center);
            background-color: #FFF;
            color: #ffffff;
            padding: 0 $space-md;
            overflow: hidden;
            font-size: 1.667rem;
            padding-top: $space-md;
            @include media-breakpoint-down(lg) {
                min-width: 100%;
            }
            &--info {
                display: inline-block;
                position: relative;
                padding-top: 1rem;
                &:before{
                    content: '';
                    width: 140px;
                    height: 6px;
                    background: $brand-secondary;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
            &__title {
                font-size: 2.833rem;
                font-family: "Begum Bold";
                margin: 0;
                display: inline-block;
                vertical-align: middle;
                color: $blue-500;
                h2,
                h6 {
                    margin-bottom: 0;
                }
                h2 {
                    font-weight: $font-medium;
                }
                span {
                    font-size: 1.2rem;
                    font-weight: $font-medium;
                }
            }
        }
    }
}

.card--b2c {
    &--box {
        background-color: transparent;
        @extend .user-select-none;
        margin: 0;
        &__header {
            @include flexbox();
            @include justify-content(space-between);
            @include align(center);
            background-color: transparent;
            color: #000;
            padding: 0 $space-md;
            overflow: hidden;
            font-size: 1.667rem;
            padding-top: $space-xs;
            @include media-breakpoint-down(lg) {
                min-width: 100%;
            }
            &--info {
                display: inline-block;
                position: relative;
                padding-top: 1rem;

                &.search-txt{
                    min-width: 32rem;
                    > input{
                        border-radius: 2rem;
                        -moz-border-radius: 2rem;
                        -webkit-border-radius: 2rem;
                    }
                    > i {
                        position: absolute;
                        right: 3%;
                        top: 42%;
                        cursor: pointer;
                    }
                }
            }
            &__title {
                font-size: 2rem;
                font-family: "Begum Bold";
                margin: 0;
                display: inline-block;
                vertical-align: middle;
                color: #000;
                h2,
                h6 {
                    margin-bottom: 0;
                }
                h2 {
                    font-weight: $font-medium;
                }
                span {
                    font-size: 1.2rem;
                    font-weight: $font-medium;
                }
            }
            &__action {
                color: $darkBlue;
                > button {
                    font-size: 1.333rem;
                    font-weight: 700;
                    background-color: transparent;
                    &:hover{
                        background-color: transparent;
                        color: $darkBlue;
                    }
                }
            }
        }
    }
}
