@import '_variables.scss'; // Font
/**
 *  If you want to override some bootstrap variables, you have to change values here.
 *  The list of variables are listed here node_modules/bootstrap/scss/_variables.scss
 */
// Core variables and mixins
// @import "bootstrap/_mixins.scss";
// Reset and dependencies
@import "bootstrap/_custom.scss";
@import "bootstrap/_normalize.scss";
@import "bootstrap/_print.scss";
@import "bootstrap/_reboot.scss";
@import "bootstrap/_animation.scss";
@import "bootstrap/_jumbotron.scss"; // Core CSS
// @import "bootstrap-customized/_type.scss";
// @import "bootstrap-customized/_code.scss";
@import "bootstrap-customized/bootstrap.scss";
@import "bootstrap-customized/_grid.scss";
@import "bootstrap-customized/_forms.scss";
@import "bootstrap-customized/_buttons.scss"; // Components
@import "bootstrap-customized/_component-animations.scss";
@import "bootstrap-customized/_dropdowns.scss";
@import "bootstrap-customized/_button-groups.scss";
@import "bootstrap-customized/_input-groups.scss";
@import "bootstrap-customized/_custom-forms.scss";
@import "bootstrap-customized/_navs.scss";
@import "bootstrap-customized/_navbar.scss"; // @import "bootstrap-customized/_breadcrumbs.scss";
@import "bootstrap-customized/_pagination.scss"; // @import "bootstrap-customized/_jumbotron.scss";
@import "bootstrap-customized/_alerts.scss";
@import "bootstrap-customized/_popover.scss";
@import "bootstrap-customized/_progress-bars.scss";
@import "bootstrap-customized/_media.scss";
@import "bootstrap-customized/_list-group.scss"; // @import "bootstrap-customized/_responsive-embed.scss";
@import "bootstrap-customized/_close.scss";
@import "bootstrap-customized/_images.scss";
@import "bootstrap-customized/_tags.scss";
@import "bootstrap-customized/_card.scss";
@import "bootstrap-customized/_type.scss";
@import "bootstrap-customized/_tables.scss"; // Components w/ JavaScript
@import "bootstrap-customized/_modals.scss";
@import "bootstrap-customized/_tooltip.scss"; // @import "bootstrap-customized/_popovers.scss";
@import "bootstrap-customized/_carousel.scss";
// Utility classes
@import "bootstrap-customized/_utilities.scss";
