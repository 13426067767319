// custom style here
.image-upload-extension {
    border: none;
    .img-ul-file-upload {
        padding: 0 !important;
        .img-ul-button {
            background: #FFF;
            box-shadow: none;
            padding: 0.5rem 1.5rem 0.5rem 1.5rem;
            font-size: 1rem;
            line-height: 1.5;
            color: #ffffff;
            background-color: $brand-secondary;
            background-image: none;
            background-clip: padding-box;
            border-color: $brand-secondary;
            text-transform: none;
            position: relative;
            margin-top: 0.8rem;
            margin-bottom: 0;
            &:hover {
                color: #ffffff;
                background-color: $brand-secondary-dark;
                border-color: $brand-secondary-dark;
            }
        }
    }
    .img-ul-clear {
        visibility: hidden;
    }
    .img-ul-file-too-large {
        padding-left: 0 !important;
    }
}