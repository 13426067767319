// Core bootstrap
@import "../bootstrap/_type.scss";

/**
 *  If you want to override some bootstrap styles, you have to code below.
 */

.list-inline-item {
    &:not(:last-child) {
        margin-right: $space-sm;
    }
    &--sm {
        &:not(:last-child) {
            margin-right: 5px;
        }
    }
    i {
        font-size: 1.25rem;
    }
    &--divider {
        display: inline-block;
        border-left: 1px solid $gray-500;
        height: 20px;
        vertical-align: middle;
    }
}