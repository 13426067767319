

/*
 * Fontawesome 5
 */

 @import 'fontawesome/fontawesome.scss';
 @import 'fontawesome/brands.scss';
 @import 'fontawesome/regular.scss';
 @import 'fontawesome/solid.scss';

/*
 * Angular Material
 */

@import '~@angular/material/theming';
@include mat-core();

$mat-blue: (
    50: #e3f2fd,
    100: #bbdefb,
    200: #90caf9,
    300: #64b5f6,
    400: #5fbfff,// For Klenico
    500: #008fd5,// For Klenico
    600: #1e88e5,
    700: #1976d2,
    800: #1565c0,
    900: #003252,// For Klenico
    A100: #82b1ff,
    A200: #448aff,
    A400: #2979ff,
    A700: #2962ff,
    contrast: (
      50: $dark-primary-text,
      100: $dark-primary-text,
      200: $dark-primary-text,
      300: $dark-primary-text,
      400: $dark-primary-text,
      500: $light-primary-text,
      600: $light-primary-text,
      700: $light-primary-text,
      800: $light-primary-text,
      900: $light-primary-text,
      A100: $dark-primary-text,
      A200: $light-primary-text,
      A400: $light-primary-text,
      A700: $light-primary-text,
    )
  );

$primary: mat-palette($mat-blue, 500);
$accent: mat-palette($mat-light-blue, 500, 400, 900);
$warn: mat-palette($mat-red, 500);
$theme: mat-light-theme($primary, $accent, $warn);
@include angular-material-theme($theme);

/*
    ng2-dragula
*/
@import '../../../node_modules/dragula/dist/dragula.css';

/*
 * Angular Bootstrap
 */

@import '_variables.scss';
@import '_bootstrap.customized.scss';
@import 'components/components';
@import 'select2.scss';
@import '_fonts.scss';
@import '_responsive.scss';
