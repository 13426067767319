// Core bootstrap
@import "../bootstrap/_navbar.scss";

/**
 *  If you want to override some bootstrap styles, you have to code below.
 */

.nav-tabs {
    background-color: $gray-300;
    &.nav-justified {
        width: 100%;
        >li {
            float: none;
            >a {
                text-align: center;
                margin-bottom: 5px;
            }
        }
        >.dropdown .dropdown-menu {
            top: auto;
            left: auto;
        }
        @include media-breakpoint-up(sm) {
            >li {
                display: table-cell;
                width: 1%;
                >a {
                    margin-bottom: 0;
                }
            }
        }
    }
}

#proposal-diagnosis {
    .nav-tabs { 
        border-radius: 2.2rem;
        border: 3px solid $gray-300;
        margin-bottom: 2rem;
        clear: both;
    }

    .nav-link {
        border: none;
        line-height: 32px;
        border-radius: 2.2rem;
        &.active {
            background-color: $brand-secondary;
            color : #fff;
        }
    }
}