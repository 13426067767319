/* CUSTOM CSS CHECKBOXES */
/* Remove default checkbox */
[type="checkbox"]:checked,
[type="checkbox"]:not(:checked) {
    position: absolute;
    left    : -9999px;
    opacity : 0;
}
// Checkbox Styles

[type="checkbox"] {
    // Text Label Style
    + label {
        position           : relative;
        padding-left       : 25px;
        cursor             : pointer;
        display            : inline-block;
        height             : 25px;
        line-height        : 25px;
        font-size          : $font-size-base;
        -webkit-user-select: none;
        /* webkit (safari, chrome) browsers */
        -moz-user-select   : none;
        /* mozilla browsers */
        -khtml-user-select : none;
        /* webkit (konqueror) browsers */
        -ms-user-select    : none;
        /* IE10+ */
    }

    &:not(:checked):disabled + label:before {
        border          : none;
        background-color: $border-color;
    }
    // Focused styles
    &.tabbed:focus + label:after {
        transform       : scale(1);
        border          : 0;
        border-radius   : 50%;
        box-shadow      : 0 0 0 10px rgba(0,0,0,.1);
        background-color: rgba(0,0,0,.1);
    }
}

[type="checkbox"]:checked {
    + label:before {
        top                : -4px;
        left               : -5px;
        width              : 12px;
        height             : 22px;
        border-top         : 2px solid transparent;
        border-left        : 2px solid transparent;
        border-right       : $border-color;
        border-bottom      : $border-color;
        transform          : rotate(40deg);
        backface-visibility: hidden;
        transform-origin   : 100% 100%;
    }

    &:disabled + label:before {
        border-right : 2px solid $border-color;
        border-bottom: 2px solid $border-color;
    }
}
// Filled in Style

[type="checkbox"] {
    // General
    + label:after {
        border-radius: 2px;
    }

    + label:after,
    + label:before {
        content   : '';
        left      : 0;
        position  : absolute;
        /* .1s delay is for check animation */
        transition: border .25s, background-color .25s, width .20s .1s, height .20s .1s, top .20s .1s, left .20s .1s;
        z-index   : 1;
    }
    // Unchecked style
    &:not(:checked) + label:before {
        width                   : 0;
        height                  : 0;
        border                  : 3px solid transparent;
        left                    : 6px;
        top                     : 10px;
        -webkit-transform       : rotateZ(37deg);
        transform               : rotateZ(37deg);
        -webkit-transform-origin: 20% 40%;
        transform-origin        : 100% 100%;
    }

    &:not(:checked) + label:after {
        height          : 20px;
        width           : 20px;
        background-color: #ffffff;
        border          : 2px solid $border-color;
        top             : 0;
        z-index         : 0;
    }
    // Checked style
    &:checked {
        + label:before {
            top                     : 2px;
            left                    : 1px;
            width                   : 8px;
            height                  : 13px;
            border-top              : 2px solid transparent;
            border-left             : 2px solid transparent;
            border-right            : 2px solid #ffffff;
            border-bottom           : 2px solid #ffffff;
            -webkit-transform       : rotateZ(37deg);
            transform               : rotateZ(37deg);
            -webkit-transform-origin: 100% 100%;
            transform-origin        : 100% 100%;
        }

        + label:after {
            top             : 0;
            width           : 20px;
            height          : 20px;
            border          : 2px solid $brand-secondary;
            background-color: $brand-secondary;
            z-index         : 0;
        }
    }
    // Focused styles
    &.tabbed:focus + label:after {
        border-radius   : 2px;
        border-color    : $border-color;
        background-color: rgba(0,0,0,.1);
    }

    &.tabbed:checked:focus + label:after {
        border-radius   : 2px;
        background-color: #ffffff;
        border-color    : #ffffff;
    }
    // Disabled style
    &:disabled:not(:checked) + label:before {
        background-color: #ffffff;
        border          : 2px solid transparent;
    }

    &:disabled:not(:checked) + label:after {
        border-color    : #ffffff;
        background-color: $border-color;
    }

    &:disabled:checked + label:before {
        background-color: #ffffff;
    }

    &:disabled:checked + label:after {
        background-color: $border-color;
        border-color    : $border-color;
    }
}
