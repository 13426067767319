/***************
Radio Buttons
***************/


/* Remove default Radio Buttons */

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
    visibility: hidden;
}

[type="radio"]+label {
    position: relative;
    padding-left: $space-md;
    cursor: pointer;
    display: inline-block;
    height: 30px;
    line-height: 1.5;
    font-size: $font-size-base;
    -khtml-user-select: none;
    /* webkit (konqueror) browsers */
    user-select: none;
    @include transition(.28s ease);
    &::before,
    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        margin: 0;
        width: 22px;
        height: 22px;
        z-index: 0;
        @include transition(.28s ease);
    }
    &::before {
        border-radius: 50%;
        border: 1px solid $darkBlue;
        opacity: 30%;
    }
}


/* Unchecked styles */

[type="radio"]:not(:checked)+label {
    &:hover::after {
        background-color: $gray-600;
        @include transform(scale(0.3));
        z-index: 1;
    }
    &::after {
        border-radius: 50%;
        z-index: -1;
        @include transform(scale(0));
    }
}


/* Checked styles */

[type="radio"]:checked+label {
    &::before {
        border-color: $darkBlue;
        opacity: 0.3;
    }
    &::after {
        border-radius: 50%;
        background-color: $darkBlue;
        z-index: 0;
        @include transform(scale(0.65));
    }
}


/* Disabled style */

[type="radio"]:disabled {
    &:checked+label:before,
    &:not(:checked)+label:before {
        background-color: #ffffff;
    }
    &:not(:checked)+label:hover::after {
        @include transform(scale(0));
    }
}

[type="radio"]:disabled+label {
    color: $border-color;
}

[type="radio"]:disabled:not(:checked)+label:before {
    border-color: $border-color;
}

[type="radio"]:disabled:checked+label:after {
    background-color: $border-color;
    border-color: $border-color;
}