body {
    font-family: $font-family-base;
    font-size: $font-size-base;
    min-width: $body-min-width;
    margin: 0;
    vertical-align: middle;
    justify-content: center;
    &.self-evaluation{
        min-width: 100vw;
    }
    &.disable-scroll{
        overflow: hidden;
    }
    &.support-page{
        background: #FFF;
        .header > .container{
            width: 1110px;
        }
    }
}

a {
    color: $text-color;
    &:hover,
    &:focus {
        color: $brand-secondary;
        text-decoration: none;
    }
}

b {
    font-weight: 700;
}

hr {
    margin-top: $space-sm;
    margin-bottom: $space-md;
    border-top-color: $border-color;
}

h2 {
    font-size: 2.8rem;
    font-weight: $font-regular;
    font-family: "Begum Bold";
}

:invalid {
    box-shadow: none;
}

:-moz-submit-invalid {
    box-shadow: none;
}

:-moz-ui-invalid {
    box-shadow: none;
}

app.app {
    vertical-align: middle;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    display: flex;
    min-height: 100vh;
    height: 100vh;
    flex-direction: column;
}

.app-wrapper {
    align-self: flex-start;
    flex: 1 0 auto;
    margin: auto;
    width: 100%;
    overflow-x: hidden;

    &.se-page{
        max-width: 100vw;
        font-family: "Cera Pro";
        overflow-x: hidden;

        button.btn{
            &.btn-primary{
                min-width: 140px;
                min-height: 56px;
                font-size: 0.96rem;
                font-weight: 700;
                border: 2px solid;
                border-radius: 32px;
                padding: 8px 0px;
                line-height: 100%;
                text-align: center;
                cursor: pointer;
                color: #FFF;
                border-color: $darkBlue;
                background: $darkBlue;
                &:hover {
                    color: $darkBlue;
                    background: #FFF;
                    border-color: $darkBlue;
                }
                &:focus{
                    outline: 2px solid $brand-secondary;
                    outline-offset: 2px;
                }
            }
            &.btn-secondary{
                min-width: 140px;
                min-height: 56px;
                font-size: 0.96rem;
                font-weight: 700;
                border: 2px solid;
                border-radius: 32px;
                padding: 8px 0px;
                line-height: 100%;
                text-align: center;
                cursor: pointer;
                color: $darkBlue;
                background: #FFF;
                border-color: $darkBlue;
                &:hover {
                    background: $darkBlue;
                    border-color: $darkBlue;
                    color: #FFF;
                }
                &:focus{
                    outline: 2px solid $brand-secondary;
                    outline-offset: 2px;
                }
            }
            &.btn--continue,
            &.btn--tertiary {
                font-size: 16px;
                font-weight: 400 !important;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
                color: $darkBlue;
                border-radius: 2px;
                > i {
                    font-size: 24px;
                }
                &:focus{
                    outline: 2px solid $brand-secondary;
                }
            }
            &.disabled {
                opacity: 0.4;
                cursor: default;
                pointer-events: none;
            }
        }

        &.touch-device{
            background: #FFF;
        }
        .main > .container{
            padding-top: 0;
            position: relative;
            padding: 2rem 0px;
        }
        &.overlay{
            background-color: rgba(0, 0, 0, 0.6);
            transition-duration: .4s;
            transition-timing-function: cubic-bezier(.25,.8,.25,1);
            transition-property: transform,margin-left,margin-right;
        }

        .header--self-evaluation{
            &__language{
                &__select{
                    color: $darkBlue;

                    .mat-select-value-text{
                        opacity: 0 !important;
                    }
                }
            }

            &__nav{
                font-size: 24px;
                width: 32px;
                height: 32px;
                cursor: pointer;
                float: right;
                margin-right: 20px;
            }
        }
    }

}

//   IE10+
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
//    IE 6-7-8-9
and (min-width: 640px), screen\9 {
    .selfevaluation__nav{
        .close-btn {
            position: absolute !important;
            top: 0 !important;
            right: $space-sm !important;
        }
    }
}

.self-evaluation-modal {
    .close-btn {
        background: $gray-100;
    }
}

app-footer {
    width: 100%;
}

.main {
    width: 100%;
    >.container {
        margin: 0 auto; // padding-bottom: $space-lg;
        padding-top: $space-md;

        &.live-questionnaire {
            width: 2160px;
        }
    }

    .header {
        .right-section {
            z-index: 15;
        }
    }
}

.page-title {
    margin-top: 0;
    margin-bottom: 1.14rem;
    text-transform: capitalize;
}

.block {
    display: block;
    width: 100%;
}

.hidden {
    display: none !important;
}

.sprite {
    &:before {
        background-image: url("#{$image-path}pages/sprite.svg");
        background-repeat: no-repeat;
        content: '';
        width: 100%;
        height: 100%;
        float: left;
        background-size: $sprite-width $sprite-height;
    }
}

.sprite-background {
    content: '';
    background-image: url("#{$image-path}pages/sprite.svg") !important;
    background-repeat: no-repeat;
    background-size: $sprite-width $sprite-height;
}

.padding {
    &-left {
        &--none {
            padding-left: 0;
        }
    }
    &-right {
        &--none {
            padding-right: 0;
        }
    }
}

.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.valign-wrapper {
    @include flexbox();
    @include align(center);
}

.loading-overlay {
    background-color: #000000;
    opacity: .7;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    .icons--profile {
        opacity: 0.2;
    }
    .spinning-circle {
        height: 30px;
        width: 30px;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border-radius: 50%;
        border: 3px solid rgba(255, 255, 255, 0);
        border-top: 3px solid white;
        border-right: 3px solid white;
        -webkit-animation: spinner 2s infinite cubic-bezier(0.085, 0.625, 0.855, 0.360);
        animation: spinner 2s infinite cubic-bezier(0.085, 0.625, 0.855, 0.360);
    }
    @-webkit-keyframes spinner {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes spinner {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
}

.ui-select-toggle {
    background: none !important;
}

.no-br {
    border: none !important;
}

.input-group.br {
    border: 1px solid $border-color; //border-radius: 0.5rem;
    padding-left: 10px;
    .input-group-addon {
        border: none;
        background: none;
    }
}

.dropdown-box {
    position: relative;
    clear: both;
    margin-top: 10px;
    .dropdown-content {
        position: absolute;
        top: 98%;
        width: 100%;
        max-height: 500px;
        background: white;
        overflow: auto;
        border: 1px solid $border-color;
        padding-bottom: 10px;
        z-index: 99;
    }
}

.panel-open>div>div>.card-title {
    .fa-plus-square-o:before {
        content: "\f147";
    }
    .fa-caret-right:before {
        content: "\f0d7";
        font-weight: 700;
    }
}

.sprite.blank {
    position: relative;
    width: 2.667rem;
    height: 2.667rem;
    z-index: 23;
    color: #ffffff;
    &:before {
        background-position: 0 -239px;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
    }
}
ul.languages {
    text-align: right;
    li {
        img {
            opacity: 0.4;
            cursor: pointer;
        }
        &.active {
            img {
                cursor: default;
                opacity: 1;
            }
        }
    }
}
#manual-diagnoses {
    width: 474px;
    margin-left: -24px;
    padding: 0px 24px;

    .card {
        background-color: #fff;
        margin-bottom: 0;
    }

    .card-block {
        padding: 0;
    }

    .diagnosis {
        &--dropdown {
            float: left;
            width: 2%;
        }

        &--text {
            float: left;
            width: 80%;
            padding-left: 16px;
        }

        &--btn {
            float: right;
            color: $brand-secondary;
            text-transform: uppercase;
        }
    }

    .lv1-accordion {
        .card {
            &-header {
                padding: 24px 0px;
                background-color: #fff;
                border-bottom: 1px solid #F2F2F2;
            }
        }

        .lv1-selected {
            color: $text-color-inactive;
            .card-header{
                background: #F4F4F4;
                width: calc(100% + 48px);
                margin-left: -24px;
                padding: 24px;
            }
        }
    }

    .lv2-accordion {
        .card {
            margin: 0;
            &-header {
                padding: 16px 0px 16px 16px;
            }
        }

        .lv2-selected {
            color: $text-color-inactive;
            .card-header{
                background: #F4F4F4;
                width: calc(100% + 48px);
                margin-left: -24px;
                padding: 16px 24px 16px 40px;
            }
        }

        .lv2-heading {
            padding: 16px 0px 16px 32px;
            background-color: #fff;
            border-bottom: 1px solid #F2F2F2;
            &:after {
                content: "";
                display: table;
                clear: both;
            }
            &.lv3-selected  {
                color: $text-color-inactive;
                background: #F4F4F4;
                width: calc(100% + 48px);
                margin-left: -24px;
                padding: 16px 24px 16px 56px;
            }
        }
    }
}

// Remove in future
#diagnoses {
    line-height: 22px;
    .btn-link {
        background-color: transparent; // margin-right: 5px;
        font-size: 1.2rem;
        &.close {
            font-size: 2.5rem;
        }
        &:focus i,
        &:hover:not(.collapse-btn) i {
            color: $gray-300;
        }
        &:hover .fa-check:before {
            color: $brand-primary;
            cursor: pointer;
        }
    }
    a:focus,
    a:hover {
        color: $gray-300;
        text-decoration: none;
    }
    .dropdown {
        width: 100%;
    }
    .lv1-btn {
        width: 10px;
    }
    .lv1-accordion {
        padding: 7px $space-sm;
        height: 636px;
        width: $diagnoses-width;
        @include media-breakpoint-up(xl) {
            width: $diagnoses-width-xl;
        }
        .card {
            &-header {
                background-color: $gray-200;
                height: 50px;
                &:hover .collapse-btn i {
                    color: $brand-secondary;
                }
            }
        }
    }
    .lv2-accordion {
        .btn-link {
            background-color: transparent;
            margin-right: $space-sm;
            &:hover i,
            &--hover i {
                color: $brand-secondary;
            }
            .icon-placeholder {
                width: 15px;
                margin-left: 0;
            }
        }
        .card {
            &-header {
                background-color: #fff;
                height: auto;
            }
        }
    }
    .card {
        margin-bottom: 0;
        .card-block {
            padding: 0;
        }
        .card-title {
            margin-bottom: 0;
        }
        .card-header {
            padding: $space-sm $space-md;
            &:first-child {
                border-radius: 0;
            }
            &:not(:last-child) {
                margin-bottom: 2px;
            }
            &:hover {
                cursor: pointer;
            }
            &.lv2-heading {
                padding-left: 96px;
                .diagnosis-heading-text {
                    font-size: 1.16rem;
                    color: $text-color;
                }
            }
            input[type="radio"]:disabled+label {
                cursor: auto;
                span {
                    color: $text-color;
                }
            }
        }
    }
    .diagnosis-heading-text {
        float: left;
        width: 92%;
        color: $brand-primary-dark;
        font-size: 1.32rem;
        input[type="radio"]+label {
            font-size: 1.33rem;
            padding-left: 31px;
            +span {
                margin-left: -3px; // align the text with the text inside input[type="radio"]+label
            }
        }
        &.selected {
            input[type="radio"] {
                &:not(:checked) {
                    &+label {
                        &::before {
                            border-color: $brand-primary;
                        }
                        &::after {
                            background-color: $brand-primary;
                            @include transform(scale(0.45));
                            z-index: 1;
                        }
                    }
                }
            }
        }
        .duplication-text {
            color: $text-color;
        }
        &__none-select {
            padding:16px 32px;
            width:100%;
        }
        &__none-select-disable{
            pointer-events: none;
        }
    }
    .diagnosis-heading>.fas,
    .diagnosis-heading>.far {
        font-size: 1.2rem;
        margin-right: 0.7rem;
    }
    .diagnosis-selected {
        .panel-collapse .far:before {
            content: "\f071";
        }
    }
    .current-selected-diagnosis {
        padding: 5px 0;
    }
}

.overflow-visible {
    overflow: visible !important;
}

// Style for Header Dashboard
.card{
    &--box {
        &--dashboard {
            &__block {
                background: $brand-primary;
                padding-top: 0;
                min-width: 360px;
                &__header {
                    padding-top: 30px;
                    h2 {
                        color: #FFF;
                    }
                }
                .quickstart {
                    margin-top: 4rem;
                    &__item {
                        margin-right: 2%;
                        &__btn {
                            padding: 0;
                            > i {
                                float: left;
                                width: 100px;
                                height: 100px;
                                border-radius: 50%;
                                -moz-border-radius: 50%;
                                -webkit-border-radius: 50%;
                                background: $brand-primary-light-2;
                                color: #FFFFFF;
                                clear: both;
                                font-size: 4rem;
                                text-align: center;
                                vertical-align: middle;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                &:before {
                                }
                                &:hover {
                                    background: $brand-secondary;
                                }
                            }
                        }
                        &__name {
                            float: left;
                            text-align: center;
                            color: #FFF;
                            font-size: 1.2rem;
                            width: 100px;
                            margin-top: 1rem;
                            white-space: normal;
                        }
                    }
                }
            }
        }
        &__search {
            &--material {
                padding-left: 0px;
                @include media-breakpoint-down(lg) {
                    margin-bottom: 0;
                }
                input {
                    height: 40px;
                    border-radius: 2px;
                    border: none;
                    font-size: 1.33rem;
                    font-weight: 400;
                    text-indent: 1rem;
                }
                &:after {
                    content: '';
                    display: none;
                }
            }
        }
    }
}

// Style for Progress Bar of Self Evaluation
.wrapper {
    &__progress {
        margin-top: 1rem;
        padding: $space-md;
        padding-bottom: 7rem;
        &__bar {
            font-family: "Cera Pro Bold";
            height: 2rem;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            width: 320px;
            float: right;
            position: relative;

            &__step {
                color: $text-color;
                position: relative;
                float: left;
                border-radius: 50%;
                background: $gray-300;
                width: 2rem;
                height: 2rem;
                text-align: center;
                display: flex;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                align-items: center;
                -webkit-align-items: center;
                justify-content: center;
                -webkit-justify-content: center;
                margin-right: 4px;

                &>span {
                    position: absolute;
                    white-space: nowrap;
                    transform: translateX(-50%);
                    left: 50%;
                    display: table;
                    top: 5px;
                    text-align: center;
                    min-height: 30px;
                    line-height: 2.25rem;
                    &.first {
                        width: 120px;
                    }
                    &:before {
                        color: $gray-600;
                        content: '\f0d7';
                        position: absolute;
                        font-weight: 700;
                        left: 50%;
                        bottom: -12px;
                    }
                }
                & > p {
                    padding-left: 2px;
                }
                &--progress {
                    top: 0;
                    position: relative;
                    float: left;
                    width: 100%;
                    max-width: calc(100% - 48px);
                    height: 8px;
                    margin: 0px 4px;
                    background: $grayLanding;
                    border-radius: 4px;

                    & > p {
                        position: absolute;
                        top: 0px;
                        color: $brand-secondary;
                        background: $darkBlue;
                        height: 8px;
                        border-radius: 4px;
                    }
                }
                &.active {
                    color : #fff;
                    background: $brand-secondary;
                    margin-right: 0;
                }
                &.complete {
                    color : #fff;
                    background: $brand-primary;
                    &:before {
                        content: '\F00C';
                        position: absolute;
                        font-size: 1rem;
                        width: 1rem;
                        height: 1rem;
                        margin: auto;
                        top: 0px;
                        bottom: 0px;
                        left: 0px;
                        right: 0px;
                    }
                    >b {
                        display: none;
                    }
                }
                &.second,
                &.fourth {
                    &>span {
                        top: 100px;
                    }
                    span:before {
                        content: '\f0d8';
                        top: -15px;
                    }
                }
                &.last {
                    &:before {
                        display: none;
                    }
                    &:after {
                        right: 0px;
                    }
                }
                &.current {
                    cursor: pointer;
                    border-top &:before {
                        border-top: 2px dotted $brand-primary;
                    }
                    &>span {
                        color: $brand-primary;
                    }
                }
                &.deactive {
                    &:before {
                        border-top: 2px dotted $gray-600;
                    }
                    &>span {
                        color: $gray-600;
                        &:before {
                            color: $gray-600;
                        }
                    }
                    &:after {
                        content: '\f111';
                        color: #CCCCCC;
                        font-weight: 700;
                    }
                }
            }
            &.deactive {
                &:before {
                    border-top: 2px dotted $gray-600;
                }
                &>span {
                    color: $gray-600;
                    &:before {
                        color: $gray-600;
                    }
                }
                &:after {
                    content: '\f111';
                    color: $gray-600;
                }
            }
            &--label{
                position: absolute;
                color: $darkBlue;
                top: -8px;
                right: 0px;
                font-size: 1.33rem;
            }
        }
    }
}

.devider {
    clear: both;
    width: 100%;
    content: ' ';
    border-top: 1px solid $gray-600;
    margin-top: 30px;
    margin-bottom: 20px;
}

.btn-gray {
    padding-left: 2.3rem;
    color: $gray-500;
    background: none;
}
.btn-radius{
    padding: 1.367rem 2.667rem;
    border-radius: 2.2rem;
    min-width: 200px;
}
.pull-right {
    float: right
}

.pull-left {
    float: left;
    &--important{
        float: left !important;
    }
}

.flex-center {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    @include flexbox();
    @include justify-content(center);
    @include align(center);
}

.right-column {
    .ps-content {
        column-count: 2;
        -moz-column-count: 2;
        >div {
            break-inside: avoid-column;
            padding: 1px $space-sm;
            page-break-inside: avoid;
            float: left;
            width: 100%;
        }
    }
    .left-column .ps-content>div {
        padding: 1px $space-sm;
    }
    .info-bold {
        font-weight: $font-medium;
        color: $brand-primary;
    }
    .user-select-none {
        -webkit-user-select: none;
        /* Chrome all / Safari all */
        -moz-user-select: none;
        /* Firefox all */
        -ms-user-select: none;
        /* IE 10+ */
        user-select: none;
        /* Likely future */
    }
    button:focus {
        outline: none;
    }
    .visible-tablet {
        display: none;
    }
    .ps-content {
        width: 100%;
    }
    .se-page,
    .de-page {
        border-top .main {
            @include transition(all 0.5s linear);
        }
    }
    .auth-page {
        display: flex;
        >.container {
            height: 100%;
            width: 100%;
            min-height: 520px;
            padding-bottom: 0;
            margin-bottom: $space-md;
            display: flex;
            display: -webkit-box;
            display: -ms-flexbox;
            vertical-align: middle;
            align-items: center;
            justify-content: center;
            align-content: center;
        }
    }
    app-footer {
        margin: 0;
    }
}

.left-column .ps-content>div {
    padding: 1px $space-sm;
}

.info-bold {
    font-weight: $font-medium;
    color: $brand-primary;
}

.user-select-none {
    -webkit-user-select: none;
    /* Chrome all / Safari all */
    -moz-user-select: none;
    /* Firefox all */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
    /* Likely future */
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
    box-shadow: 0 0 0px 1000px #ffffff inset;
}

button:focus {
    outline: none;
}

.visible-tablet {
    display: none;
}

.ps-content {
    width: 100%;
}

.se-page,
.de-page {
    .main {
        @include transition(all 0.5s linear);
    }
}

.auth-page {
    display: flex;
    flex-direction: column;
    .main {
        // height: 100vh;
        height: 100%;
        flex-grow: 1;
        width: 100%;
        position: relative;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        >.container {
            height: 100%;
            width: 100%;
            min-height: 520px;
            padding: 0;
            margin: 0;
            display: flex;
            display: -webkit-box;
            display: -ms-flexbox;
            justify-content: flex-start;

            > app-login,
            > app-change-password,
            > app-password,
            > app-request-action{
                display: flex;
                background: #FFF;
                color: $darkBlue;

                .auth{
                    &__header{
                        display: flex;
                        width: 100%;
                        align-items: center;
                        padding: 16px;

                        &__link{
                            color: $brand-secondary;
                            font-size: 14px;
                            font-weight: bold;
                        }
                    }
                    &__wrapper{
                        margin: auto;
                        order: 11;

                        .page-title{
                            font-family: "Cera Pro Bold", sans-serif;
                            text-align: left;
                            color: $darkBlue;
                            font-weight: $font-medium;
                            font-size: $font-se-title;
                            line-height: $spacing-se-subtitle;
                            margin-bottom: 3.2rem;
                            position: relative;

                            &::after{
                                content:"";
                                position: absolute;
                                width: 2.5rem;
                                height: 6px;
                                background: #00ACD2;
                                border-radius: 4px;
                                bottom: -1.4rem;
                                left: 0px;
                            }
                        }
                    }
                    &__form,
                    &__slider{
                        display: flex;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        align-items: center;
                        width: auto;
                        height: 100%;
                        flex-direction: column;

                        &--mobile{
                            display: flex;
                            order: 8;
                            margin-bottom: $space-md;
                        }
                    }
                    &__form{
                        &__card{
                            &__footer{
                                background: #E9EAEE;
                                width: 100%;
                                padding: $space-md;
                            }
                        }
                    }
                    &__slider{
                        width: calc(100vw - 400px);
                        height: 100vh;
                        img{
                            height: 100%;
                            object-fit: cover;
                            object-position: top center;
                        }
                    }
                    &__footer{
                        width: 100%;
                        order: 12;

                        mat-select.language{
                            &__select{
                                .mat-select-value{
                                    color: $darkBlue;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    app-footer {
        margin: 0;
    }
}

.symptom-map--full-screen {
    #symptom-map {
        height: 100% !important;
    }
}

input:not(:focus)+small {
    color: $brand-danger;
}

.text-left {
    text-align: left;
}

.text-bold {
    font-weight: $font-medium;
}

.text-small {
    font-size: 1rem !important;
}

.text-regular {
    font-size: 1.167rem !important;
    font-weight: 400 !important;
}

.text-large {
    font-size: 1.333rem !important;
}

.text-xlarge {
    font-size: 1.667rem !important;
}

.text-normal {
    color: $text-color !important;
}

.color-primary {
    color: $brand-primary !important;
}

.color-dark-blue {
    color: $darkBlue !important;
}

.color-secondary {
    color: $brand-secondary !important;
}

.padding-top-sm {
    padding-top: $space-sm;
}

.padding-bottom-sm {
    padding-bottom: $space-sm;
}

.padding-left-sm {
    padding-left: $space-sm;
}

.padding-right-sm {
    padding-right: $space-sm;
}

@font-face {
    font-family: 'text-security-circle';
    src: url('#{$text-security-font-path}text-security-circle.eot');
    src: url('#{$text-security-font-path}text-security-circle.eot?#iefix') format('embedded-opentype'),
    url('#{$text-security-font-path}text-security-circle.woff') format('woff'),
    url('#{$text-security-font-path}text-security-circle.ttf') format('truetype'),
    url('#{$text-security-font-path}text-security-circle.svg#text-security') format('svg');
}

@font-face {
    font-family: 'text-security-square';
    src: url('#{$text-security-font-path}text-security-square.eot');
    src: url('#{$text-security-font-path}text-security-square.eot?#iefix') format('embedded-opentype'),
    url('#{$text-security-font-path}text-security-square.woff') format('woff'),
    url('#{$text-security-font-path}text-security-square.ttf') format('truetype'),
    url('#{$text-security-font-path}text-security-square.svg#text-security') format('svg');
}

@font-face {
    font-family: 'text-security-disc';
    src: url('#{$text-security-font-path}text-security-disc.eot');
    src: url('#{$text-security-font-path}text-security-disc.eot?#iefix') format('embedded-opentype'),
    url('#{$text-security-font-path}text-security-disc.woff') format('woff'),
    url('#{$text-security-font-path}text-security-disc.ttf') format('truetype'),
    url('#{$text-security-font-path}text-security-disc.svg#text-security') format('svg');
}

.collapse-btn {
    position: relative;
    width: $space-md;
    height: $space-md;
    i {
        font-size: 3.75rem;
        position: absolute;
        color: $gray-500;
        top: 50%;
        right: 0;
        margin-top: -$space-md;
        height: 100%;
        width: 100%;
    }
    .fa-angle-down {
        margin-top: -29px;
    }
    &:hover {
        i {
            color: $brand-secondary;
        }
    }
}

// for domain component
.symptom-domain {
    position: relative;
    text-align: center;
    border: 1px solid $gray-300;
    border-radius: 5px;
    padding: $space-sm - 2 $space-sm;
    background-color: #fff;
    &:hover {
        background-color: $gray-300;
    }
    &--checked {
        background-color: $gray-200;
    }
    &__symptom-indicators {
        position: absolute;
        top: 50%;
        @include transform(translateY(-50%));
        .fas {
            color: $gray-400;
        }
        .highlight {
            color: $brand-secondary;
        }
    }
    &__indicators {
        position: absolute;
        right: $space-sm;
        top: 50%;
        @include transform(translateY(-50%));
        .fa-bars {
            color: $gray-500;
            margin-right: $space-md;
        }
        .fa-angle-down,
        .fa-angle-up {
            color: $gray-600;
        }
    }
}

.color-yellow {
    color: #ffd85e;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.app-wrapper.se-page{
    mat-drawer-container{
        z-index: $zindex-modal;
        overflow: inherit;
        background: transparent;
        box-shadow: 0px 40px 60px 0px rgba(32, 42, 77, 0.08);
        height: 100%;
    }
    mat-drawer {
        width: 25%;
        min-width: 420px;
        max-height: 100vh;
        padding: $space-md;
        padding-right: $space-lg;
    }

    .mat-drawer-inner-container{
        overflow-x: hidden !important;
    }
    .mat-drawer-content{
        overflow: inherit;
    }
}

.selfevaluation__nav{
    position: absolute;
    right: 1.5rem;
    top: 3.5rem;
    z-index: $zindex-modal;
    font-size: 24px;
    width: $space-md;
    height: $space-md;

    .fa-bars {
        vertical-align: top;
    }

    &:hover {
        cursor: pointer;
    }
    .close-btn {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 1.8rem;
        z-index: 9;
        cursor: pointer;
        width: 1.8rem;
        height: 1.8rem;
        padding: 0;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        text-align: center;
    }
}
.self-evaluation-modal{
    .close-btn {
        position: absolute;
        top: 26px;
        right: 38px;
        font-size: 1.8rem;
        z-index: 9;
        cursor: pointer;
        width: 2.5rem;
        height: 2.5rem;
        padding: 0.5rem;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        text-align: center;
    }
}

.modal-open {
    .modal {
        &--continue-later {
            display: flex !important;
            align-items: center;
        }
    }
}
.popover {
}

.kl-datepicker .mydrp {
    .headerbtndisabled,
    .disabled,
    .disabled .prevmonth,
    .disabled .nextmonth{
        color: $gray-300 !important;
        cursor: not-allowed !important;
    }
    .headerbtn,
    .monthlabel,
    .yearchangebtn,
    .yearlabel{
        &:focus{
            color: $gray-800 !important;
        }
    }
}

// Custom tooltip for study in Create SE modal
.patient-selfevaluation-modal{
    .tooltip-study{
        &.in{
            opacity: 1;
        }
        .tooltip-inner{
            max-width: unset;
            background: #FFF;
            color: $text-color;
            min-height: 120px;
            min-width: 400px;
            padding: $space-sm;
            border: 1px solid $gray-300;
            font-family: "Cera Pro";
            &:before{
                border-top-color: $gray-300;
            }
        }
    }

    .mat-slide-toggle {
        .mat-slide-toggle-content {
            font-size: 1.167rem;
            font-family: 'Cera Pro';
            color: $text-color;
        }

        .mat-slide-toggle-label {
            position: relative;
        }
    }
}

.patient-registration {
    .mat-slide-toggle {
        height: 48px;
        .mat-slide-toggle-content {
            white-space: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
        }
        &.mat-checked {
            .mat-slide-toggle-bar {
                background-color: #D4F3EA;
            }
            .mat-slide-toggle-thumb {
                background-color: #6FD8B8;
            }
        }
    }

    .mat-form {
        .mat-form-field {
            .mat-form-field-required-marker {
                color: #f44336;
            }
        }
    }
}

// Custom style for Document Dashboard Page
.documents-dashboard{
    background-image: url('../../img/pages/document-bg.png');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
    position: relative;
    &:after{
        position: absolute;
        content: '';
        width: 450px;
        height: 450px;
        right: 0;
        bottom: 0;
        background-image: url('../../img/pages/document-bg-bottom.png');
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: cover;
    }
}

// Custom Select2 and Mat-Select Activity Dashboard
.selected-clinic__filter .select2-container--default .select2-selection--single,
.selected-clinic__filter .select2-container--default:focus{
    border: none;
    border-bottom: 1px solid $gray-300;
    padding-bottom: 1rem;
    border-radius: 0;
    outline: none;
}
.select2-container--default .select2-selection--single .select2-selection__rendered,
.table--grid__dateRange .mat-select-value{
    color: $text-color !important;
}

.se-auth-language .mat-select-value , .se-auth-language .mat-select-arrow{
    color : $brand-primary;
}

@media screen and (max-width: 480px){
    mat-sidenav {
        width: 100%;
        min-width: auto;
    }

    // Login Page
    .auth-page {
        display: flex;
        flex-direction: column;
        .main {
            // height: 100vh;
            height: 100%;
            flex-grow: 1;
            width: 100%;
            position: relative;
            align-items: center;
            justify-content: center;
            vertical-align: middle;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            >.container {
                height: 100%;
                width: 100%;
                min-height: 520px;
                padding: 0;
                margin: 0;
                display: flex;
                display: -webkit-box;
                display: -ms-flexbox;
                justify-content: flex-start;

                > app-login,
                > app-change-password,
                > app-password,
                > app-request-action{
                    display: flex;
                    background: #FFF;
                    flex-wrap: wrap;

                    .auth{
                        &__wrapper{
                            margin: auto;
                        }
                        &__form{
                            width: auto;
                            height: auto;
                            order: 10;
                        }
                        &__slider{
                            width: auto;
                            height: auto;
                            order: 9;
                            img{
                                height: 100%;
                                object-fit: cover;
                                object-position: top center;
                            }
                        }
                    }
                }
            }
        }
        app-footer {
            margin: 0;
        }
    }
}

.self-evaluation-modal {
    z-index : 1081;
    font-family: "Cera Pro";
    // overflow-y: overlay !important;
    .modal-dialog {
        margin: 0 auto;
        max-width: fit-content;
        display: table;
        transform: none !important;
        -webkit-transform: none !important;
    }

    .modal-body {
        text-align: left;
        min-height: 100vh;
        padding : 32px 56px;
    }

    &__header {
        font-size: 24px;
        color: $brand-primary;
        font-weight: 500;
        text-align: left;
        font-family: "Cera Pro Bold";
        margin-bottom: 1.8rem;
        word-wrap: break-word;
    }

    &__body {
        text-align: left;
        font-size: $font-se-paragraph;
        line-height: $spacing-se-paragraph;
    }

    @media screen and (min-width: 1025px) and (max-width: 1199px) {
        .modal-content{
            width: calc(100vw - 32px);
        }
    }

    @media screen and (min-width: 1200px) {
        .modal-content{
            width: 1138px;
            max-height: 100vh;
            overflow: auto;
            -ms-overflow-style: none;
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    @media screen and (max-width: 1024px) {
        .modal-content{
            width: 100vw;
        }
    }

    @media screen and (max-width: 480px){
        .modal-dialog{
            .modal-body {
                padding: $space-md;
                width: 100vw;
            }
        }
    }
}


.badge {
    display: inline-block;
    padding: .25em .4em;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;

    &.badge-pill {
        padding-right: .6em;
        padding-left: .6em;
        border-radius: 10rem;
    }
}

.patient-status {
    .badge {
        &.badge-pill {
            padding-right: 0.8em;
            padding-left: 0.8em;
            max-width: 75%;
            float: left;
        }
        &.new_map_available {
            background-color: $brand-green-light;
        }

        &.se_overdue,
        &.reminder_overdue {
            background-color: $brand-orange-light;
        }

        &.renew_self_report {
            background-color: $brand-blue-light;
        }
    }
}

.scroll-overlay{
    overflow: overlay;
}
.tasklists{
    .tab-content{
        float: left;
        width: 100%;
    }
}
// Style for Breadcrumb
.breadcrumb{
    display: block;
    padding: $space-sm 0;
    &--nav{
        color: $brand-secondary;
        &:hover{
            color: $brand-primary;
        }
    }
}

// Disable switcher Weglot
.weglot_switcher{
    opacity: 0;
}
.weglot-container,
.wg-default,
.wg-default .country-selector{
    left: -9999px;
    right: auto !important;
}
.text-blink{
    font-size: 25px;
    color: $brand-primary;
    animation: blink 1s linear infinite;
}
@keyframes blink{
    0%{opacity: 0;}
    50%{opacity: .5;}
    100%{opacity: 1;}
}

.modal-confirmation-delete-tab{
    .modal-confirmation-dialog{
        vertical-align: top;
    }
}

// Mat-Slider for Rating on Self Evaluation
.mat-slider{
    .mat-slider{
        .mat-slider-wrapper{
            position: relative;
            left:0;

            &::before{
            }
        }
        &--rating{
            width: 96%;
            margin-left: 0px;
            .mat-slider-thumb{
            }
            &.mat-slider-horizontal{
                .mat-slider-track-wrapper{
                    height: 24px;
                    //background-image: linear-gradient(to right, $brand-secondary , $darkBlue );
                    background-image: url('/assets/img/self-evaluation/rating-bar.svg');
                    position: relative;
                    overflow: hidden;
                    background-size: contain;
                    background-repeat: no-repeat;
                }
                .mat-slider-track-background,
                .mat-slider-track-fill{
                    height: 0px;
                }
                .mat-slider-thumb-container{
                    .mat-slider-thumb{
                        width: 36px;
                        height: 36px;
                        bottom: -28px;
                        background: #FFF;
                        border: 0px;
                        right: -16px;
                    }
                    .mat-slider-focus-ring {
                        opacity: 0;
                    }
                }
                &:not(.explaination).mat-slider-disabled{
                    .mat-slider-track-wrapper{
                        background-image: linear-gradient(to right, $gray-200 , $gray-500 );
                    }
                    .mat-slider-thumb-container{
                        .mat-slider-thumb{
                            opacity: 0.5;
                        }
                    }
                }
                &.cdk-mouse-focused,
                &.cdk-program-focused{
                    .mat-slider-thumb-container{
                        .mat-slider-thumb{
                            transform:scale(0.8);
                            border: 3px solid $brand-secondary;
                        }
                    }
                }
            }
            &.selected{
                .mat-slider-track-wrapper{
                    &::before,
                    &::after{
                        background: $grayLanding;
                    }
                }
            }
            &.not-selected{
                opacity: 0.3;
                .mat-slider-track-wrapper{
                    filter: contrast(20%);
                }
            }
        }
        &--rating__me{
            width: 100%;
            margin-left: 0px;
            &.mat-slider-horizontal{

                .mat-slider-wrapper {
                    padding-left: 6px;
                    &::before {
                        content: '';
                        position: absolute;
                        display: block;
                        width: 6px;
                        height: 6px;
                        border-radius: 100%;
                        left: 3px;
                        top: 9px;
                        background: $green500;
                    }
                }


                .mat-slider-track-wrapper{
                    border-radius: 16px;
                    background: linear-gradient(90deg, $green500 0%, $green500 22.01%, $brightYellow 39.96%, $red500 100%);
                    clip-path: polygon(0px 35%, 100% 0px, 100% 100%, 0px 65%);
                }
            }
        }
        &--label{
            &.disabled{
                opacity: 0.5;
            }
        }
        &.disabled{
          position: relative;
          &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            float: left;
            z-index: 999;
          }
          &.mat-slider-horizontal{
            .mat-slider-thumb-container{
                .mat-slider-thumb{
                    border: none;
                }
            }
          }
        }
        &.cdk-keyboard-focused.mat-slider-horizontal,
        &.cdk-program-focused.mat-slider-horizontal{
            .mat-slider-thumb-container{
                .mat-slider-thumb{
                    border: 3px solid $brand-secondary;
                }
                .mat-slider-focus-ring {
                    opacity: 0;
                }
            }
        }
    }
    &--label{
        padding: 8px 14px 4px 4px;
        color: #202A4D99;
    }
}

.insurance-header .dropdown-item__language .mat-select-value {
    color: $darkBlue;
}

.mat-select-pill {
    position: relative;
    border: 1px solid #EAEAEA;
    border-radius: 4rem;
    min-height: 3.2rem;

    &__select {
        line-height: 3rem;
    }

    .mat-select-disabled {
        opacity: 0.5 !important;
    }

    .mat-select-trigger {
        padding: 0px 10px;
    }

    .mat-select-value-text {
        color: $darkBlue;
        width: calc(80%);

        // Set width to enable text-overflow: ellipsis;
        @media screen and (max-width: 480px){
            width: calc(60%);
        }
    }

    .mat-select-arrow-wrapper {
        .mat-select-arrow {
            color: $darkBlue;
        }
    }
}

.mat-input-pill {
    font-size: 16px;
    font-weight: 500;
    color: $darkBlue;
    font-family: 'Cera Pro';

    .mat-form-field-label-wrapper {
        top: -8px;
        .mat-form-field-label {
            padding-left: 20px;
            color: $darkBlue;
        }
    }

    .mat-form-field-infix {
        padding-left: 16px;
    }

    .mat-form-field-wrapper {
        border: 1px solid #BCBFCA;
        border-radius: 16px;
        padding-bottom: 0px;
    }

    .mat-form-field-underline,
    .mat-form-field-ripple {
        height: 0 !important;
    }
}

.b2c-dashboard{
    .mat-input-pill {
        .mat-form-field-label-wrapper {
            .mat-form-field-label {
                padding-left: 20px;
                color: $darkBlue;
            }
        }

        .mat-form-field-infix {
            padding-left: 16px;
        }

        .mat-form-field-wrapper {
            background: #FFF;
            height: 100%;
        }
    }
}

.app-wrapper.me-landing-page{
    background-color: #ffffff;
}

.me-landing-page-how-it-works,
.me-landing-page-quote {

    .carousel-img {
        max-height: 600px;
        object-fit: contain;

        @media screen and (max-width: 480px){
            max-height: 338px;
            max-width: 338px;
        }
    }

    .owl-carousel {
        width: 90%;
        left: 20px;
    }

    .owl-dot.active > span {
        background-color: $darkBlue !important;
    }

    .me-landing-page-quote__carousel-card {
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        display: flex;
        gap: 40px;
        flex-direction: column;
        padding: 30px 50px 30px 90px;
        background: rgb(255, 255, 255);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
        border-radius: 10px;
        margin: 20px auto;
    }

    @media screen and (max-width: 480px){
        .me-landing-page-quote__carousel-card {
            padding: 30px 50px 30px 60px;
        }
    }
}

.carousel-nav {
    &__left {
        position: absolute;
        cursor: pointer;
        color: $darkBlue;
        height: 100%;
        i {
            font-size: 20px;
            position: absolute;
            top: calc(50% - 30px);
        }
    }

    &__right {
        position: absolute;
        color: $darkBlue;
        height: 100%;
        cursor: pointer;
        top: 0;
        right: calc(10% - 20px);
        i {
            font-size: 20px;
            position: absolute;
            top: calc(50% - 30px);
        }
    }
}

.me-landing-expert {
    .owl-item {
        border: 1px solid #e9eaed;
        background-color: #e9eaed;
    }

    .owl-carousel .owl-item {
        border-radius: 10px;
        cursor: pointer;
    }

    .owl-carousel .owl-stage {
        display: flex;
        gap: 20px
    }

    .carousel-img {
        width: 100%;
        height: 350px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .owl-dot.active > span {
        background-color: $darkBlue !important;
    }
}

.klenico-me__image {
    aspect-ratio: 1 / 1;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.klenico-me__background {
    position: absolute;
    inset: 0px;
    overflow: hidden;
    z-index: -1;
    background-color: white;
    transition: background-color 0.3s;

    &__inner {
        position: absolute;
        top: 0px;
        left: 50%;
        min-width: calc(100% + 40px);
        min-height: 100vh;
        background: linear-gradient(104.72deg, $green500 -98.52%, white 86.74%);
        border-radius: 100%;
        aspect-ratio: 1 / 1;
        transform: translate(-50%, -40%);
        filter: blur(10px);
    }
}

.mat-me-toggle {
    &.mat-slide-toggle {
        &.mat-checked {
            .mat-slide-toggle-thumb {
                background-color: $green500;
            }

            .mat-slide-toggle-bar { 
                background-color: $green30 !important;
            }
        }
    }
}