@font-face {
    font-family: 'Cera Pro';
    font-style: normal;
    src: url('../fonts/email/TypeMates-CeraProRegular.eot');
    src: url('../fonts/email/TypeMates-CeraProRegular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/email/TypeMates-CeraProRegular.woff2') format('woff2'),
    url('../fonts/email/TypeMates-CeraProRegular.woff') format('woff'),
    url('../fonts/email/TypeMates-CeraProRegular.ttf') format('truetype'),
    url('../fonts/email/TypeMates-CeraProRegular.svg#CeraPro-Regular') format('svg');
}

@font-face {
    font-family: 'Cera Pro';
    src: url('../fonts/email/CeraPro-Bold.eot');
    src: url('../fonts/email/CeraPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/email/CeraPro-Bold.woff2') format('woff2'),
    url('../fonts/email/CeraPro-Bold.woff') format('woff'),
    url('../fonts/email/CeraPro-Bold.ttf') format('truetype'),
    url('../fonts/email/CeraPro-Bold.svg#CeraPro-Bold') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Cera Pro Bold';
    src: url('../fonts/email/CeraPro-Bold.eot');
    src: url('../fonts/email/CeraPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/email/CeraPro-Bold.woff2') format('woff2'),
    url('../fonts/email/CeraPro-Bold.woff') format('woff'),
    url('../fonts/email/CeraPro-Bold.ttf') format('truetype'),
    url('../fonts/email/CeraPro-Bold.svg#CeraPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter/Inter-Regular.eot');
    src: url('../fonts/Inter/Inter-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Inter/Inter-Regular.woff2') format('woff2'),
        url('../fonts/Inter/Inter-Regular.woff') format('woff'),
        url('../fonts/Inter/Inter-Regular.ttf') format('truetype'),
        url('../fonts/Inter/Inter-Regular.svg#Inter-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Begum Bold';
    src: url('../fonts/Begum/Begum-Bold.eot');
    src:    url('../fonts/Begum/Begum-Bold.eot?#iefix') format('embedded-opentype'),
            url('../fonts/Begum/Begum-Bold.woff') format('woff'),
            url('../fonts/Begum/Begum-Bold.svg#BegumW-Bold') format('svg'),
            url('../fonts/Begum/Begum-Bold.ttf') format('truetype'),
            url('../fonts/Begum/Begum-Bold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
