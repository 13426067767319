select {
    border: 1px solid $gray-400;
    background: transparent;
    width: 150px;
    padding: 5px 35px 5px 5px;
    font-size: 1rem;
    border: 1px solid #ccc;
    height: 34px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url('/assets/img/pages/select-arrow.png') 102% / contain no-repeat;
}

/* CAUTION: IE hackery ahead */

select::-ms-expand {
    display: none;
    /* remove default arrow on ie10 and ie11 */
}

select.form-control {
    background: url('/assets/img/pages/select-arrow.png') 100% / contain no-repeat;
    padding-right: 20px;
}

select.form-control:not([size]):not([multiple]) {
    border-color: $gray-400;
}

.mat-select-panel-wrap {
    .mat-select-panel {
        max-height: 280px;
        min-width: 248px !important;
        .mat-option {
            max-height: 3.33rem;
        }
    }
}

.mat-select,
.mat-option-text {
    font-family: "Cera Pro";
    color: $gray-600;
    &.mat-select-disabled{
        opacity: 0.8;
    }
}

.patients-filter,
.criteria-status,
.proposal--filter {
    float: left;
    width: auto;
    margin-right: 2rem;
    font-size: 15px;
    min-width: 60px;
    &__label,
    .mat-select-value-text,
    .mat-select-arrow {
        color: #FFFFFF;
        font-family: "Cera Pro";
    }
    .mat-select-arrow {
        margin-right: -6px;
    }
    &.mat-form-field-type-mat-select {
        .mat-form-field-label {
            text-overflow: unset;
            overflow: initial;
            position: static;
        }
        .mat-form-field-infix {
            width: auto;
            padding: 0;
            margin: 0;
        }
        .mat-form-field-wrapper {
            padding-bottom: 0;
        }
        .mat-select {
            position: absolute;
            right: 0;
        }
    }
    .mat-form-field-label-wrapper {
        position: static;
        float: left;
        width: 100%;
        padding: 0 1.5rem 0 0;
        overflow: visible;

        .mat-form-field-label {
            transform: none !important;
        }
    }
    &.mat-form-field-disabled {
        .mat-select-arrow,
        .patients-filter__label {
            color: $gray-100 !important;
            cursor: wait;
            opacity: 0.4;
        }
    }
    .mat-form-field-underline {
        background: none;
        display: none;
    }

    &__icon {
        position: absolute;
        right: 1rem;
        top: 8px;
        font-size: 1.3rem;
        color: #FFF;
        z-index: 9;
        cursor: pointer;
    }
    &.selected {
        border-radius: 2rem;
        border: 1px solid #FFF;
        padding: 0 0 0 0.6rem;
        margin-top: 6px;
        width: auto;
        max-width: 248px;
        .mat-select-arrow {
            opacity: 0;
        }
        .mat-form-field-wrapper {
            padding: 0;
            .mat-select {
                position: static;
                float: left;
            }
        }
        .mat-form-field-infix {
            padding: 0.5rem;
            border: 0;
            width: auto;
        }
        &__label {
            display: none;
        }
        .mat-select-value {
            overflow: visible;
            white-space: nowrap;
            text-overflow: unset;
            max-width: none;
            padding-right: 0.25rem;
            line-height: 1.1667rem;
            width: auto;
        }

        &.can-exported {
            .mat-select-arrow {
                margin-right: 12px;
            }

            .export-icon {
                right: 2.2rem;
            }
        }
    }
}

.proposal--filter {
    &__label,
    .mat-select-arrow {
        font-size: 16px;
        color: $text-color-primary;
        line-height: 16px;
    }
    .mat-select-value-text{
        font-size: 15px;
        color: $blue-500;
    }
    .mat-form-field-label-wrapper {
        .mat-form-field-flex {
            vertical-align: middle;
        }
    }
    &.mat-form-field-disabled {
        .mat-select-arrow,
        .patients-filter__label {
            color: $gray-100 !important;
        }
    }
    &__icon {
        color: $blue-500;
    }
    &.selected {
        padding: 0;
        background: #FFF;
        color: $blue-500;
        font-size: 16px;
        border-radius: 20px;
        border-color: $blue-500;
        max-width: none;
        .mat-form-field-infix {
            padding: 4px 4px 4px 6px;
        }
        .mat-form-field-wrapper {
            height: 28px;
        }
    }
}

.criteria-status {
    float: right;
    clear: both;
    width: auto;
    padding-top: 8px;
    margin-right: 0;
    &__icon {
        width: 20px;
        height: 20px;
        background: url(/assets/img/pages/fullfilled.svg);
        background-size: 57px 130px;
        position: absolute;
        top: 10px;
        left: 8px;
        background-position: 38px 112px;
        &.partially-fullfilled {
            background-position: 38px 112px;
        }
        &.not_fullfilled {
            background-position: 38px 40px;
        }
        &.review_assauge,
        &.partially_fullfilled {
            background-position: 38px 75px;
        }
    }
    &__label{
        position: absolute;
        left: 32px;
        top: 12px;
    }
    &__label,
    .mat-select-value-text,
    .mat-select-arrow {
        font-size: 14px;
        color: $text-color-primary;
        line-height: 16px;
    }
    .mat-select-arrow {
        margin-right: 0;
        position: absolute;
        top: 40%;
        right: 0;
    }
    .mat-select-trigger{
        width: 100%;
    }
    .mat-select-value-text {
        padding-left: 16px;
        padding-right: 10px;
    }
    .mat-form-field-label-wrapper {
        .mat-form-field-flex {
            vertical-align: middle;
        }
    }
    &.mat-form-field-disabled {
        .mat-select-arrow,
        .patients-filter__label {
            color: $gray-100 !important;
        }
    }
    &__icon {
        color: $text-color-primary;
    }
    &.selected {
        padding: 0 6px 0 10px;
        background: #FFF;
        border: 1px solid #005B7F;
        border-radius: 16px;
        max-width: none;
        margin-top: 0;
        .mat-form-field-infix {
            padding: 4px 0px 2px 8px;
        }
        .mat-select-arrow {
            opacity: 1;
        }
    }
}
.mat-select-panel-wrap .mat-select-panel{
    &.matStatus{
        min-width: 180px !important;
        float: right;
        margin-right: -12px;
    }
}
.select2-selection{
    &--multiple{
        border-color: #e0e0e0;
        background: transparent !important;
        z-index: 9;
        cursor: pointer !important;
    }
    &__choice{
        border-radius: 32px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 200px;
    }
}

.mat-menu-filter{
    &.active{
        position: relative;
        font-weight: bold;
        &::after{
            position: absolute;
            content: '\f00c';
            font-family: "Font Awesome 5 Pro";
            right: 10px;
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }
    .mat-form-field-wrapper{
        padding: 0;
        .mat-form-field-infix{
            padding: 0;
            margin: 0;
            border: none;
        }
        .mat-form-field-label-wrapper,
        .mat-select-arrow-wrapper,
        .mat-select-value{
            display: none;
        }
        .mat-form-field-underline{
            display: none;
        }
    }
}