.symptom-map-svg-content{
    &.dragging,
    &.proposal-mode {
        #symptom-map,
        g.symptom-icon,
        circle{
            cursor: move !important;
        }
    }
}
.symptom-map-svg-content:not(.disabled):not(.dragging){
    g.symptom-icon{
        cursor: pointer;
        .text{
            &:hover{
                font-weight: 700;
            }
        }
    }
    circle{
        cursor: pointer;
    }
}
.gradient-line{
    fill: #FFFFFF;
}
.opacity-0{
    opacity: 0;
}
.opacity-01{
    opacity: 0.1;
}
.opacity-02{
    opacity: 0.2;
}
.opacity-03{
    opacity: 0.3;
}
.opacity-04{
    opacity: 0.4;
}
.opacity-05{
    opacity: 0.5;
}
.opacity-06{
    opacity: 0.6;
}
.opacity-07{
    opacity: 0.7;
}
.opacity-08{
    opacity: 0.8;
}
.opacity-09{
    opacity: 0.9;
}
.opacity-1{
    opacity: 1;
}
.stline {
    fill: #00ACD2;
}
.stcircle {
    fill: #D1E9EC;
    stroke: none;
}
.main-fill{
    fill: #00516C !important;
}
.main-stroke{
    stroke:#00516C !important;
    stroke-width:10 !important;
}
.stroke-6{
    stroke-miterlimit: 6 !important;
}
.stroke-10{
    stroke-miterlimit:10 !important;
}
.fill-none{
    fill: none !important;
}
.default-history{
    fill:none;
    stroke:#00516C;
    stroke-width:7;
    stroke-miterlimit:10;
}
.st1, .symptom-icon text {
    font-family: 'Inter'
}
.domain-name text{
    font-family: 'Begum Bold';
}
.domain_108_200_syndrome_history_text{
    font-family: 'Begum Bold';
    font-size: 60px;
}

.st0 {
    fill: none;
    stroke: #DDDDDD;
    stroke-width: 1.0102;
    stroke-miterlimit: 10;
}

.st2 {
    font-size: 9px;
}

.st3 {
    fill: #cdcdcd;
}

.st4{
    fill: url(/assets/img/pages/sprite.svg#SVGID_1_);
}
.st5{
    fill: url(/assets/img/pages/sprite.svg#JSVGID_2_);
}
.st6{
    fill: url(/assets/img/pages/sprite.svg#SVGID_3_);
}
.st7{
    fill: url(/assets/img/pages/sprite.svg#SVGID_4_);
}
.st8 {
    fill: none;
    stroke: #DDDDDD;
    stroke-width: 1.0625;
    stroke-miterlimit: 10;
}

.st9 {
    clip-path: url(#SVGID_2_);
    fill: none;
    stroke: #DDDDDD;
    stroke-miterlimit: 10;
}

.st10 {
    font-size: 7.4883px;
}

.st11 {
    fill: none;
    stroke: #DDDDDD;
    stroke-width: 1.1597;
    stroke-miterlimit: 10;
}

.st12 {
    font-size: 8.3671px;
}

.st13 {
    opacity: 0.75;
    fill: none;
    stroke: #D0CECE;
    stroke-width: 2;
    stroke-miterlimit: 10;
}

.st14 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #FFFFFF;
    stroke: #999999;
    stroke-width: 3;
    stroke-miterlimit: 10;
}

.st15 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #999999;
    stroke: #999999;
    stroke-miterlimit: 10;
}

.st16 {
    fill: #666666;
}

.st17 {
    font-size: 39px;
}

.st18 {
    opacity: 0.75;
    fill: none;
    stroke: #D0CECE;
    stroke-width: 1.6;
    stroke-miterlimit: 10;
}

.st19 {
    font-size: 5.2643px;
}

.st20 {
    clip-path: url(#SVGID_4_);
    fill: none;
    stroke: #DDDDDD;
    stroke-miterlimit: 10;
}

.st21 {
    fill: none;
    stroke: #bdbdbd;
    stroke-miterlimit: 10;
    // stroke-dasharray: 2, 2, 2;
}

.st22 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: none;
    stroke: #DDDDDD;
    stroke-width: 1.0102;
    stroke-miterlimit: 10;
}

.st23 {
    fill: #FF0000;
}

.st24 {
    font-size: 16px;
}

.st25 {
    opacity: 0.75;
    fill: none;
    stroke: #D0CECE;
    stroke-width: 1.7466;
    stroke-miterlimit: 10;
}

.st26 {
    fill: none;
    stroke: #CCCCCC;
    stroke-miterlimit: 10;
    stroke-dasharray: 2.013, 2.013, 2.013, 2.013, 2.013, 2.013;
}

.st27 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #999999;
}

.st28 {
    fill: #FFFFFF;
}

.st29 {
    font-size: 24px;
}

.st30 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #D0CECE;
}

.st31 {
    fill: none;
    stroke: #000000;
    stroke-miterlimit: 10;
}

.st32 {
    font-size: 9.3475px;
}

.st33 {
    fill: none;
    stroke: #DDDDDD;
    stroke-width: 1.0386;
    stroke-miterlimit: 10;
}

.st34 {
    fill: #FFFFFF;
    stroke: #999999;
    stroke-width: 3;
    stroke-miterlimit: 10;
}

.st35 {
    fill: #FFFFFF;
    stroke: #333333;
    stroke-width: 0.5849;
    stroke-miterlimit: 10;
}

.st36 {
    fill: none;
    stroke: #333333;
    stroke-width: 0.5849;
    stroke-miterlimit: 10;
}

.st37 {
    fill: #333333;
}

svg {
    width: 100%;
    height: 100%;
}

.not-selected path:first-child,
.not-selected ellipse {
    fill: #00ACD2;
    fill-opacity: 0.05;
    stroke-width: 0;
    stroke: none;
}

#symptom-map-svg-content-DI .not-selected path:first-child,
#symptom-map-svg-content-DI .not-selected ellipse {
    fill: #00516C;
    fill-opacity: 0.05;
}

#symptom-map-svg-content-DI .not-selected .stline {
    fill: #00ACD2;
    fill-opacity: 0.5;
    stroke: none;
}

#symptom-map-svg-content-DI .not-selected .domain-name text,
#symptom-map-svg-content-DI .domain-name .not-selected-dialog text {
    fill: #00516C;
    fill-opacity: 0.5;
}

#symptom-map-svg-content-DI .not-selected .not-selected-syndrome,
#symptom-map-svg-content-DI .not-selected-syndrome {
    display: none;
}
#symptom-map-svg-content-DI .not-selected .not-selected-syndrome path,
#symptom-map-svg-content-DI .not-selected-syndrome path,
#symptom-map-svg-content-DI .not-selected .not-selected-syndrome ellipse,
#symptom-map-svg-content-DI .not-selected-syndrome ellipse {
    fill: #000000;
    stroke: #000000;
    stroke-width: 0;
}

circle {
    fill: transparent;
    stroke: #bdbdbd;
    stroke-width: 2;
    stroke-miterlimit: 10;
}

.symptom-icon{
    text {
        font-size: 20px;
        color: #ddd;
    }
    > rect.highlight-border {
        //display: none;
        fill:#cbeaee;
        stroke: $brand-secondary;
        stroke-width: 2px;
    }
    &.focus{
        > circle:not(.water){
            // filter: drop-shadow(1px 1px 2px black);
            // opacity: 1 !important;
            //transform: scale(1.5);
            //-ms-transform: scale(1.5);
            //-moz-transform: scale(1.5);
            //-webkit-transform: scale(1.5);
        }
        > polygon{
            filter: drop-shadow(1px 1px 2px black);
            opacity: 1 !important;
            //transform: scale(1.25);
            //-ms-transform: scale(1.25);
            //-moz-transform: scale(1.25);
            //-webkit-transform: scale(1.25);
        }
        > text,
        > tspan{
            font-weight: 700;
        }

        > rect.highlight-border {
            display: block;
            visibility: visible !important;
            opacity: 1 !important;
        }
    }
}

.text-top text{
    transform: translateY(-18px);
    -ms-transform: translateY(-18px);
    -webkit-transform: translateY(-18px);
    text-anchor: middle;
}
.text-top-left text {
    text-anchor: end;
    transform: translate(10px, -18px);
    -ms-transform: translate(10px, -18px);
    -webkit-transform: translate(10px, -18px);
}
.text-top-right text {
    text-anchor: start;
    transform: translate(-10px, -18px);
    -ms-transform: translate(-10px, -18px);
    -webkit-transform: translate(-10px, -18px);
}
.text-top text.line-2{
    transform: translateY(-38px);
    -ms-transform: translateY(-38px);
    -webkit-transform: translateY(-38px);
}
.text-top-left text.line-2{
    transform: translate(10px, -40px);
    -ms-transform: translate(10px, -40px);
    -webkit-transform: translate(10px, -40px);
}
.text-top-right text.line-2{
    transform: translate(-9px, -40px);
    -ms-transform: translate(-9px, -40px);
    -webkit-transform: translate(-9px, -40px);
}

.text-bottom text{
    text-anchor: middle;
    transform: translateY(30px);
    -ms-transform: translateY(30px);
    -webkit-transform: translateY(30px);
}
.text-bottom rect,
.text-top rect,
.text-left rect,
.text-right rect,
.text-top-left rect,
.text-top-right rect,
.text-bottom-left rect,
.text-bottom-right rect{
    transform: translateY(0px);
}
.text-bottom-left text {
    transform: translate(10px, 33px);
    -ms-transform: translate(10px, 33px);
    -webkit-transform: translate(10px, 33px);
    text-anchor: end;
}
.text-bottom-right text {
    text-anchor: start;
    transform: translate(-9px, 33px);
    -ms-transform: translate(-9px, 33px);
    -webkit-transform: translate(-9px, 33px);
}
.text-left text {
    transform: translate(-18px, 8px);
    -ms-transform: translate(-18px, 8px);
    -webkit-transform: translate(-18px, 8px);
    text-anchor: end;
}

.text-left text.line-2 {
    transform: translate(-18px, 8px);
    -ms-transform: translate(-18px, 8px);
    -webkit-transform: translate(-18px, 8px);
    text-anchor: end;
}

.text-right text {
    transform: translate(18px, 8px);
    -ms-transform: translate(18px, 8px);
    -webkit-transform: translate(18px, 8px);
    text-anchor: initial;
}

.text-right text.line-2 {
    transform: translate(18px, 8px);
    -ms-transform: translate(18px, 8px);
    -webkit-transform: translate(18px, 8px);
    text-anchor: initial;
}

.selected circle {
    fill: #FFEB3B;
    stroke-width: 0;
}

.symptom-icon polygon {
    fill: #aaa;
}

.symptom-icon polygon:hover {
    cursor: pointer;
}

.rating-low circle {
    stroke-width: 0;
    fill: $symptom-rating-low;
}

.rating-middle circle {
    stroke-width: 0;
    fill: $symptom-rating-middle;
}

.rating-high circle {
    fill: $symptom-rating-high;
    stroke-width: 0;
}

.comparison-symptom circle {
    stroke-width: 0;
}

.comparison-symptom.new-selected circle {
    fill: #FFFFFF
}

.comparison-symptom.new-selected circle.circle,
.comparison-symptom.new-appearance circle.circle,
.comparison-symptom.positive-change circle.circle,
.comparison-symptom.negative-change circle.circle,
.comparison-symptom.hide-on-map  circle.circle{
    opacity: 0;
}

.comparison-symptom.new-selected .water{
    fill: #7A13D3;
    opacity: 0.8;
    stroke-width: 0;
}

.comparison-symptom.no-symptom-rating circle {
    fill: #BFD3DA !important;
    stroke-width: 0;
}
#symptom-map-svg-content-SE .comparison-symptom.no-symptom-rating circle {
    fill: #D1E9ED !important;
    stroke-width: 0;
}

.comparison-symptom.new-appearance .water{
    fill: $symptom-new-appearance;
    stroke-width: 0;
    opacity: 0.8;
}

.comparison-symptom.no-change circle.circle{
    fill: #EDF9FE;
    stroke-width: 0;
    opacity: 0.4;
}
.comparison-symptom.no-change .water{
    opacity: 0;
}

.comparison-symptom.negative-change.rating-high circle {
    stroke-width: 1;
}

.comparison-symptom.negative-change .water {
    fill: $symptom-increased;
    opacity: 0.8;
    stroke-width: 0;
}

.comparison-symptom.positive-change .water{
    fill: $symptom-reduced;
    stroke-width: 0;
    opacity: 1;
}

#symptom-map-svg-content-SE .comparison-symptom.hide-on-map .water {
    fill: $blue-500;
    stroke-width: 0;
}

#symptom-map-svg-content-DI .comparison-symptom.hide-on-map .water {
    fill: #94D7E5;
    stroke-width: 0;
}

.acute-selected .selected-symptom > text {
    fill: #00516C;
}

.only-amnestic .symptom-icon text {
    fill: #00516C;
}

.only-amnestic .selected-symptom > text {
    fill: #00516C;
}

.not-selected .selected-symptom > text {
    fill: #00516C;
    fill-opacity: 0.5;
}

.selected-symptom > polygon {
    transform: scale(0.8);
    -ms-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -webkit-transform: scale(0.8);
}

svg.level1 text {
    display: block;
}

svg.level1 .focus text {
    display: block !important;
}

svg.level1 .domain-name text {
    display: block !important;
}

svg.level1 .domain-name > text,
svg.level1 #dialog_domain_101_200_name text,
svg.level1 .domain-name tspan {
    font-size: 39px;
}

svg.level1 circle:hover {
    cursor: pointer;
}

svg.level1 circle title {
    background: #cccccc;
    fill: #cccccc;
}

#domain_101_200_f tspan {
    font-size: 10px;
}

svg.level2 circle,
svg.level2 polygon,
svg.level3 circle,
svg.level3 polygon {
    //pointer-events: none;
}

svg.level2 text,
svg.level3 text {
    display: block;
}

.has-symptom {
    fill: aliceblue;
}

.domain-name.selected-dialog {
    cursor: pointer;
}

.domain-name text {
    fill: #00516C;
}
.map-overlay{
    fill: #FFF;
    opacity: 0.7;
}

// SYMPTOM MAP STYLE FOR DI
#symptom-map-svg-content-DI .acute-selected > path,
#symptom-map-svg-content-DI .acute-selected + circle,
#symptom-map-svg-content-DI .acute-selected > ellipse,
#symptom-map-svg-content-DI .acute-selected.only-amnestic > path {
    fill: #00516C;
    fill-opacity: 0.25;
    stroke-width: 0;
    stroke: none;
}

#symptom-map-svg-content-DI .acute-selected .domain-name text {
    fill: #00516C;
    opacity: 1;
    fill-opacity: 1;
}

#symptom-map-svg-content-DI .acute-selected > g.domain-name .syndrome-history-container path {
    fill: #00516C;
    stroke: none;
}
#symptom-map-svg-content-DI .only-amnestic > g.domain-name.selected-dialog rect {
    stroke: #757575;
    fill: #bdbdbd;
}

#symptom-map-svg-content-DI .only-amnestic > g.domain-name.selected-dialog g tspan {
    fill: #757575;
}

#symptom-map-svg-content-DI .only-amnestic > g.domain-name.selected-dialog .syndrome-history-container g.not-selected-syndrome {
    stroke: #9e9e9e;
    fill: #9e9e9e;
}

#symptom-map-svg-content-DI .only-amnestic > g.domain-name.selected-dialog .syndrome-history-container g.not-selected-syndrome polygon {
    stroke: #00516C;
    fill: #00516C;
}

#symptom-map-svg-content-DI .only-amnestic > g.domain-name.selected-dialog .syndrome-history-container g {
    stroke: #757575;
    fill: #757575;
}

#symptom-map-svg-content-DI .only-amnestic > g.domain-name.selected-dialog .syndrome-history-container g polygon {
    stroke: #757575;
    fill: #757575;
}

#symptom-map-svg-content-DI .subdomain > path{
    stroke: #00516C;
    opacity: 0.25;
    fill: none;
    stroke-width: 1px;
}
#symptom-map-svg-content-DI .subdomain.selected-sub-dialog > path,
#symptom-map-svg-content-DI .only-amnestic.subdomain > path{
    stroke: #00516C;
    opacity: 0.25;
    fill-opacity: 0;
}

#symptom-map-svg-content-DI .not-selected .subdomain path:first-child,
#symptom-map-svg-content-DI .not-selected .only-amnestic.subdomain > path{
    fill-opacity: 0;
}

#symptom-map-svg-content-DI .acute-selected > g.domain-name.selected-dialog .syndrome-history-container g {
    stroke: #fff;
    fill: #fff;
}

#symptom-map-svg-content-DI .acute-selected .unselected-symptom > circle{
    stroke: #00516C;
    opacity: 1;
}

#symptom-map-svg-content-DI .not-selected .unselected-symptom > circle {
    fill: transparent;
    stroke: #00516C;
    opacity: 0.5;
}

#symptom-map-svg-content-DI .domain-not-hightlight{
    .selected-symptom > text {
        fill: #00516C;
        fill-opacity: 1;
    }
    .symptom-icon:not(.unselected-symptom){
        opacity: 0.5;
    }
}

#symptom-map-svg-content-DI .expert-symptom > circle {
    stroke-width: 3.5px;
    stroke: #00516C;
    transform: scale(1.1);
    -ms-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
}

#symptom-map-svg-content-DI .not_fullfilled-highlight > circle {
    stroke-width: 5.5px;
    stroke: #fd6f87 !important;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2));
}

#symptom-map-svg-content-DI .fullfilled-highlight > circle {
    stroke-width: 4.5px;
    stroke: #00ACD2 !important;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2));
}

#symptom-map-svg-content-DI .expert-symptom > circle.focus{
}
@keyframes blink{
    0%{opacity: 0;}
    50%{opacity: .5;}
    100%{opacity: 1;}
}

#symptom-map-svg-content-DI .acute-selected g.not-selected .unselected-symptom > circle,
#symptom-map-svg-content-DI .only-amnestic g.not-selected .unselected-symptom > circle {
    fill: transparent;
    stroke: #00516C;
    opacity: 1;
}
#symptom-map-svg-content-DI .only-amnestic .unselected-symptom > circle {
    fill: transparent;
    stroke: #00516C;
}


#symptom-map-svg-content-DI .acute-selected > g.domain-name.selected-dialog .syndrome-history-container g polygon {
    stroke: #fff;
    fill: #fff;
}

#symptom-map-svg-content-DI .acute-selected > g.domain-name.selected-dialog .syndrome-history-container g.not-selected-syndrome {
    stroke: #0080b3;
    fill: #0080b3;
}

#symptom-map-svg-content-DI .acute-selected > g.domain-name.selected-dialog .syndrome-history-container g.not-selected-syndrome polygon {
    stroke: #0080b3;
    fill: #0080b3;
}

#symptom-map-svg-content-DI .only-amnestic > g.domain-name.selected-dialog polygon {
    stroke: #757575;
    fill: #757575;
}

#symptom-map-svg-content-DI .only-amnestic > g.domain-name.selected-dialog text {
    fill: #757575;
}

#symptom-map-svg-content-DI .only-amnestic .selected-symptom > polygon {
    fill: #aaa;
}


#symptom-map-svg-content-DI .only-amnestic .syndrome-history-container > circle.stcircle {
    fill: #D1E9ED;
    fill-opacity: 1;
}

#symptom-map-svg-content-DI .acute-selected .unselected-symptom > text {
    fill: #00516C;
}
/* exclude subdomains inside the domain Abhangigkeiten */
#symptom-map-svg-content-DI .not-selected:not(#domain_108_201):not(#domain_108_202):not(#domain_108_203) .unselected-symptom > text {
    fill: #00516C;
    fill-opacity: 0.5;
}

#symptom-map-svg-content-DI .selected-dialog > rect, .selected-dialog g:first-child > rect, .selected-dialog
.syndrome-history rect {
    stroke: #003252;
    fill: #005b7f;
}

#symptom-map-svg-content-DI .selected-dialog > text, .selected-dialog g:first-child > text, .selected-dialog
tspan {
    fill: #fff;
}

#symptom-map-svg-content-DI .selected-dialog > text, .selected-dialog text tspan {
    fill: #fff;
}

#symptom-map-svg-content-DI .selected-dialog > polygon {
    stroke: #003252;
    fill: #003252;
}

// #symptom-map-svg-content-DI .comparison-symptom.no-symptom-rating circle:not(:first-child) {
//     fill: #bfd3da;
//     stroke-width: 0;
// }

// -- END SYMPTOM MAP STYLE FOR DI

// SYMPTOM MAP STYLE FOR SE
#symptom-map-svg-content-SE .not-selected path:first-child,
#symptom-map-svg-content-SE .not-selected ellipse {
    fill: #D1E9ED;
    fill-opacity: 0.3;
    stroke-width: 0;
    stroke: none;
}

#symptom-map-svg-content-SE .not-selected .stline {
    fill: #00ACD2;
    fill-opacity: 0.5;
    stroke: none;
}

#symptom-map-svg-content-SE .not-selected .domain-name text {
    fill: #00516C;
    fill-opacity: 0.5;
}

#symptom-map-svg-content-SE .subdomain > path{
    stroke: #00516C;
    opacity: 0.25;
}
#symptom-map-svg-content-SE .not-selected .subdomain > path{
    opacity: 0.25;
    fill: none;
    stroke-width: 1px;
}

#symptom-map-svg-content-SE .acute-selected > path,
#symptom-map-svg-content-SE .acute-selected > ellipse,
#symptom-map-svg-content-SE .acute-selected.only-amnestic > path {
    fill: #D1E9ED;
    stroke-width: 0;
    stroke: none;
}
#symptom-map-svg-content-SE .subdomain text{
    opacity: 0.5;
}
#symptom-map-svg-content-SE .subdomain.selected-sub-dialog > path,
#symptom-map-svg-content-SE .only-amnestic.subdomain > path{
    stroke: #00516C;
    opacity: 0.5;
}
#symptom-map-svg-content-SE .acute-selected .stline,
#symptom-map-svg-content-SE .acute-selected .stline,
#symptom-map-svg-content-SE .acute-selected.only-amnestic .stline {
    fill: #00ACD2;
}
#symptom-map-svg-content-SE .acute-selected .stcircle,
#symptom-map-svg-content-SE .only-amnestic .stcircle{
    fill: #d4f6fa;
}
#symptom-map-svg-content-SE .acute-selected .unselected-symptom circle,
#symptom-map-svg-content-SE .acute-selected.only-amnestic .unselected-symptom circle {
    stroke: #00516C;
    opacity: 1;
}

#symptom-map-svg-content-SE .acute-selected .domain-name text {
    fill: #00516C;
    opacity: 1;
}

#symptom-map-svg-content-SE .selected-dialog > rect, .selected-dialog g:first-child > rect, .selected-dialog
.syndrome-history rect {
    stroke: #30527c;
    fill: #5285cf;
}

#symptom-map-svg-content-SE .only-amnestic > g.domain-name.selected-dialog rect {
    stroke: #757575;
    fill: #bdbdbd;
}

#symptom-map-svg-content-SE .only-amnestic > g.domain-name.selected-dialog polygon {
    stroke: #757575;
    fill: #757575;
}

#symptom-map-svg-content-SE .only-amnestic > g.domain-name.selected-dialog text {
    fill: #757575;
}

#symptom-map-svg-content-SE .only-amnestic > g.domain-name.selected-dialog text tspan {
    fill: #757575;
}

#symptom-map-svg-content-SE .only-amnestic .selected-symptom > polygon {
    fill: #aaa;
}

#symptom-map-svg-content-SE .only-amnestic .unselected-symptom > circle {
    fill: transparent;
    stroke: #00516C;
}

#symptom-map-svg-content-SE .not-selected .unselected-symptom > circle {
    fill: transparent;
    stroke: #00516C;
    opacity: 0.5;
}
#symptom-map-svg-content-SE .acute-selected g.not-selected .unselected-symptom > circle,
#symptom-map-svg-content-SE .only-amnestic g.not-selected .unselected-symptom > circle {
    fill: transparent;
    stroke: #00516C;
    opacity: 1;
}

#symptom-map-svg-content-SE .acute-selected .unselected-symptom > text {
    fill: #00516C;
}


/* exclude subdomains inside the domain Abhangigkeiten */
#symptom-map-svg-content-SE .not-selected:not(#domain_108_201):not(#domain_108_202):not(#domain_108_203) .unselected-symptom > text {
    fill: #00516C;
    fill-opacity: 0.5;
}

// #symptom-map-svg-content-SE .comparison-symptom.no-symptom-rating circle:not(:first-child) {
//     fill: #d1e9ed;
//     stroke-width: 0;
// }

// -- END SYMPTOM MAP STYLE FOR SE

.selected-dialog > rect, .selected-dialog g:first-child > rect, .selected-dialog .syndrome-history rect {
    stroke: #30527c;
    fill: #5285cf;
}

.selected-dialog > text, .selected-dialog g:first-child > text, .selected-dialog tspan {
    fill: #fff;
}

.selected-dialog > polygon, .selected-dialog g:first-child > polygon {
    fill: #30527c;
    stroke: #30527c;
}

.only-amnestic > path,
.only-amnestic circle:first-child,
.only-amnestic > ellipse,
#symptom-map-svg-content-DI.comparison .only-amnestic > path,
#symptom-map-svg-content-DI.comparison .only-amnestic + circle,
#symptom-map-svg-content-DI.comparison .only-amnestic > ellipse {
    fill: #EAEAEA;
}

.only-amnestic > g.domain-name.selected-dialog rect {
    stroke: #757575;
    fill: #bdbdbd;
}

.only-amnestic > g.domain-name.selected-dialog polygon {
    stroke: #757575;
    fill: #757575;
}

.only-amnestic > g.domain-name.selected-dialog text, .selected-dialog tspan {
    fill: #757575;
}

.only-amnestic .selected-symptom > polygon {
    fill: #757575;
}

.only-amnestic .unselected-symptom > circle {
    fill: transparent;
    stroke: #999999;
}

#symptom-map-svg-content-DI .only-amnestic > path,
#symptom-map-svg-content-DI .only-amnestic + circle,
#symptom-map-svg-content-DI .only-amnestic > ellipse {
    fill: #EAEAEA;
    stroke:none;
    fill-opacity: 0.5;
}

// Domain hover
#symptom-map-svg-content-DI {
    &.interactive-mode {
        g.domain-name:hover > text,
        g.domain-name:hover > rect,
        text[attr-node=domain-path]:hover{
            fill: #00ACD2;
            fill-opacity: 1;
            cursor: pointer;
        }
        g#rootNode.have-domain-activate{
            > g[attr-node=domain]{
                opacity: 0.4;
            }
            > g.domain-active{
                opacity: 1;
            }
        }
        // Disable hover domain effect
        //g[attr-node=domain]:hover > path,
        g.domain-active[attr-node=domain] > path
        {
            stroke: #00516c24;
            stroke-width: 4px;
            zoom: 1.5;
            filter: drop-shadow(20px 20px 20px rgba(0, 0, 0, 0.2));
        }
        // Disable hover domain effect
        //g.not-selected[attr-node=domain]:hover > path,
        g.domain-active.not-selected[attr-node=domain] > path{
            filter: drop-shadow(20px 20px 20px rgba(0, 0, 0, 1));
            fill-opacity: 0.06;
        }
    }
    &.view-mode{
    }
}

#symptom-map-svg-content-DI .only-amnestic .stline {
fill: #00ACD2;
}
#symptom-map-svg-content-DI .acute-selected .stcircle,
#symptom-map-svg-content-DI .only-amnestic .stcircle{
fill: #d4f6fa;
}
#symptom-map-svg-content-DI .only-amnestic .domain-name text {
fill: #00516C;
}
#symptom-map-svg-content-DI .only-amnestic .unselected-symptom > text {
fill: #00516C;
}
// #symptom-map-svg-content-DI .only-amnestic circle {
// fill: #D1E9ED;
// fill-opacity: 1;
// }

#symptom-map-svg-content-DI .selected-sub-dialog rect {
fill: #003252;
}

#symptom-map-svg-content-DI .selected-sub-dialog > path {
stroke: #bdbdbd;
}

.selected-sub-dialog rect {
fill: #30527c;
}

.selected-sub-dialog > path {
stroke: #d1d1d1;
}

.not-selected-dialog.subdomain rect {
stroke: #bdbdbd;
fill: #bdbdbd;
}

.not-selected-dialog.subdomain text,
.not-selected-dialog.subdomain text tspan {
fill: #00516C;
}

.not-selected-dialog > rect {
stroke: #bdbdbd;
}

.not-selected-dialog > polygon {
fill: #bdbdbd;
stroke: #bdbdbd;
}

// .not-selected-dialog .st16 {
// fill: #bdbdbd;
// }

.hidden-unselected-se-domains #rootNode > g.not-selected:not(.selected-symptom),
.hidden-unselected-se-domains g.not-selected-dialog,
.hidden-unselected-di-domains #rootNode > g.not-selected,
.hidden-unselected-di-domains g.not-selected-dialog {
display: none;
}

.selected-dialog .syndrome-history-container .st34 {
stroke: #003252;
fill: #005b7f;
}

.selected-dialog .syndrome-history-container g {
stroke: #fff;
}

.not-selected-dialog .syndrome-history-container .st34 {
stroke: #bdbdbd;
}

#symptom-map-svg-content-SE .syndrome-history-container {
display: none;
}

#symptom-map-svg-content-DI .syndrome-history-container {
display: initial;
}

#symptom-map-svg-content-DI .syndrome-history-container text {
fill: #fff;
}

#symptom-map-svg-content-DI .selected-dialog .syndrome-history-container text.not-selected-syndrome {
fill: #0080b3;
}

.not-selected-dialog .syndrome-history-container g {
stroke: #ddd;
fill: #ddd;
}

.not-selected-dialog .syndrome-history-container g polygon {
stroke: #ddd;
fill: #ddd;
}

// Customize when selected Focus Mode
.comparison.focus-mode {
    .water,
    .water.circle{
        display: none;
    }
    &.new{ 
        .comparison-symptom.new-appearance{
            .water{
                display: block !important;
            }
            circle {
                stroke-width: 1;
                stroke: #FFF;
            }
        }
        .comparison-symptom.no-symptom-rating circle:not(:first-child) {
            stroke-width: 2 !important;
            fill: transparent;
            stroke: #00516C;
        }
    }
    &.increased{
        .comparison-symptom.negative-change{
            .water{
                display: block !important;
            }
            circle {
                stroke-width: 1;
                stroke: #FFF;
            }
        }
        .comparison-symptom.no-symptom-rating circle:not(:first-child) {
            stroke-width: 2 !important;
            fill: transparent;
            stroke: #00516C;
        }
    }
    &.unchanged{
        .comparison-symptom.no-change{
            .water,
            .water.circle{
                display: block !important;
            }
            circle {
                stroke-width: 1;
                stroke: #FFF;
            }
        }
        .comparison-symptom.no-symptom-rating circle:not(:first-child) {
            stroke-width: 2 !important;
            fill: transparent;
            stroke: #00516C;
        }
    }
    &.reduced{
        .comparison-symptom.positive-change{
            .water{
                display: block !important;
            }
            circle {
                stroke-width: 1;
                stroke: #FFF;
            }
        }
        .comparison-symptom.no-symptom-rating circle:not(:first-child) {
            stroke-width: 0 !important;
        }
    }
    &.none{
        .comparison-symptom{
            .water{
                display: block !important;
            }
            circle {
                stroke-width: 1;
                stroke: #FFF;
            }
        }
        .comparison-symptom.no-symptom-rating circle:not(:first-child) {
            stroke-width: 0;
        }
    }
}
